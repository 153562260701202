import {AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit} from '@angular/core';
import { Router } from '@angular/router';
import { of, Subscription } from 'rxjs';
import { concatMap, first, map } from 'rxjs/operators';
import { InterventionTaskResponse } from 'src/app/core/models/student-data.model';
import { InterventionTask } from 'src/app/core/models/task.model';
import { AudioPlayerService } from 'src/app/core/services/audio-player.service';
import { CurriculumService } from 'src/app/core/services/curriculum.service';
import { InterventionTaskService } from 'src/app/core/services/intervention-task.service';
import { ApplicationStateService } from 'src/app/core/services/application-state.service';
import { ShuffleService } from 'src/app/core/services/shuffle.service';
import { StudentDataService } from 'src/app/core/services/student-data.service';
import { TimerService } from 'src/app/core/services/timer.service';
import { InterventionTaskComponent } from '../intervention-task.component';
import { Phrase } from './passages-read-and-listen.model';

@Component({
  selector: 'passages-read-and-listen',
  templateUrl: './passages-read-and-listen.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PassagesReadAndListenComponent extends InterventionTaskComponent implements OnInit, AfterViewInit, OnDestroy {

  // task specific variables
  currentPhrase: Phrase | null = null;
  currentlyPlayingAudio: string | null = null;
  subTaskList: InterventionTask[] = [];
  displayAvatar: boolean = false;
  avatarIcon: string = '';
  disablePassageAudio: boolean = true;
  currentDestination: number = 0;
  phraseList: Phrase[] = [];
  passageTitle: string = '';
  showDoneButton: boolean = false;
  highlightTitle: boolean = false;
  subTasksComplete: boolean = false;
  indicationTimer: number = 30 * 1000;
  private backButtonSubscription?: Subscription;

  constructor(
    public studentDataService: StudentDataService,
    public interventionTaskService: InterventionTaskService,
    public shuffleService: ShuffleService,
    public timerService: TimerService,
    public audioPlayerService: AudioPlayerService,
    public router: Router,
    public applicationStateService: ApplicationStateService,
    public curriculumService: CurriculumService,
    public changeDetector: ChangeDetectorRef,
  ) {
    super(studentDataService, interventionTaskService, timerService, audioPlayerService, router);
  }

  ngOnInit(): void {
    this.avatarIcon = this.studentDataService.getCurrentStudentAvatar().avatar;
    this.currentDestination = this.studentDataService.getCurrentDestination();
    this.passageTitle = this.task.passage?.title?.['#text'] || '';
    // Display total points for this task if it has been completed
    this.taskTotalPoints = (this.alreadyCompleted ? 500 : 0);

    this.backButtonSubscription = this.interventionTaskService.browserBackButtonListener().subscribe(() => {
      if (this.router.getCurrentNavigation()?.extras?.state?.passage)
      {
        // NOTE: This seems like a major hack.
        // Setting navigation extras state to undefined because in the guard
        // we check to see if passages is set. If it is not, then we don't allow
        // the user to go back into the sub task.
        this.router!.getCurrentNavigation()!.extras!.state = undefined
      }
    });
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
    this.backButtonSubscription?.unsubscribe();
  }

  ngAfterViewInit(): void {
    // After view is initialized wait for task animation to complete and then initialize everything else
    this.taskBar.taskAnimationComplete.pipe(first()).subscribe((complete) => {
      if (complete) {
        // set this to tell the trial-counter that animation is complete
        this.animationComplete = true;
        this.interventionTaskService.initTaskContainerElements(this.task, this.alreadyCompleted, this.wordListAttempt, this.attempt)
          .pipe(first(),
            map(() => {
              this.timerBarTaskSettings = this.interventionTaskService.getTimerBarTaskSettings();
              if (this.timerBarTaskSettings.timerBarEnabled) {
                this.trialTimerBar.showTimerBar();
              }
            }),
            concatMap(() => {
              if (!this.studentDataService.hasCompletedAtLeastOneTaskLikeThis(this.task.id) && this.interventionTaskService.getPlayVideoFlag()) {
                this.playInstructionalAudio = false;
                return this.instructions.playInstructionalVideo();
              }
              else {
                return of({});
              }
            })
          )
          .subscribe({
            complete: () => {
              this.markTaskCompleted();
              this.displayTask();
            },
          });
      }
    });
    // Display the focus dialog if needs focus is set (from intevention task)
    if(this.needsFocus){
      this.focusDialog.showDialog();
    }
  }

  createNewPhrase(index: number, audio: string, text: string, highlight: boolean): Phrase {
    let phrase: Phrase = {
      index: index,
      audio: audio,
      text: text,
      highlight: highlight    }
    return phrase;
  }

  saveTaskData() {
    let studentInterventionData = this.studentDataService.getStudentInterventionData();
    let taskObject: InterventionTaskResponse[] = [{
      interventionTaskIdentifier: this.task.id,
      points: this.taskTotalPoints,
      objectiveNumber: studentInterventionData?.objectiveNumber || 0,
      type: studentInterventionData?.type || 0,
      unitNumber: studentInterventionData?.unitNumber || 0,
      durationMillis: 0,
      wordListType: this.studentDataService.getWordListTypeAsInt(this.task.wordlistType || ''),
    }];
    // Send the data to the backend
    this.studentDataService.saveTrialData(taskObject).subscribe({
      next: () => this.saveDataDialog.hideSaveDataDialog(),
      error: () => this.saveDataDialog.showSaveDataDialog()
    });
  }

  markTaskCompleted() {
    // If entering the task for the first time -- reward 500 pts and save
    if (!this.alreadyCompleted) {
      this.taskTotalPoints = 500;
      // Keep track of total points locally
      this.studentDataService.setTotalPoints(this.currentDestination, this.taskTotalPoints, 0, 0);
      this.saveTaskData();
    }
  }

  playPassage() {
    if (!this.stopAudioIfPlaying(this.task.passage!['@audio'])) {
      this.disableAVButtons = true;
      this.togglePhraseHighlighting(true);
      this.currentlyPlayingAudio = this.task.passage!['@audio'];
      this.audioPlayerService.play(this.currentlyPlayingAudio).subscribe({
        complete: () => this.passageAudioCompleteFunc(),
        error: () => this.audioErrorFunc()
      })
    }
    else {
      this.togglePhraseHighlighting(false);
    }
  }

  playPassageTitle() {
    if (!this.stopAudioIfPlaying(this.task.passage!.title!['@audio'])) {
      this.disableAVButtons = true;
      this.togglePhraseHighlighting(false);
      this.highlightTitle = true;
      this.currentlyPlayingAudio = this.task.passage!.title!['@audio'] || '';
      this.audioPlayerService.play(this.currentlyPlayingAudio).subscribe({
        complete: () => this.titleAudioCompleteFunc(),
        error: () => this.titleAudioCompleteFunc()
      });
    }
    else {
      this.togglePhraseHighlighting(false);
    }
  }

  playPhraseAudio(phrase: Phrase) {
    if (!this.stopAudioIfPlaying(phrase.audio)) {
      this.disableAVButtons = true;
      this.togglePhraseHighlighting(false);
      phrase.highlight = true;
      this.currentPhrase = phrase;
      this.currentlyPlayingAudio = phrase.audio;
      this.audioPlayerService.play(this.currentlyPlayingAudio).subscribe({
        complete: () => this.phraseAudioCompleteFunc(),
        error: () => this.audioErrorFunc()
      });
    }
    else {
      this.togglePhraseHighlighting(false);
    }
  }

  phraseAudioCompleteFunc() {
    this.disableAVButtons = false;
    this.currentlyPlayingAudio = null;
    this.togglePhraseHighlighting(false);
  }

  titleAudioCompleteFunc() {
    this.disableAVButtons = false;
    this.currentlyPlayingAudio = null;
    this.togglePhraseHighlighting(false);
  }

  passageAudioCompleteFunc() {
    this.disableAVButtons = false;
    this.currentlyPlayingAudio = null;
    this.togglePhraseHighlighting(false);
  }

  audioErrorFunc() {
    this.disableAVButtons = false;
    this.currentlyPlayingAudio = null;
    this.togglePhraseHighlighting(false);
  }

  togglePhraseHighlighting(highlight: boolean) {
    this.highlightTitle = highlight;
    for (let i = 0; i < this.phraseList.length; i++) {
      this.phraseList[i].highlight = highlight;
    }
    this.changeDetector.markForCheck() ;
  }

  buildPhraseList() {
    let phrases = this.task.passage!.phrase;
    for (let i = 0; i < phrases!.length; i++) {
      let newPhrase = this.createNewPhrase(i, phrases![i]['@audio'], phrases![i]['#text'], false);
      this.phraseList.push(newPhrase);
    }
  }

  // Stop playing passage/phrase audio if same button is re-selected
  // Returns true if audio was stopped, otherwise false
  stopAudioIfPlaying(audio: string) {
    if (this.currentlyPlayingAudio === audio) {
      this.audioPlayerService.stopAll();
      this.disableAVButtons = false;
      this.currentlyPlayingAudio = null;
      return true;
    }
    return false;
  }

  displayTask() {
    this.disablePassageAudio = false;
    this.disableAVButtons = false;
    this.displayAvatar = true;
    this.buildPhraseList();
    this.changeDetector.markForCheck() ;
  }
}
