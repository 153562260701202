import { AfterViewInit, ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router, RoutesRecognized } from '@angular/router';
import { Observable, Observer, of, Subscription } from 'rxjs';
import { catchError, filter, pairwise, switchMap, concatMap, take } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { AvatarData, AvatarItem } from '../../core/models/student-data.model';
import { AudioPlayerService } from '../../core/services/audio-player.service';
import { SessionTimerService } from '../../core/services/session-timer.service';
import { StudentDataService } from '../../core/services/student-data.service';
import { WorldNavigationTimerService } from '../../core/services/world-nav-timer.service';
import { LoginService } from '../../login/login.service';
import { InstructionsComponent } from '../../shared/components/instructions/instructions.component';
import { SideMenuComponent } from '../../shared/components/side-menu/side-menu.component';
import { StudentFooterComponent } from '../../shared/components/student-footer/student-footer.component';
import { StudentHeaderComponent } from '../../shared/components/student-header/student-header.component';
import { LevelButton, LevelButtonStyle } from './level-select.model';
import { Fireworks } from 'fireworks-js';

@Component({
  selector: 'app-level-select',
  templateUrl: './level-select.component.html',
})
export class LevelSelectComponent implements OnInit, OnDestroy, AfterViewInit {
  private currentPosition: number = -1;
  private avatar: AvatarData | null = null;
  // TODO: Create resolve data type and set from level select resolver
  private levelSelectResolveData: any = {};
  private levelSelectProperties: any = null;
  previousUrl: string | null = null;
  private animationTimeout?: number;
  private eventsSubscription?: Subscription;
  private logoutSubscription?: Subscription;
  private navigationTimerSubscription?: Subscription;

  showAwardedAvatar: boolean = false;
  awardedAvatar: AvatarItem | null = null;
  // TODO: Create avatar position type
  avatarPosition: any = {};
  isIntervention: boolean = false;
  isDemoUser: boolean = false;
  enableAllButtons: boolean = false;
  hideCompletedMessage: boolean = true;
  displayCompletedMessage: boolean = false;
  playTransition: boolean = false;
  transitionDirection: string = '';
  levelSelectBackgroundImageA: string = '';
  levelSelectBackgroundImageB: string | null = '';
  // TODO: Create header control type
  headerControl: any = {};
  levelOne: boolean = false;
  levelTwo: boolean = false;
  levelButtonList: LevelButton[] = [];
  levelButtonList1: LevelButton[] = [];
  levelButtonList2: LevelButton[] = [];
  videoSource: string = '';
  disableSideButton: boolean = false;
  sessionTimerIntervalFunc: any;

  @ViewChild('studentHeader') headerComponent!: StudentHeaderComponent;
  @ViewChild('sideMenu') sideMenuComponent!: SideMenuComponent;
  @ViewChild('instructions') instructionsComponent!: InstructionsComponent;
  @ViewChild('studentFooter') studentFooter!: StudentFooterComponent;


  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private changeDetector: ChangeDetectorRef,
    private audioPlayerService: AudioPlayerService,
    private loginService: LoginService,
    private navigationTimerService: WorldNavigationTimerService,
    private studentDataService: StudentDataService,
    private sessionTimerService: SessionTimerService,
  ) { }

  ngOnInit(): void {
    this.currentPosition = this.studentDataService.getCurrentPosition();
    this.avatar = this.studentDataService.getStudentAvatar();
    this.isIntervention = this.studentDataService.isIntervention();
    this.isDemoUser = this.studentDataService.isDemoUser();

    this.studentDataService.setCurrentDestination(0);
    this.levelSelectProperties = this.studentDataService.getLevelProperties();
    this.transitionDirection = this.levelSelectProperties.transitionDirection;
    this.levelSelectBackgroundImageA = this.studentDataService.getLevelSelectBackgroundA();
    this.levelSelectBackgroundImageB = this.studentDataService.getLevelSelectBackgroundB();
    this.previousUrl = window.history.state?.previous || null ;

    this.levelSelectResolveData = this.activatedRoute.snapshot.data.resolveData;
    if (this.levelSelectResolveData.allTasksWereJustCompleted && this.isDemoUser)
    {
      // Hack
      this.router.navigateByUrl('/demo');
    }

    this.navigationTimerSubscription = this.navigationTimerService.expired.subscribe(expired => {
      if (expired)
      {
        this.handleNavigationTimerExpired();
      }
    });
  }

  createLevelButton(id: string, style: LevelButtonStyle, disabled: boolean, destination: number): LevelButton {
    return {
      id,
      style,
      disabled,
      destination,
    }
  }

  highlightLevelButton(index: number) {
    if (!this.levelTwo)
    {
      this.levelButtonList1[index].style['background-image'] = this.levelButtonList1[index].style['background-image'].replace('_bu', '_bh');
    }
    else
    {
      this.levelButtonList2[index].style['background-image'] = this.levelButtonList2[index].style['background-image'].replace('_bh', '_bu');
    }
  }

  unhighlightLevelButton(index: number) {
    if (!this.levelTwo)
    {
      this.levelButtonList1[index].style['background-image'] = this.levelButtonList1[index].style['background-image'].replace('_bh', '_bu');
    }
    else
    {
      this.levelButtonList2[index].style['background-image'] = this.levelButtonList2[index].style['background-image'].replace('_bh', '_bu');
    }
  }

  handleTouchEnd(levelButton: LevelButton, index: number, $event: Event) {
    // Prevent the click event from firing since the touch event is handling it
    $event.preventDefault();
    $event.stopPropagation();

    this.unhighlightLevelButton(index);

    // We do not process any touch "click" events when levels buttons are disabled
    if (!this.enableAllButtons || levelButton.disabled) {
      return;
    }

    this.goToTaskSelect(index);
  }

  updateSelectedAvatar(avatar: AvatarItem) {
    this.headerComponent.updateAvatarIcon();
    this.avatarPosition['background-image'] = 'url(' + avatar.avatarMarker + ')';
  }

  private getStyleForLevelSelectButton(
    isIntevention: boolean,
    levelsPerPrePost: number,
    levelsBeforePost: number,
    buttonIndex: number,
    buttonState: string,
    left: string,
    top: string,
    priorLevelsComplete: number,
  ): LevelButtonStyle {
    let levelIcon = null;
    let includeWidth = false;

    if (isIntevention) {
      if(buttonIndex < levelsPerPrePost || buttonIndex > levelsBeforePost){
        levelIcon = '/assets/images/levelSelect/bttn_objAssess_b' + buttonState + '.svg';
      } else {
        levelIcon='/assets/images/levelSelect/levelSelectButtons/bttn_levelSelect-' + (priorLevelsComplete+buttonIndex-levelsPerPrePost+1) + '_b' + buttonState + '.svg';
        includeWidth = true;
      }
    } else {
      levelIcon = '/assets/images/levelSelect/bttn_diagnosticAssess_b' + buttonState + '.svg';
      includeWidth = true;
    }

    let style: LevelButtonStyle = {
      'background-image' : 'url(' + levelIcon + ')',
      'left' : left,
      'top' : top
    }

    if (includeWidth) {
      style.width = '4%';
    }

    return style;
  }

  private initializeLevels() {
    let levelsPerPrePost = 0;
    let levelsBeforePost = 0;
    let levelsPerUnit = 0;
    let priorLevelsComplete = 0;
    let disabled = true;
    let startPosition = 0;
    let completedDestinations: { [key: number]: boolean } = {};

    if (this.isDemoUser)
    {
      completedDestinations = this.studentDataService.computeCompletedDestinations();
      if (this.studentDataService.isInterventionUnit())
      {
        startPosition = this.currentPosition - this.studentDataService.getNumLevelsInUnit() + 1;
      }
      else if (this.studentDataService.isInterventionPostTest())
      {
        startPosition = this.currentPosition - this.studentDataService.getNumLevelsInPrePost() + 1;
      }
    }

    let numberOfButtons = this.studentDataService.getNumberOfLevelSelectButtons();
    if (this.studentDataService.isIntervention())
    {
      levelsPerPrePost = this.studentDataService.getNumLevelsInPrePost();
      levelsBeforePost = numberOfButtons - levelsPerPrePost - 1;
      levelsPerUnit = this.studentDataService.getNumLevelsInUnit();
      priorLevelsComplete = this.studentDataService.getNumLevelsPriorToCurrentObjective();
    }

    for (let i = 0; i < numberOfButtons; ++i)
    {
      let buttonState = 'g';
      if (this.isDemoUser)
      {
        if (i <= this.currentPosition && i >= startPosition)
        {
          let positionInUnit = i - startPosition + 1;
          if (completedDestinations[positionInUnit])
          {
            buttonState = 'c';
          }
          else
          {
            buttonState = 'u';
          }
        }
      }
      else if (i < this.currentPosition)
      {
        buttonState = 'c';
      }
      else if (i == this.currentPosition)
      {
        buttonState = 'u';
      }

      let destinationForLevel = i+1;
      let left = this.levelSelectProperties.xPositions[i] + '%';
      let top = this.levelSelectProperties.yPositions[i] + '%';

      let style: LevelButtonStyle = this.getStyleForLevelSelectButton(
        this.studentDataService.isIntervention(), levelsPerPrePost, levelsBeforePost,
        i, buttonState, left, top, priorLevelsComplete);

      if (this.studentDataService.isIntervention())
      {
        if (i < levelsPerPrePost)
        {
          destinationForLevel = i+1;
        }
        else if (i > levelsBeforePost)
        {
          destinationForLevel = i - levelsBeforePost;
        }
        else
        {
          destinationForLevel =  ((i - levelsPerPrePost) % levelsPerUnit) + 1;
        }
      }

      if (buttonState =='u')
      {
        disabled = false;
      }
      else
      {
        disabled = true;
      }
      this.levelButtonList.push(this.createLevelButton(
        (!disabled ? 'current-position' : 'disabled'),
        style,
        disabled,
        destinationForLevel
      ));
    }

    // Split the levels if the student is in an intervention level
    if (this.studentDataService.isIntervention())
    {
      this.levelButtonList1 = this.levelButtonList.slice(0, this.levelButtonList.length/2);
      this.levelButtonList2 = this.levelButtonList.slice(this.levelButtonList.length/2, this.levelButtonList.length);
    }
    else
    {
      this.levelButtonList1 = this.levelButtonList;
    }

    // Set the avatar
    this.avatarPosition = {
      'background-image' : 'url(' + this.studentDataService.getCurrentStudentAvatar().avatarMarker + ')',
      'left' : this.levelSelectProperties.xPositions[this.currentPosition]+0.35 + '%',
      'top' : this.levelSelectProperties.yPositions[this.currentPosition]-5.66 + '%'
    }

    this.initializeController();
  }

  private initializeController() {
    if (this.previousUrl != null && this.previousUrl === '/taskSelect')
    {
      if (this.currentPosition === this.levelButtonList1.length && this.levelButtonList2 !== null && this.levelButtonList2.length !== 0)
      {
        this.playTransition = true;
      }
      else if (this.currentPosition == this.levelButtonList1.length || this.currentPosition == this.levelButtonList.length)
      {
        this.goToWorld();
      }
      else if (this.currentPosition > this.levelButtonList1.length)
      {
        this.levelTwo = true;
      }
      else
      {
        this.levelTwo = false;
      }
    }
    else if (this.currentPosition >= this.levelButtonList1.length)
    {
      this.levelTwo = true;
    }
    else
    {
      this.levelTwo = false;
    }
  }

  private checkForNotifications() {
    this.disableSideButton = true;

    if (this.levelSelectResolveData.allTasksWereJustCompleted &&
        this.levelSelectResolveData.nextAssessmentComplete &&
        !this.studentDataService.isFullProductSubscription())
    {
      if (this.studentDataService.isDiagnosticProductSubscription())
      {
        this.displayEndOfProductAnimation('Audio/Help/instructions_completedstandalonediagnostic.mp3');
      }
      else
      {
        this.displayEndOfProductAnimation('Audio/Help/instructions_completedscreener.mp3');
      }
    }
    else
    {
      // FIXME: This chain of video events has a few issues that need to be addressed.
      //      : First, our local videoSource is an input to the instructions.component that specifies the source to
      //      : load the video from. In this chain, playVideoIfNecessary will update our local copy of videoSource,
      //      : but I don't think change is being detected in the instructions component -- the very first videoSource
      //      : is being used to load the video, even though our copy is updated.
      //      : Second, if no welcome video is necessary, the videoSource is set to an empty string. This is ok, except
      //      : when a transition video is needed (last observable), then because of problem 1 above, it attempts to load
      //      : the video with a videoSource of an empty string, so it never loads.
      //      : Third, a side effect of the second problem is that if for some reason we do need a welcome video and a transition
      //      : video, the videoSource will never update in the instruction component and thus the same welcome video would play first,
      //      : instead of welcome then transition (it will be welcome then welcome)
      //      : Fourth, if the user has not interacted with the page, the video player freezes and the user cannot move on from there.
      this.eventsSubscription = this.playVideoIfNecessary(this.studentDataService.getWelcomeVideoToPlay())
        .pipe(
          // Since the video component emits next multiple times, only respond to the last one.
          //  If there is an error, catch it and continue on with the rest of the chain.
          catchError(error => {
            console.error('Error playing video: ', error);
            return of(error);
          }),
          switchMap(() => this.headerComponent.checkForPointsAnimation()),
          switchMap(() => this.checkForScreenTransition()),
          switchMap(() => this.checkForWinningTeamAvatar()),
          switchMap(() => this.headerComponent.checkForNewTeam()),
          switchMap(() => this.checkForRecentlyAwardedAvatar()),
          switchMap(() => this.checkForWeeklyGoalAvatar()),
          switchMap(() => this.sideMenuComponent.checkForTeamStandingAnimation()),
          concatMap(() => this.playVideoIfNecessary(this.studentDataService.getVideoToPlayOnTransition()))
        )
        .subscribe({
          next: () => {
            this.studentFooter.checkForCountdownClock();
            this.enableAllButtons = true;
            this.disableSideButton = false;
            this.changeDetector.detectChanges();

            // TODO: This should probably just be an observable to watch
            // instead of running an interval every 5 seconds.
            this.sessionTimerIntervalFunc = setInterval(() => {
              if (this.sessionTimerService.hasTimerExpired()) {
                this.router.navigateByUrl('/timeUp');
              }
            }, 5000);
          },
          error: (error) => {
            console.error('Error: ', error)
          },
        });
    }
  }

  private displayEndOfProductAnimation(audioFile: string) {
		this.displayCompletedMessage = true;
    this.hideCompletedMessage = false;
    this.audioPlayerService.play(audioFile).subscribe({
      complete: () => this.logoutStudentAfterAudioDone(),
      error: () => this.logoutStudentAfterAudioDone(),
    });
  }

  private logoutStudentAfterAudioDone() {
    this.animationTimeout = window.setTimeout(() => {
      this.logoutSubscription = this.loginService.studentLogout().pipe(take(1)).subscribe();
    }, 500);
  }

  private playVideoIfNecessary(videoName: string | null) {

    // Should just be able to return the instructionalvideo play

    if (videoName != null)
    {
      this.videoSource = environment.VideoAssetServerURL + '/assets/video/' + videoName + '.mp4';
      this.changeDetector.detectChanges();

      return this.instructionsComponent.playInstructionalVideo();
    }
    else
    {
      return of(false) ;
    }
  }

  // Plays the screen transition if needed and returns an
  //  observable that resolves when the transition has completed.
  //  resolves true if it played, false if it didn't need to play.
  private checkForScreenTransition() {
    // Create an observable from our already stored curriculum data.
    return new Observable((observer: Observer<boolean>) => {
      if (this.playTransition)
      {
        // TODO: Do this in a more Angular manner
        let firstScreen = document.getElementById('first-screen');
        let secondScreen = document.getElementById('second-screen');
        let transitionDirection = this.levelSelectProperties.transitionDirection;

        if (transitionDirection === 'Left')
        {
          firstScreen!.classList.add('firstSelectScreen-Left');
          secondScreen!.classList.add('nextSelectScreen-Left');
        }
        else if (transitionDirection === 'Right')
        {
          firstScreen!.classList.add('firstSelectScreen-Right');
          secondScreen!.classList.add('nextSelectScreen-Right');
        }
        else if (this.levelSelectProperties.transitionDirection === 'Up')
        {
          firstScreen!.classList.add('firstSelectScreen-Up');
          secondScreen!.classList.add('nextSelectScreen-Up');
        }
        else if (transitionDirection === 'Down')
        {
          firstScreen!.classList.add('firstSelectScreen-Down');
          secondScreen!.classList.add('nextSelectScreen-Down');
        }

        setTimeout(() => {
          observer.next(true);
          observer.complete();
        }, 2500);
      }
      else
      {
        observer.next(false);
        observer.complete();
      }
    });
  }

  private checkForWinningTeamAvatar() {
    return new Observable((observer: Observer<boolean>) => {
      if (this.avatar?.winningTeamAvatar && !this.avatar.hasBeenNotifiedOfWinningTeam)
      {
        this.studentDataService.setHasBeenNotifiedOfWinningTeam(true);
        this.awardedAvatar = this.studentDataService.getAvatarList()[0];
        this.showAwardedAvatar = true;
        this.audioPlayerService.play('Audio/Help/winningteam.mp3').subscribe({
          error: (error) => console.log('Error playing winning team avatar audio: ', error.message),
        });
        // wait for animation to complete before resolving
        setTimeout(() => {
          this.showAwardedAvatar = false;
          this.studentDataService.resetRecentlyAwardedAvatar();
          observer.next(true);
          observer.complete();

        }, 7000);
      }
      else
      {
        observer.next(false);
        observer.complete();
      }
    });
  }

  showFireworks() {
    const container = document.querySelector('.fireworks-panel');
    // const container = document.querySelector('.fireworks-panel');
    const fireworks = new Fireworks(container as HTMLCanvasElement, {autoresize: true,
      opacity: 0.7,
      acceleration: 1.05,
      friction: 0.97,
      gravity: 1.5,
      particles: 50,
      traceLength: 3,
      traceSpeed: 10,
      explosion: 12,
      intensity: 80,
      flickering: 45,
      lineStyle: 'round',
      hue: {
        min: 1,
        max: 360,
      },
      delay: {
        min: 120,
        max: 200,
      },
      rocketsPoint: {
        min: 40,
        max: 50,
      },
      lineWidth: {
        explosion: {
          min: 5,
          max: 7,
        },
        trace: {
          min: 1,
          max: 3,
        }
      },
      brightness: {
        min: 50,
        max: 70,
      },
      sound: {
        enabled: true,
        files: [
          '../../../assets/Audio/Other/explosion0.mp3',
          '../../../assets/Audio/Other/explosion1.mp3',
          '../../../assets/Audio/Other/explosion2.mp3'
        ],
        volume: {
          min: 1,
          max: 2
        }
      },
      decay: {
        min: 0.015,
        max: 0.03,
      },
      mouse: {
        click: true,
        move: false,
        max: 2,
      }
    })
    fireworks.start()
    setTimeout(() => {
      fireworks.waitStop()
    }, 7000);
  }



  private checkForWeeklyGoalAvatar() {
    return new Observable((observer: Observer<boolean>) => {
      // alternative check for testing weekly goal celebration process
      // if (this.studentDataService.getStudentLeaderboard().weeklyLevelsCompleted >= 0){
      if (this.studentDataService.getStudentLeaderboard().weeklyLevelsCompleted >= 8 && this.avatar && !this.avatar.hasBeenNotifiedOfWeeklyGoal){
        this.studentDataService.awardAvatar('weeklygoal', 1)
        this.studentDataService.setHasBeenNotifiedOfWeeklyGoal(true);
        this.awardedAvatar = this.studentDataService.getRecentlyAwardedAvatar();
        this.showAwardedAvatar = true;
        this.showFireworks();
        this.audioPlayerService.play('Audio/Help/weeklygoal.mp3').subscribe({
          error: (error) => console.log('Error playing weekly goal avatar audio: ', error.message),
        });
        // wait for animation to complete before resolving
        setTimeout(() => {
          this.showAwardedAvatar = false;
          this.studentDataService.resetRecentlyAwardedAvatar();
          observer.next(true);
          observer.complete();
        },7000)
      }
      else
      {
        observer.next(false);
        observer.complete();
      }
    });
  }

  private checkForRecentlyAwardedAvatar() {
    return new Observable((observer: Observer<boolean>) => {
      this.awardedAvatar = this.studentDataService.getRecentlyAwardedAvatar();
      if (this.awardedAvatar != null)
      {
        this.showAwardedAvatar = true;
        this.audioPlayerService.play('Audio/Help/newcharacter.mp3').subscribe({
          error: (error) => console.log('Error playing help audio: ', error.message),
        });
        // wait for animation to complete before resolving
        setTimeout(() => {
          this.showAwardedAvatar = false;
          this.studentDataService.resetRecentlyAwardedAvatar();
          observer.next(true);
          observer.complete();
        },4000)
      }
      else
      {
        observer.next(false);
        observer.complete();
      }
    });
  }

  private goToWorld() {
    if (this.sessionTimerService.hasTimerExpired())
    {
      this.router.navigateByUrl('/timeUp', {state: {isLevelSelect: true}});
    }
    else
    {
      this.router.navigateByUrl('/world');
    }
  }

  private handleNavigationTimerExpired() {
    this.navigationTimerSubscription?.unsubscribe();
    this.goToTaskSelect();
  }

  goToTaskSelect(index?: number) {
    if (this.sessionTimerService.hasTimerExpired())
    {
      this.router.navigateByUrl('/timeUp', {state: {isLevelSelect: true }});
    }
    else
    {
      if (this.isDemoUser && this.levelTwo && index != null)
      {
        index += this.levelButtonList1.length;
      }
      else if (!this.isDemoUser)
      {
        index = this.currentPosition;
      }

      if (index != null)
      {
        this.studentDataService.setCurrentDestination(this.levelButtonList[index].destination);
        this.router.navigateByUrl('/taskSelect');
      }
      else
      {
        console.error('Could not set current destination');
      }
    }
  }

  ngAfterViewInit() {
    this.initializeLevels();
    this.checkForNotifications();
    this.changeDetector.detectChanges();
  }


  ngOnDestroy() {
    if (this.studentDataService.getSideMenuOpen())
    {
      this.studentDataService.setSideMenuOpen(false);
    }

    this.navigationTimerSubscription?.unsubscribe();
    this.eventsSubscription?.unsubscribe();
    this.logoutSubscription?.unsubscribe();

    clearInterval(this.sessionTimerIntervalFunc);
    window.clearTimeout(this.animationTimeout);
  }
}
