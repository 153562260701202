<div (click)="resetInactivityTimer()" (keyup)="handleKeyUp($event)">
  <div class="aspect-ratio-box">
    <div class="aspect-ratio-box-inside">
      <div class="flexbox-centering">
        <router-outlet></router-outlet>
        <please-wait-dialog *ngIf="showPleaseWaitDialog"></please-wait-dialog>
        <asset-loading-dialog *ngIf="showAssetLoadingDialog" (onLogoutButtonClick)="logout()" (onRetryButtonClick)="reloadAssets()"></asset-loading-dialog>
      </div>
    </div>
  </div>
</div>
