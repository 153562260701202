import { Component, HostListener, OnDestroy, OnInit, NgZone } from '@angular/core';
import { App, URLOpenListenerEvent} from "@capacitor/app";
import { Router } from "@angular/router" ;
import { of, Subject, Subscription } from 'rxjs';
import { catchError, debounceTime, switchMap } from 'rxjs/operators';
import { ConnectivityService } from './core/services/connectivity.service';
import { SessionTimerService } from './core/services/session-timer.service';
import { StudentDataService } from './core/services/student-data.service';
import { LoginService } from './login/login.service';
import { AssetPreloaderService } from './core/services/asset-preloader.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
})
export class AppComponent implements OnInit, OnDestroy {
  showPleaseWaitDialog: boolean = false;
  showAssetLoadingDialog: boolean = false;
  private keyUpSubject = new Subject<string>() ;
  private assetLoadingSubscription?: Subscription ;

  constructor(
    private connectivityService: ConnectivityService,
    private loginService: LoginService,
    private sessionTimerService: SessionTimerService,
    private studentDataService: StudentDataService,
    private assetPreloaderService: AssetPreloaderService,
    private router: Router,
    private zone: NgZone,
  )
  {
    this.initializeApp() ;
  }

  ngOnInit() {
    // Setup session timer expire trigger
    this.onTriggerLogoutHandler().subscribe(() => {
      // When handler is done, rehide the please wait dialog.
      this.showPleaseWaitDialog = false;
    })

    this.startSessionTimers();

    //
    // Only show context menu on right click
    //   if the user is a super user for testing
    window.oncontextmenu = () => {
      return this.studentDataService.isUserSuperUser();
    }

    document.addEventListener('gesturestart', (event) => {
      event.stopPropagation() ;
      event.preventDefault() ;

      return false ;
    }, false) ;

    document.addEventListener('gesturechange', (event) => {
      event.stopPropagation() ;
      event.preventDefault() ;

      return false ;
    }, false) ;

    document.addEventListener('gestureend', (event) => {
      event.stopPropagation() ;
      event.preventDefault() ;

      return false ;
    }, false) ;

    // Handle keyup events to reset inactivity timers, use a debounce to keep from firing over and over
    this.keyUpSubject.pipe(debounceTime(500)).subscribe((key) => {
      this.resetInactivityTimer() ;
    }) ;

    this.assetLoadingSubscription = this.assetPreloaderService.loadingErrorOccurred.subscribe((assetType: string) => {
      if (assetType === 'image' || assetType === 'audio') {
        console.error('Error loading images');
        this.showAssetLoadingDialog = true;
      }
    });
  }
  ngOnDestroy() {
    console.log('destroy???') ;
    this.cancelSessionTimers();
    this.keyUpSubject.complete() ;
    this.assetLoadingSubscription?.unsubscribe() ;
  }

  private cancelSessionTimers() {
    this.sessionTimerService.cancelTimers();
  }

  private initializeApp() {
    App.addListener('appUrlOpen', (event: URLOpenListenerEvent) => {
      this.zone.run(() => {
        const slug = event.url.split(".com").pop();
        if (slug)
        {
          this.router.navigateByUrl(slug);
        }
      });
    });
  }

  private startSessionTimers() {
    // Only start the sleep and inactivity timers if the
    //  user is logged in.
    if (this.studentDataService.isLoggedIn())
    {
      this.sessionTimerService.startSleepAndInactivityTimers();
    }
  }

  // 1. Shows please wait dialog
  // 2. Use the connectivity service to ping the server
  // 3. Call logout if there is a connection otherwise call demoLogout
  // 4. Rehides please wait dialog
  private onTriggerLogoutHandler() {
    return this.sessionTimerService.triggerLogout$.pipe(
      switchMap(() => {
        this.showPleaseWaitDialog = true;
        return this.connectivityService.checkServerConnectivity().pipe(
          catchError(error => {
            this.loginService.demoLogout();
            return of({});
          }),
        );
      }),
      switchMap(() => {
        return this.loginService.studentLogout().pipe(
          catchError(error => {
            this.loginService.demoLogout();
            return of({});
          }),
        );
      }),
    );
  }

  handleKeyUp(event: KeyboardEvent) {
    this.keyUpSubject.next(event.key) ;
  }

  resetInactivityTimer() {
    if (this.studentDataService.isLoggedIn() && !this.studentDataService.isDemoUser()) {
      this.sessionTimerService.startInactivityTimer();
    }
  }

  logout() {
    this.showAssetLoadingDialog = false ;
    this.showPleaseWaitDialog = false ;
    this.loginService.studentLogout().subscribe() ;
  }

  reloadAssets() {
    this.showAssetLoadingDialog = false ;
    this.showPleaseWaitDialog = false ;
    this.router.routeReuseStrategy.shouldReuseRoute = () => false ;
    this.router.onSameUrlNavigation = 'reload' ;
    this.router.navigateByUrl(this.router.url) ;
  }
}
