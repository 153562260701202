import { ChangeDetectorRef, Component, ElementRef, OnInit } from '@angular/core';
import { TaskSelectComponent } from '../task-select/task-select.component';
import { Router } from '@angular/router';
import { SessionTimerService } from '../../core/services/session-timer.service';
import { WorldNavigationTimerService } from '../../core/services/world-nav-timer.service';
import { StudentDataService } from '../../core/services/student-data.service';
import { CurriculumService } from '../../core/services/curriculum.service';
import { AudioPlayerService } from '../../core/services/audio-player.service';
import { SubtaskSelectButton } from '../../core/models/subtask-select-button.model';
import { DiagnosticTask, InterventionTask } from '../../core/models/task.model';
import {
  DiagnosticTaskResponse,
  InterventionTaskResponse,
  InterventionTaskStatus
} from "../../core/models/student-data.model";
import { TaskService } from '../../core/services/task.service';
import {THEMES} from "../../core/models/theme.model";

@Component({
  selector: 'app-subtask-select',
  templateUrl: './subtask-select.component.html',
  styleUrls: ['./subtask-select.component.css']
})
export class SubtaskSelectComponent extends TaskSelectComponent implements OnInit {
  isPulse: boolean = false ;
  completedTasks: number = 1 ;
  totalTasks: number = 4 ;
  taskSelectBackgroundImage: string = '' ;
  subtaskSelectBackgroundImage: string = '' ;
  subtaskButtonList: SubtaskSelectButton[] = [] ;
  allSubtasksComplete: boolean = false ;
  private mainTaskId: string = '' ;

  constructor(
    public router: Router,
    public host: ElementRef,
    public changeDetector: ChangeDetectorRef,
    public sessionTimerService: SessionTimerService,
    public navigationTimerService: WorldNavigationTimerService,
    public studentDataService: StudentDataService,
    public curriculumService: CurriculumService,
    public audioPlayerService: AudioPlayerService,
    public taskService: TaskService,
  )
  {
    super(router, host, changeDetector, sessionTimerService, navigationTimerService, studentDataService, curriculumService, audioPlayerService, taskService);

    // Get our main task id to show and find subtasks for
    this.mainTaskId = this.router.getCurrentNavigation()?.extras.state?.mainTaskId ;
  }

  ngOnInit(): void {
    // Initialize our properties
    this.isInterventionStudent = this.studentDataService.isIntervention() ;
    this.isPreDiagnosticStudent = this.studentDataService.isPreDiagnostic() || this.studentDataService.isScreenerDiagnostic() ;
    this.taskSelectBackgroundImage = this.studentDataService.getTaskSelectBackground() ;
    this.subtaskSelectBackgroundImage = this.studentDataService.getSubtaskSelectBackground() ;
    this.destinationValue = this.studentDataService.getCurrentDestination() ;
    this.levelProperties = this.studentDataService.getLevelProperties() ;

    if (this.studentDataService.isIntervention())
    {
      this.taskList = this.curriculumService.getTasksForDestination(this.destinationValue) as InterventionTask[] ;
    }
    else
    {
      this.taskList = this.curriculumService.getTasksForDestination(this.destinationValue) as DiagnosticTask[] ;
    }

    // Do some sanity checks here, make sure we have a task list and we have a main task id, if not go back to taskSelect
    // Set up our Task Select Buttons
    this.configureTaskButtons(this.destinationValue) ;

    if (!this.mainTaskId || !this.filteredTaskList || !this.filteredTaskList.length)
    {
      this.router.navigateByUrl('/taskSelect', { state: { previous: this.router.url} });
    }

    // Set up our task count and completion count
    this.totalTasks = this.subtaskButtonList.length ;
    this.completedTasks = this.subtaskButtonList.reduce((total: number, taskBttn: SubtaskSelectButton) => {
      return (taskBttn.completed) ? (total + 1) : total ;
    }, 0) ;
    this.allSubtasksComplete = (this.completedTasks === this.totalTasks) ;

    if (this.allSubtasksComplete)
    {
      // Update our subtask select background if all tasks are complete
      this.subtaskSelectBackgroundImage = this.subtaskSelectBackgroundImage.replace('_incomplete', '_complete') ;
    }

    // Listen for the navigation timer service expiration then we can auto-select the first task if the user does not choose in time
    // NOTE: The footer component currently houses the logic to determine whether the user is a superuser and needs the nav timer
    this.navigationTimerSubscription = this.navigationTimerService.expired.subscribe((expired) => {
      if (expired) this.handleNavigationTimerExpired() ;
    }) ;
  }

  goBack() {
    this.router.navigateByUrl('/taskSelect', { state: { canGoToTaskSelect: true, } }) ;
  }

  gotoSubtask(idx: number) {
    // Sanity check
    if (idx > this.filteredTaskList.length) {
      this.goBack() ;

      return ;
    }

    // Navigating to a subtask can be different if our main task is a passage, so handle that correctly
    const selectedTask = this.filteredTaskList[idx] ;
    let interventionTaskData = this.studentDataService.getInterventionTask(selectedTask.id, this.destinationValue);
    let secondAttempt = (interventionTaskData!.numberOfTrials > 0);
    let mainTask = (this.filteredTaskList as Array<InterventionTask | DiagnosticTask>).find((task: DiagnosticTask | InterventionTask) => {
      return task.id === this.mainTaskId ;
    }) ;
    let stateParams = {
      wordListAttempt: 1,
      attempt: (secondAttempt) ? 2 : 1,
      subtask: true,
      parentTaskId: this.mainTaskId,
      passage: (mainTask?.id.startsWith('PASS') ? (mainTask as InterventionTask).passage : undefined),
    } ;

    this.studentDataService.setSelectedTask(selectedTask) ;
    // Now go to our directed subtasks as we should
    if (selectedTask.taskName.toLowerCase().includes('read and listen'))
    {
      this.router.navigateByUrl('/passagesReadAndListen', { state: stateParams }) ;
    }
    else if (selectedTask.taskName.toLowerCase().includes('picture'))
    {
      this.router.navigateByUrl('/findThePictureIntervention', { state: stateParams }) ;
    }
    else if (selectedTask.taskName.toLowerCase().includes('question'))
    {
      this.router.navigateByUrl('/answerTheQuestionIntervention', { state: stateParams }) ;
    }
    else if (selectedTask.taskName.toLowerCase().includes('sentence'))
    {
      this.router.navigateByUrl('/readTheSentenceIntervention', { state: stateParams }) ;
    }
    else if (selectedTask.taskName.toLowerCase().includes('word'))
    {
      this.router.navigateByUrl('/readTheWordIntervention', { state: stateParams }) ;
    }
  }

  // This seems a bit unnecessary to handle the hover here, but our button image is dependent on
  // the theme, we cannot easily add a :hover pseudo class in CSS to handle
  toggleHighlight(index: number, shouldHighlight: boolean) {
    if (index < 0 || index > this.subtaskButtonList.length) return ;

    if (shouldHighlight)
    {
      this.subtaskButtonList[index].buttonImage = this.subtaskButtonList[index].buttonImage.replace('_bu', '_bh') ;
    }
    else
    {
      this.subtaskButtonList[index].buttonImage = this.subtaskButtonList[index].buttonImage.replace('_bh', '_bu') ;
    }
  }

  protected configureTaskButtons(destination: number) {
    if (this.taskList === null || this.taskList.length === 0) return ;

    let taskIdString = '' ;
    let studentTaskList = this.studentDataService.getStudentTaskStatusForDestination(destination) ;
    if (!this.isInterventionStudent)
    {
      taskIdString = 'taskIdentifier' ;
      this.filteredTaskList = this.taskList ;
    }
    else
    {
      // Filter the taskList to only include tasks for a single wordlist if student is in intervention
      taskIdString = 'interventionTaskIdentifier' ;
      this.filteredTaskList = this.filterTaskList(this.taskList as InterventionTask[], studentTaskList as InterventionTaskStatus[]) ;
    }

    this.filteredTaskList.forEach((task: DiagnosticTask | InterventionTask) => {
      let taskComplete = this.studentDataService.isTaskComplete(studentTaskList, destination, task.id, taskIdString, true) ;
      let disabled = taskComplete && !this.studentDataService.isInterventionUnit() ;
      let taskCompletionCount = this.studentDataService.getTaskCompletionCount(task.id);
      if (taskCompletionCount && taskCompletionCount >= 4) {
        disabled = true ;
      }

      // Determine if we are a passage and have a passage title to show
      let passageTitle = null ;
      if (this.isInterventionStudent && task.id.startsWith('PASS'))
      {
        // Only interventions
        passageTitle = (task as InterventionTask).passage!.title!['#text'] ;
      }

      this.subtaskButtonList.push(new SubtaskSelectButton(
        (this.mainTaskId === task.id),
        this.getSubtaskType(task),
        task.taskName,
        passageTitle,
        this.studentDataService.getTaskPercentage(task.id, true),
        true,
        disabled,
        taskComplete,
        this.levelProperties
      )) ;
    }) ;

    this.enableAutocomplete = true ;
  }

  protected filterTaskList(taskList: InterventionTask[], studentTaskList: InterventionTaskStatus[]): InterventionTask[] {
    let mainTasks: InterventionTask[] = [] ;
    let subTasks: InterventionTask[] = [] ;
    let filteredTasks: InterventionTask[] = [] ;
    const wordListType = this.studentDataService.getWordListType() ;

    // Find the task that matches our main task ID and any subtasks of it
    // subtasks will have an ID that matches the suffix, e.g. 'PASS-A-4'
    // main task id will have subtasks that match '<X>-A-4'
    let mainTaskSuffix = '' ;
    const mainTaskSuffixMatch = this.mainTaskId.toUpperCase().match(/^[A-Z0-9]+(-[A-Z]-[0-9]+)/) ;
    if (mainTaskSuffixMatch)
    {
      mainTaskSuffix = mainTaskSuffixMatch[1] ;
    }

    // We search our task list and separate out our found main task and any subtasks
    // NOTE: Tasks can be repeated because a task can have multiple entries, each with a different wordlistType
    taskList.forEach((task) => {
      const taskStatusExists = studentTaskList.some((st) => st.interventionTaskIdentifier === task.id) ;
      const isBetaTask: boolean = this.taskService.isBetaTask(task.id) ;
      const isBetaSpeechTask: boolean = this.taskService.isBetaSpeechTask(task.id) ;

      if (isBetaTask && !this.studentDataService.getStudentData().betaTasksEnabled) return ;
      if (isBetaSpeechTask && !this.studentDataService.getStudentData().betaSpeechTasksEnabled) return ;
      if (taskStatusExists && (task.id.toUpperCase() === this.mainTaskId.toUpperCase()))
      {
        mainTasks.push(task) ;
      }
      else if (taskStatusExists && (mainTaskSuffix !== '' && task.id.endsWith(mainTaskSuffix)))
      {
        subTasks.push(task) ;
      }
    }) ;

    // Now that we have our tasks separate, attempt to identify the correct wordlistType for the task and add them
    [mainTasks, subTasks].forEach((taskList) => {
      if (taskList.length === 1)
      {
        filteredTasks.push(taskList[0]) ;
      }
      else if (taskList.length > 1)
      {
        let tasksOrderedByType: any = {};
        taskList.forEach((task) => {
          tasksOrderedByType[task.id] = {} ;
          tasksOrderedByType[task.id][task.wordlistType] = task;
        });

        Object.keys(tasksOrderedByType).forEach((taskId) => {
          const orderedTask = tasksOrderedByType[taskId] ;
          if (orderedTask.hasOwnProperty(wordListType))
          {
            filteredTasks.push(orderedTask[wordListType]);
          }
          else
          {
            if (wordListType.toLowerCase() === 'challenge')
            {
              if (orderedTask.hasOwnProperty('medium')) filteredTasks.push(orderedTask['medium']);
              else if (orderedTask.hasOwnProperty('easy')) filteredTasks.push(orderedTask['easy']);
            }
            else if (wordListType.toLowerCase() === 'medium')
            {
              if (orderedTask.hasOwnProperty('easy')) filteredTasks.push(orderedTask['easy']);
              else if (orderedTask.hasOwnProperty('challenge')) filteredTasks.push(orderedTask['challenge']);
            }
            else if (wordListType.toLowerCase() === 'easy')
            {
              if (orderedTask.hasOwnProperty('medium')) filteredTasks.push(orderedTask['medium']);
              else if (orderedTask.hasOwnProperty('challenge')) filteredTasks.push(orderedTask['challenge']);
            }
          }
        }) ;
      }
    });

    return filteredTasks ;
  }

  protected handleNavigationTimerExpired() {
    this.navigationTimerSubscription?.unsubscribe();

    let taskIdx: number = this.subtaskButtonList.findIndex((task) => !task.completed) ;
    if (taskIdx !== -1)
    {
      this.gotoSubtask(taskIdx) ;
    }
    else
    {
      this.router.navigateByUrl('/taskSelect', { state: { previous: this.router.url} });
    }
  }

  autoCompleteSubtask($event: KeyboardEvent) {
    if (!this.enableAutocomplete || !this.studentDataService.isUserSuperUser() || ($event.key !== 'c') || this.subtaskButtonList.length === 0)
    {
      return ;
    }

    // Find a task to complete
    let task: DiagnosticTask | InterventionTask | null = null ;
    let alreadyCompletedFlag: boolean | null = null ;
    this.enableAutocomplete = false ;
    for (let taskBtnIdx = 0 ; taskBtnIdx < this.subtaskButtonList.length ; taskBtnIdx++)
    {
      // For intervention, check to see if the task is already complete
      // If not intervention, we'll just say it is not complete and let the other
      // logic for visibility dictate whether we need to complete it or not
      let taskBtn = this.subtaskButtonList[taskBtnIdx] ;
      if (!taskBtn.disabled && taskBtn.visible && !taskBtn.completed)
      {
        // We found a task to autocomplete, so start it and complete it
        task = this.filteredTaskList[taskBtnIdx] ;
        break ;
      }
    }

    if (task !== null)
    {
      this.studentDataService.setSelectedTask(task);
      let response: DiagnosticTaskResponse | InterventionTaskResponse[] ;
      if (this.isInterventionStudent)
      {
        response = [] ;

        // Autocomplete the intervention task
        let numberOfTrials = (!task.trial) ? 0 : task.trial.length ;
        let interventionData = this.studentDataService.getStudentInterventionData() ;
        let additionalData = {
          wordListType: this.studentDataService.isInterventionUnit() && (task as InterventionTask).wordlistType ? this.studentDataService.getWordListTypeAsInt((task as InterventionTask).wordlistType) : null,
          durationMillis: this.studentDataService.getSelectedTaskDurationMillis(),
          objectiveNumber: interventionData?.objectiveNumber ?? 0,
          type: interventionData?.type ?? 0,
          unitNumber: interventionData?.unitNumber ?? 0,
        } ;
        response.push((task as InterventionTask).generateAutocompleteResponse(additionalData)) ;
        alreadyCompletedFlag = this.studentDataService.isInterventionTaskComplete(this.destinationValue, (task as InterventionTask)) ;

        let cumulativeTotalPoints: number = response.reduce((cumulativeTotalPoints, resp) => {
          return resp.points + cumulativeTotalPoints ;
        }, 0) ;

        this.studentDataService.setTotalPoints(this.destinationValue, cumulativeTotalPoints, numberOfTrials, numberOfTrials) ;
      }
      else
      {
        // Start our task
        this.studentDataService.setSelectedTask(task) ;
        this.studentDataService.startSelectedTask() ;

        response = (task as DiagnosticTask).generateAutocompleteResponse() ;
        this.studentDataService.setTotalPoints(this.destinationValue, response.points, response.numberOfTrials, response.numberOfCorrectTrials) ;
      }

      // Save our data and go back to task select
      this.studentDataService.saveTrialData(response).subscribe({
        complete:() => {
          // Because we are calling the same route, we need to let the router do this by telling it to reload even though the app
          // knows that we are on the same route. More so, we need to also tell Angular to *not* reuse the same component instance
          // TODO: Check to see the optimization of this
          this.router.routeReuseStrategy.shouldReuseRoute = () => false ;
          this.router.onSameUrlNavigation = 'reload' ;
          this.router.navigateByUrl('/subtaskSelect', {
            state: {
              canGoToTaskSelect: true,
              lastTaskCompletedID: task!.id,
              alreadyCompleted: alreadyCompletedFlag,
              mainTaskId: this.mainTaskId,
            }
          }) ;
        }
      }) ;
    }
  }

  private getSubtaskType(task: InterventionTask | DiagnosticTask): string {
    switch (task.taskName.toLowerCase()) {
      case 'find the picture':
        return 'picture' ;
      case 'read and listen':
        return 'passage' ;
      case 'read the sentence':
      case 'read the word':
        return 'record';
      case 'answer the question':
      default:
        return 'questions';
    }
  }

  protected readonly THEMES = THEMES;
}
