import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { of } from 'rxjs';
import { concatMap, first } from 'rxjs/operators';
import { DiagnosticTrial } from 'src/app/core/models/task.model';
import { AssetPreloaderService } from 'src/app/core/services/asset-preloader.service';
import { AudioPlayerService } from 'src/app/core/services/audio-player.service';
import { ApplicationStateService } from 'src/app/core/services/application-state.service';
import { ShuffleService } from 'src/app/core/services/shuffle.service';
import { StudentDataService } from 'src/app/core/services/student-data.service';
import { TimerService } from 'src/app/core/services/timer.service';
import { DiagnosticTaskComponent } from '../diagnostic-task.component';
import { TaskService } from '../../core/services/task.service';

@Component({
  selector: 'app-find-the-rhyme',
  templateUrl: './find-the-rhyme.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FindTheRhymeComponent extends DiagnosticTaskComponent implements OnInit, AfterViewInit {
  trials: DiagnosticTrial[] = this.task.trial;

  // Task specific variables
  targetWord: string = '';
  toggleNextButton: boolean = false;
  hideResponses: boolean = true;
  unmaskedTask: boolean;
  responseOptions: string[] = [];
  responseOptions1: string[] = [];
  responseOptions2: string[] = [];
  maskerTime: number = 0;

  constructor(
    public router: Router,
    public applicationStateService: ApplicationStateService,
    public studentDataService: StudentDataService,
    public audioPlayerService: AudioPlayerService,
    public timerService: TimerService,
    public shuffleService: ShuffleService,
    public assetPreloaderService: AssetPreloaderService,
    public taskService: TaskService,
    public changeDetector: ChangeDetectorRef,
  ) {
    super(router, applicationStateService, studentDataService, audioPlayerService, timerService, shuffleService, assetPreloaderService);

    this.unmaskedTask = this.task.trial[0].masked === 'U';
  }
  ngOnInit(): void {
    if (this.task.randomTrials) {
      this.shuffleService.shuffleArray(this.trials);
    }
  }

  ngAfterViewInit(): void {
    // After view is initialized wait for task animation to complete and then initialize everything else
    this.taskBar.taskAnimationComplete.pipe(first()).subscribe((complete) => {
      if (complete) {
        // set this to tell the trial-counter that animation is complete
        this.animationComplete = true;
        this.displayTaskContainerElements()
        .pipe(first(),
          concatMap(() => {
            if (!this.studentDataService.isDemoUser() && !this.studentDataService.hasCompletedAtLeastOneTaskLikeThis(this.task.id)) {
              this.playInstructionalAudio = false;
              return this.instructions.playInstructionalVideo();
            }
            else {
              return of({});
            }
          }),
          concatMap(() => {
            if (this.playInstructionalAudio) {
              return this.audioPlayerService.play(this.instructionalAudioFile);
            } else {
              return of({});
            }
          })
        )
        .subscribe({
          complete: () => this.instructionalAudioCompleteFunction(),
        });
      }
    });
  }

  instructionalAudioCompleteFunction() {
    this.reusableTimer = window.setTimeout(() => {
      this.disableNextButton = false;
      this.readyToHighlight = true;
      this.hideResponses = false;
      this.buildResponseLists(this.trialIndex);
      if (this.unmaskedTask){
        this.displayTrial();
      } else {
        this.createInterval();
      }
    }, 0);
  }

  buildResponseLists(index: number) {
    // Build the response option lists from the curriculum
    this.responseOptions = [];
    for (let response in this.trials[index]['resp-list'].resp) {
      this.responseOptions.push(this.trials[index]['resp-list'].resp[response]['#text']);
    }

    if (this.trials[index]['resp-list']['@randomResponses']) {
      this.shuffleService.shuffleArray(this.responseOptions);
    }

    this.responseOptions1 = this.responseOptions.slice(0, (this.responseOptions.length / 2));
    this.responseOptions2 = this.responseOptions.slice(((this.responseOptions.length / 2)), this.responseOptions.length);
  }

  displayTrial() {
    this.stopInterval();
    this.readyToHighlight = false;
    this.disableNextButton = true;
    this.hideNextButton = true;

    this.maskerTime = parseInt(this.trials[this.trialIndex].maskerTime);
    this.targetWord = "______";
    this.changeDetector.markForCheck() ;

    this.reusableTimer = window.setTimeout(() => {
      // NOTE: tile can be an array, so use toString to ensure its a string for this task
      this.targetWord = this.trials[this.trialIndex].display.tile.toString();
      this.changeDetector.markForCheck() ;
      this.displayMasker();
    }, this.task.focuserTime || 750);
  }

  displayMasker() {
    if (!this.unmaskedTask) {
      this.reusableTimer = window.setTimeout(() => {
        this.targetWord = "########";
        this.disableResponseButtons = false;
        this.changeDetector.markForCheck() ;
        this.startTime = this.timerService.startTimer();
      }, this.maskerTime);
    }
    else {
      this.disableResponseButtons = false;
      this.changeDetector.markForCheck() ;
      this.startTime = this.timerService.startTimer();
    }
  }

  submitAnswer(index: number) {
    this.endTime = this.timerService.stopTimer();
    this.audioPlayerService.play('Audio/Help/SNDneutral.mp3').subscribe({
      error(event) {
        console.error('Error playing audio: ', event);
      }
    });
    this.disableNextButton = true;
    this.hideResponses = true;
    this.targetWord = '';
    this.changeDetector.markForCheck() ;

    let selectedResponse: string = this.responseOptions[index];
    let foil = null;
    let word = this.trials[this.trialIndex].word || "";
    let responsePosition = index + 1 || 0;
    let responseType = this.trials[this.trialIndex]['resp-list'].resp[index]['@type'] || "";
    // NOTE: word can be an object with #text and @audio properties in other tasks,
    //  using toString here to ensure target is just a string
    let target: string = word.toString();
    // Build a response object and save it to the trialList
    let response = this.submitResponse(selectedResponse, foil, target, responsePosition, responseType);

    this.taskService.answerTrial(response.correct) ;
    // Make sure we didn't just complete the last task
    if (this.trialIndex + 1 < this.trials.length) {
      this.trialIndex += 1;
      this.disableResponseButtons = true;
      this.reusableTimer = window.setTimeout(() => {
        this.hideResponses = false;
        this.disableNextButton = false;
        this.hideNextButton = false;
        this.targetWord = '';
        this.buildResponseLists(this.trialIndex);
        this.readyToHighlight = true;
        if (this.unmaskedTask){
          this.displayTrial();
        } else {
          this.createInterval();
        }
      }, 750);
    }
    else {
      this.reusableTimer = window.setTimeout(() => {
        this.saveStudentData();
      }, 750); // Just needs a little time to finish trial counter animation
    }
  }

}
