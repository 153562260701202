import { ChangeDetectionStrategy, Component, Input, ViewChild } from '@angular/core';
import { VgApiService } from '@videogular/ngx-videogular/core'
import { Observable } from 'rxjs';

import { StudentDataService } from 'src/app/core/services/student-data.service';

@Component({
  selector: 'instructions',
  templateUrl: './instructions.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InstructionsComponent {

  @Input() videoSource: string = '';

  @ViewChild('videoPlayerElement') videoPlayerElement: HTMLVideoElement | undefined | null;

  playerAPI: VgApiService;
  onCompleteFunc: any = null;
  viewInstructionalVideo: boolean = false;

  constructor(
    private studentDataService: StudentDataService,
    public vgApiService: VgApiService,
  ) {
    this.playerAPI = vgApiService ;
  }

  onPlayerReady(API: VgApiService): void {
    //this.playerAPI = API.getDefaultMedia();
    this.playerAPI = API ;
    this.playerAPI.getDefaultMedia().subscriptions.ended.subscribe(
      () => {
        this.playerAPI.currentTime=0;
      }
    );

    this.playerAPI.getDefaultMedia().subscriptions.pause.subscribe(
      () => {
        this.onUpdateState();
      }
    );
  }

  onUpdateState(): void {
    if (this.playerAPI.state === 'paused')
    {
      if (this.videoPlayerElement != null && this.videoPlayerElement)
      {
        // iOS - if student exits full screen mode by using pinch gestures,
        // we'll automatically stop the video and return to the screen to
        // prevent weird scenarios where the student is stuck in the video screen
        if (document.fullscreenElement)
        {
          document.exitFullscreen().then(() => {
            console.log("exiting fullscreen");
          }
          ).catch((err) => {
            console.log(err);
          })
        }
        // Future idea: Find a way to pause the video so we can
        // continue playing where we left off instead of having to start
        // over again.
        // if (stopVideoOnPause) {
        this.playerAPI.pause();
        //}
      }
    }
  }

  // Play and show the instructional video, if we have our player API loaded
  playInstructionalVideo(): Observable<any> {
    return new Observable((observer) => {
      // This is need this for when the video is skipped for DEMO users to continue
      // on as expected.
      // NOTE If we ever want to add a pause functionality to these videos we will have to rethink this
      let pauseSubscription = this.playerAPI.getDefaultMedia().subscriptions.pause.subscribe(() => {
        observer.next();
        observer.complete();
        this.viewInstructionalVideo = false;
      });

      let errorSubscription = this.playerAPI.getDefaultMedia().subscriptions.error.subscribe((error) => {
        observer.error(error);
        this.viewInstructionalVideo = false;
      });

      this.viewInstructionalVideo = true;
      this.playerAPI.play();

      return {
        unsubscribe: () => {
          pauseSubscription.unsubscribe();
          errorSubscription.unsubscribe();
        }
      }
    });
  }

  skipVideo(): void {
    if (this.studentDataService.isUserSuperUser() || this.studentDataService.isDemoUser())
    {
      if (this.playerAPI != null)
      {
        this.playerAPI.pause();
        // reset player time to zero if skipped for replay
        this.playerAPI.currentTime = 0;
      }
    }
  }

  replayVideo(): void {
    this.playInstructionalVideo().subscribe();
  }
}
