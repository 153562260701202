import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { InterventionTaskComponent } from '../intervention-task.component';
import { StudentDataService } from '../../core/services/student-data.service';
import { InterventionTaskService } from '../../core/services/intervention-task.service';
import { ShuffleService } from '../../core/services/shuffle.service';
import { TimerService } from '../../core/services/timer.service';
import { AudioPlayerService } from '../../core/services/audio-player.service';
import { TaskService } from '../../core/services/task.service';
import { Router } from '@angular/router';
import { concatMap, first, map, mergeMap } from 'rxjs/operators';
import { of, Subscription } from 'rxjs';
import { ApplicationStateService } from '../../core/services/application-state.service';
import { InterventionTrial, Response } from '../../core/models/task.model';
import { ResponseOption, PrefixSuffixResponseOption } from "./change-the-meaning-intervention.model";

@Component({
  selector: 'app-change-the-root-word-intervention',
  templateUrl: './change-the-meaning-intervention.component.html',
  styleUrls: ['./change-the-meaning-intervention.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChangeTheMeaningInterventionComponent extends InterventionTaskComponent implements OnInit, AfterViewInit, OnDestroy
{
  trials: InterventionTrial[] = this.task.trial;
  changeAudio: string = '';
  makeAudio: string = '' ;
  toAudio: string = '';
  rootWordTile: string = '';
  prefixSuffixResponseCorrect: boolean = false ;
  prefixTile: string = '?';
  suffixTile: string = '?';
  correctBaseResponse: ResponseOption | null | undefined = null ;
  selectedBaseResponse: ResponseOption | null = null ;
  selectedPrefixResponse: PrefixSuffixResponseOption | null = null ;
  selectedSuffixResponse: PrefixSuffixResponseOption | null = null ;
  correctBaseAudio: string = '' ;
  correctPrefixAudio: string = '' ;
  correctSuffixAudio: string = '' ;
  topDictionaryImageURL: string = '';
  bottomDictionaryImageURL: string = '';
  morpheme: string = '';
  baseWordTrialComplete: boolean = false;
  showSubmitButton: boolean = false ;
  disableSubmitButton: boolean = true ;
  responsesReduced: boolean = false ;
  correctAnswer: string = '';
  baseAttempts: number = 0 ;
  prefixAttempts: number = 0;
  suffixAttempts: number = 0;
  firstResponseTime: number = 0;
  secondResponseTime: number = 0;
  sequentialIncorrect: number = 0 ;
  currentTrial: any = {};
  numberOfAttemptsForTrial: number = 0 ;
  numberOfCorrectTrials: number = 0;
  baseWordResponseOptions: ResponseOption[] = [] ;
  prefixResponseOptions: PrefixSuffixResponseOption[] = [] ;
  suffixResponseOptions: PrefixSuffixResponseOption[] = [] ;

  private audioPlayerSubscription: Subscription = new Subscription();

  constructor(
    public studentDataService: StudentDataService,
    public interventionTaskService: InterventionTaskService,
    public shuffleService: ShuffleService,
    public timerService: TimerService,
    public audioPlayerService: AudioPlayerService,
    public taskService: TaskService,
    public router: Router,
    public changeDetector: ChangeDetectorRef,
    public applicationStateService: ApplicationStateService
  ) {
    super(studentDataService, interventionTaskService, timerService, audioPlayerService, router, changeDetector, applicationStateService);
  }

  ngOnInit(): void {
    if (this.task.randomTrials) {
      this.trials = this.shuffleService.shuffleArray(this.trials);
    }
    this.currentTrial = this.trials[this.trialIndex] ;
    this.changeAudio = 'Audio/Help/' + this.task.trialaudiochange + '.mp3';
    this.toAudio = 'Audio/Help/' + this.task.trialaudioto + '.mp3';
    this.makeAudio = 'Audio/Help/initial_make.mp3' ;
    this.taskTotalPoints = this.interventionTaskService.getStartingPoints(this.task.id, this.currentDestination, this.wordListAttempt);
  }

  ngAfterViewInit() {
    // After view is initialized wait for task animation to complete and then initialize everything else
    this.taskBar.taskAnimationComplete.pipe(first()).subscribe(() => {
      // set this to tell the trial-counter that animation is complete
      this.animationComplete = true;
      this.interventionTaskService.initTaskContainerElements(this.task, this.alreadyCompleted, this.wordListAttempt, this.attempt)
        .pipe(
          first(),
          map(() => {
            let timerBarSettings = this.interventionTaskService.getTimerBarTaskSettings();
            // TODO: OPTIMIZE could probably find a better way to set this.hideTimer and handle timerBarSettings
            timerBarSettings.timerBarEnabled ? this.trialTimerBar.showTimerBar() : this.trialTimerBar.hideTimerBar();
            this.hideTimer = !timerBarSettings.timerBarEnabled;
          }),
          concatMap(() => {
            if (!this.studentDataService.hasCompletedAtLeastOneTaskLikeThis(this.task.id) && this.interventionTaskService.getPlayVideoFlag()) {
              this.playInstructionalAudio = false;
              return this.instructions.playInstructionalVideo();
            } else {
              return of({});
            }
          }),
          concatMap(() => {
            if (this.playInstructionalAudio) {
              return this.audioPlayerService.play(this.interventionTaskService.getInstructionalAudioFile());
            } else {
              return of({});
            }
          })
        )
        .subscribe({
          complete: () => this.defaultAudioCompleteFunc(),
          error: (err) => console.log(err),
        });
    });

    // Display the focus dialog if needs focus is set (from intervention task)
    if (this.needsFocus) {
      this.focusDialog.showDialog();
    }
  }

  ngOnDestroy() {
    this.audioPlayerSubscription.unsubscribe();

    super.ngOnDestroy();
  }

  createResponseList() {
    this.dataTracker = this.interventionTaskService.createTrialDataTrackerObject();

    // Set data tracker opts for CTM specific values (morpheme and speechPart)
    this.dataTracker.morpheme = this.currentTrial['morpheme'] ;
    this.dataTracker.speechPart = this.currentTrial['speechPart'] ;

    // Construct our response options for the template
    if (this.currentTrial['morpheme'] === 'base') {
      // Create a shallow copy of the response list
      let responseList = this.trials[this.trialIndex]['resp-list']!.resp.slice();
      let correctResponse = responseList.find((resp) => resp['@type'] === 'Correct') ;
      let correctText = correctResponse ? correctResponse['#text'] : '' ;

      responseList = this.reduceResponsesIfNecessary(responseList) ;
      responseList = this.interventionTaskService.shuffleResponses(responseList, this.trials[this.trialIndex]['resp-list']!['@randomResponses']);
      this.addResponseOptions(responseList, this.baseWordResponseOptions, correctText) ;
    }
    else if (this.currentTrial['morpheme'] === 'prefix') {
      let correctText = (this.trials[this.trialIndex].correct!.resp as { '@audio': string ; '#text': string ; }[])[0]['#text'] ;
      // Create a shallow copy of the response list
      let responseList = this.trials[this.trialIndex]['resp-list']!.resp.slice();
      responseList = this.reduceResponsesIfNecessary(responseList) ;
      responseList = this.interventionTaskService.shuffleResponses(responseList, this.trials[this.trialIndex]['resp-list']!['@randomResponses']);
      this.addResponseOptions(responseList, this.prefixResponseOptions, correctText) ;
    }
    else if (this.currentTrial['morpheme'] === 'suffix') {
      let correctText = (this.trials[this.trialIndex].correct!.resp as { '@audio': string ; '#text': string ; }[])[1]['#text'] ;
      // Create a shallow copy of the response list
      let responseList = this.trials[this.trialIndex]['resp-list']!.resp.slice();
      responseList = this.reduceResponsesIfNecessary(responseList) ;
      responseList = this.interventionTaskService.shuffleResponses(responseList, this.trials[this.trialIndex]['resp-list']!['@randomResponses']);
      this.addResponseOptions(responseList, this.suffixResponseOptions, correctText) ;
    }
    else if (this.currentTrial['morpheme'] === 'both') {
      let respArr: any = this.trials[this.trialIndex]['resp-list'] ;
      if (!respArr || !Array.isArray(respArr) || respArr.length !== 2) {
        throw new Error(`Encountered CTM task (${this.task.id}) with a response list that was supposed to be an array but had an unexpected length: ${respArr.length}`) ;
      }

      respArr.forEach((respOpt) => {
        // Create a shallow copy of the response list
        let responseList = respOpt.resp.slice();
        responseList = this.reduceResponsesIfNecessary(responseList) ;
        responseList = this.interventionTaskService.shuffleResponses(responseList, respOpt['@randomResponses']);

        if (respOpt['@position'] === 'prefix') {
          let correctText = (this.trials[this.trialIndex].correct!.resp as { '@audio': string ; '#text': string ; }[])[0]['#text'] ;
          this.addResponseOptions(responseList, this.prefixResponseOptions, correctText) ;
        }
        else if (respOpt['@position'] === 'suffix') {
          let correctText = (this.trials[this.trialIndex].correct!.resp as { '@audio': string ; '#text': string ; }[])[2]['#text'] ;
          this.addResponseOptions(responseList, this.suffixResponseOptions, correctText) ;
        }
      }) ;
    }
  }

  defaultAudioCompleteFunc() {
    this.displayTrial();
  }

  disableVideo() {}

  displayTrial() {
    this.disableAVButtons = true;
    this.numberOfAttemptsForTrial = 0 ;
    this.topDictionaryImageURL = '';
    this.bottomDictionaryImageURL = '';
    this.correctAnswer = this.currentTrial.word['#text'];
    this.createResponseList() ;

    if (this.currentTrial['dictionary-list'].dictionary[1]['@image']) {
      this.topDictionaryImageURL = `/assets/${this.currentTrial['dictionary-list'].dictionary[1]['@image']}`;
    }
    else
    {
      this.topDictionaryImageURL = '' ;
    }

    if ((this.trialIndex % 2 !== 0) && this.currentTrial['dictionary-list'].dictionary[3]['@image']) {
      this.bottomDictionaryImageURL = `/assets/${this.currentTrial['dictionary-list'].dictionary[3]['@image']}` ;
    }
    else
    {
      this.bottomDictionaryImageURL = '' ;
    }

    // Set our correct target audio files
    if (this.baseWordResponseOptions.length)
    {
      const corr = this.baseWordResponseOptions.find((resp) => resp.isCorrect) ;
      this.correctBaseAudio = (corr && corr.audio) ? corr.audio : '' ;
    }

    if (this.prefixResponseOptions.length)
    {
      const corr = this.prefixResponseOptions.find((resp) => resp.isCorrect) ;
      this.correctPrefixAudio = (corr && corr.audio) ? corr.audio : '' ;
    }

    if (this.suffixResponseOptions.length)
    {
      const corr = this.suffixResponseOptions.find((resp) => resp.isCorrect) ;
      this.correctSuffixAudio = (corr && corr.audio) ? corr.audio : '' ;
    }

    if (this.currentTrial.morpheme === 'base') {
      this.showSubmitButton = false ;
      this.changeDetector.markForCheck() ;

      // Play our instructional audio
      this.audioPlayerSubscription = this.audioPlayerService.play(this.interventionTaskService.getTrialInstructionalAudio()).subscribe({
        complete: () => this.trialInstructionalAudioComplete(),
      });
    }
    else
    {
      this.showSubmitButton = true ;
      this.changeDetector.markForCheck() ;

      // Play our instructional audio
      this.playChangeToAudio(() => this.trialLoopAudioComplete());
    }
  }

  // Show the user the response they selected for one second before going to the next trial
  // Run through the task with the next trial in the curriculum
  endOfTrialCallback() {
    this.reusableTimer = window.setTimeout(() => {
      this.trialTimerBar.resetTrialTimer();
      this.removeResponseHighlighting();
      this.trialIndex += 1;

      if (this.trialIndex < this.trials.length) {
        this.currentTrial = this.trials[this.trialIndex] ;
        this.disableNextButton = false;
        this.hideNextButton = false;
        this.disableAgainButton = true;
        this.disableResponseButtons = true ;
        this.hideAgainButton = true;
        this.prefixTile = '' ;
        this.suffixTile = '' ;
        this.selectedPrefixResponse = null ;
        this.selectedSuffixResponse = null ;
        this.prefixResponseOptions = [] ;
        this.suffixResponseOptions = [] ;
        this.baseAttempts = 0 ;
        this.prefixAttempts = 0 ;
        this.suffixAttempts = 0 ;
        this.prefixSuffixResponseCorrect = false ;
        this.hideResponses = true;

        // Only clear our root word if we are circling back to the base morpheme
        // (we start there so seeing it again means we came back)
        if (this.currentTrial.morpheme === 'base') {
          this.rootWordTile = '' ;
          this.selectedBaseResponse = null ;
          this.baseWordResponseOptions = [] ;
          this.correctBaseResponse = null ;

          // If we are back on a 'base' word, we need to see if we are in a sequence
          // that needs response reduction (>= 2 in a row incorrect). We only flip this
          // during the 'base' so in a 'suffix/prefix' we do not change response options
          this.responsesReduced = (this.sequentialIncorrect >= 2) ;
        }

        this.displayTrial();
      }
      else {
        this.hideNextButton = true;
        this.hideAgainButton = true;
        this.saveTaskData();
      }
    }, this.interventionTaskService.getDelayAfterSingleResponse(this.trialList));
  }

  firstResponseIncorrectSequence(currentMorpheme: string) {
    this.disableAgainButton = true;
    this.hideAgainButton = true;
    this.showResponseAudioButtons = true;
    this.disableResponseButtons = false;
    this.disableAVButtons = false;

    if (currentMorpheme === 'base') {
      this.rootWordTile = '' ;
      this.selectedBaseResponse = null ;
      this.baseAttempts++ ;
      this.removeResponseHighlighting('base');
    }

    if ((currentMorpheme === 'prefix' || currentMorpheme === 'both') && this.selectedPrefixResponse && !this.selectedPrefixResponse.isCorrect) {
      this.prefixTile = '' ;
      this.selectedPrefixResponse = null ;
      this.prefixAttempts++ ;
      this.disableSubmitButton = true ;
      this.removeResponseHighlighting('prefix');
    }

    if ((currentMorpheme === 'suffix' || currentMorpheme === 'both') && this.selectedSuffixResponse && !this.selectedSuffixResponse.isCorrect) {
      this.suffixTile = '' ;
      this.selectedSuffixResponse = null ;
      this.suffixAttempts++ ;
      this.disableSubmitButton = true ;
      this.removeResponseHighlighting('suffix');
    }

    this.changeDetector.markForCheck() ;
    this.startTime = this.timerService.startTimer();
  }


  playChangeToAudio(audioCompleteCallback?: Function) {
    this.audioPlayerSubscription.unsubscribe();
    this.audioPlayerSubscription = this.audioPlayerService.play(this.makeAudio).pipe(
      mergeMap(() => this.audioPlayerService.play(this.currentTrial.word['@audio'])),
    )
    .subscribe({
      complete: () => {
        if (audioCompleteCallback) audioCompleteCallback() ;
      }
    }) ;
  }

  playMeaningAudio(audioCompleteCallback?: Function) {
    this.audioPlayerSubscription.unsubscribe();
    this.audioPlayerSubscription = this.audioPlayerService.play(this.currentTrial['dictionary-list'].dictionary[1]['@audio']).subscribe({
      complete: () => {
        if (audioCompleteCallback) audioCompleteCallback();
      },
      error: () => {
        if (audioCompleteCallback) audioCompleteCallback();
      },
    });
  }

  playSpecifiedAudio(audioFile: string | undefined): void {
    if (!audioFile) return ;

    this.audioPlayerService.play(audioFile).subscribe({
      error(event) {
        console.error('Error playing audio: ', event);
      },
    });
  }

  playDictionaryAudio(section: string) {
    this.audioPlayerSubscription.unsubscribe();
    if (section === 'top') {
      // If we have the correct base response already, the dictionary audio should play the target and definition
      if (this.correctBaseResponse)
      {
        this.audioPlayerSubscription = this.audioPlayerService.play(this.currentTrial['dictionary-list'].dictionary[0]['@audio']).pipe(
          mergeMap(() => this.audioPlayerService.play(this.currentTrial['dictionary-list'].dictionary[1]['@audio'])),
        ).subscribe() ;
      }
      else
      {
        this.audioPlayerSubscription = this.audioPlayerService.play(this.currentTrial['dictionary-list'].dictionary[1]['@audio']).subscribe() ;
      }
    }
    else if (section === 'bottom') {
      if (this.suffixAttempts > 0 || this.prefixAttempts > 0)
      {
        this.audioPlayerSubscription = this.audioPlayerService.play(this.currentTrial['dictionary-list'].dictionary[2]['@audio']).pipe(
          mergeMap(() => this.audioPlayerService.play(this.currentTrial['dictionary-list'].dictionary[3]['@audio'])),
        ).subscribe() ;
      }
      else
      {
        this.audioPlayerSubscription = this.audioPlayerService.play(this.currentTrial['dictionary-list'].dictionary[3]['@audio']).subscribe() ;
      }
    }
  }

  // Set up audio for the speaker button
  playTargetAudio(audioCompleteCallback?: Function) {
    this.audioPlayerSubscription.unsubscribe();
    if (this.currentTrial.morpheme === 'base') {
      // Play our instructional audio
      this.audioPlayerSubscription = this.audioPlayerService.play(this.interventionTaskService.getTrialInstructionalAudio()).subscribe({
        complete: () => this.trialInstructionalAudioComplete(),
      });
    }
    else
    {
      // Play our instructional audio
      this.playChangeToAudio(() => this.trialLoopAudioComplete());
    }
  }

  reduceResponsesIfNecessary(responseList: Response[]): Response[] {
    // We only reduce responses if we have 2+ incorrect in a row and we are on the base morpheme, however the
    // responsesReduced flag is controlled at the end of the trial and updated if we are on the base or not
    // so here we just need to respect that flag
    if (this.responsesReduced)
    {
      return responseList.slice(0, Math.ceil(responseList.length / 2)) ;
    }
    else
    {
      return responseList ;
    }
  }

  removeMorpheme(morpheme: string) {
    if (morpheme === 'prefix') {
      this.prefixTile = '' ;
      this.disableSubmitButton = true ;
      this.removeResponseHighlighting('prefix') ;
      this.selectedPrefixResponse = null ;
    }
    else if (morpheme === 'suffix') {
      this.suffixTile = '' ;
      this.disableSubmitButton = true ;
      this.removeResponseHighlighting('suffix') ;
      this.selectedSuffixResponse = null ;
    }

    // check if our selected response altered the base word, if so undo then deselect
    // If our response option alters the base word, update that
    if (this.correctBaseResponse) {
      this.rootWordTile = this.correctBaseResponse.text ;
    }
  }

  removeResponseHighlighting(list: string = 'all') {
    const listsMap: { [ key: string]: ResponseOption[] } = {
      prefix: this.prefixResponseOptions,
      suffix: this.suffixResponseOptions,
      base: this.baseWordResponseOptions
    };

    if (list === 'all') {
      Object.values(listsMap).forEach(options =>
        options.forEach(opt => opt.highlight = false)
      );
    } else if (listsMap[list]) {
      listsMap[list].forEach(opt => opt.highlight = false);
    }

    this.changeDetector.markForCheck() ;
  }

  saveTaskData() {
    this.interventionTaskService.handleEndOfTaskProcess(this.trialList, this.taskTotalPoints, this.numberOfTrials, this.numberOfCorrectTrials, this.attempt)
      .pipe(
        mergeMap(() => {
          let params = this.interventionTaskService.getTaskDataParams();
          if (params.taskData.length) {
            return this.studentDataService.saveTrialData(params.taskData, !params.taskFinished)
          } else {
            return of({});
          }
        })
      ).subscribe({
      next: () => {
        this.saveDataDialog.hideSaveDataDialog();
        this.completeTask(this.attempt);
      },
      error:() => this.saveDataDialog.showSaveDataDialog()
    });
  }

  secondResponseIncorrectSequence(currentMorpheme: string) {
    this.disableAgainButton = true;
    this.hideAgainButton = true;
    this.showResponseAudioButtons = false;
    this.disableResponseButtons = false;
    this.disableAVButtons = false;
    this.disableSubmitButton = true ;
    this.removeResponseHighlighting() ;

    if (currentMorpheme === 'base') {
      this.correctBaseResponse = this.baseWordResponseOptions.find((opt) => opt.isCorrect) ;
      if (!this.correctBaseResponse) {
        throw new Error('Could not find the correct base word response option on second incorrect for task \'' + this.task.id + '\' (trial: ' + (this.trialIndex + 1) + ')') ;
      }

      // Display our correct answer
      this.rootWordTile = this.correctBaseResponse.text ;
      this.correctBaseResponse.highlight = true ;
    }

    if (currentMorpheme === 'prefix' || currentMorpheme === 'both') {
      let correctBaseResponseOption = this.baseWordResponseOptions.find((opt) => opt.isCorrect) ;
      let correctPrefixResponseOption = this.prefixResponseOptions.find((opt) => opt.isCorrect) ;

      // Simple sanity checks
      if (!correctPrefixResponseOption) {
        throw new Error('Could not find the correct prefix word response option on second incorrect for task \'' + this.task.id + '\' (trial: ' + (this.trialIndex + 1) + ')') ;
      }

      if (!correctBaseResponseOption) {
        throw new Error('Could not find the correct BASE word response option on second incorrect for task \'' + this.task.id + '\' (trial: ' + (this.trialIndex + 1) + ')') ;
      }

      // Display our correct answer
      this.rootWordTile = (correctPrefixResponseOption.base || correctBaseResponseOption.text) ;
      this.prefixTile = correctPrefixResponseOption.text ;
      correctPrefixResponseOption.highlight = true ;
    }

    if (currentMorpheme === 'suffix' || currentMorpheme === 'both') {
      let correctBaseResponseOption = this.baseWordResponseOptions.find((opt) => opt.isCorrect) ;
      let correctSuffixResponseOption = this.suffixResponseOptions.find((opt) => opt.isCorrect) ;

      if (!correctSuffixResponseOption) {
        throw new Error('Could not find the correct suffix word response option on second incorrect for task \'' + this.task.id + '\' (trial: ' + (this.trialIndex + 1) + ')') ;
      }

      if (!correctBaseResponseOption) {
        throw new Error('Could not find the correct BASE word response option on second incorrect for task \'' + this.task.id + '\' (trial: ' + (this.trialIndex + 1) + ')') ;
      }

      // Display our correct answer
      this.rootWordTile = (correctSuffixResponseOption.base || correctBaseResponseOption.text) ;
      this.suffixTile = correctSuffixResponseOption.text ;
      correctSuffixResponseOption.highlight = true ;
    }

    // If we are not the base morpheme, need to play the correct target word here. For the base morpheme we already played it above
    this.audioPlayerSubscription = this.audioPlayerService.play(this.currentTrial['dictionary-list'].dictionary[(currentMorpheme === 'base') ? 0 : 2]['@audio']).subscribe() ;

    this.changeDetector.markForCheck() ;
    this.reusableTimer = window.setTimeout(() => {
      let responseObject = this.trialList[this.trialList.length - 1];
      this.updateTotalPoints(responseObject.points);
      this.endOfTrialCallback();
    }, this.interventionTaskService.moveToNextTrialDelay);

  }

  selectBaseAndSubmit(selectedResponseIDx: number) {
    let responseTile = this.baseWordResponseOptions[selectedResponseIDx] ;
    responseTile.highlight = true ;
    this.rootWordTile = responseTile.text ;
    this.selectedBaseResponse = responseTile ;

    // Now submit our find the word trial (base morpheme)
    this.submitResponse('find') ;
  }

  selectPrefixSuffix(selectedResponseIdx: number, morpheme: string) {
    let responseTile ;
    this.removeResponseHighlighting(morpheme) ;
    this.changeDetector.markForCheck() ;

    if (morpheme === 'prefix') {
      responseTile = this.prefixResponseOptions[selectedResponseIdx] ;
      responseTile.highlight = true ;
      this.prefixTile = responseTile.text ;
      this.selectedPrefixResponse = responseTile ;
    }
    else if (morpheme === 'suffix') {
      responseTile = this.suffixResponseOptions[selectedResponseIdx] ;
      responseTile.highlight = true ;
      this.suffixTile = responseTile.text ;
      this.selectedSuffixResponse = responseTile ;
    }

    // If our response option alters the base word, update that
    if (responseTile && responseTile.base) {
      this.rootWordTile = responseTile.base ;
    }

    this.disableSubmitButton = (this.currentTrial.morpheme === 'prefix' && !this.selectedPrefixResponse) ||
      (this.currentTrial.morpheme === 'suffix' && !this.selectedSuffixResponse) ||
      (this.currentTrial.morpheme === 'both' && (!this.selectedPrefixResponse || !this.selectedSuffixResponse)) ;

    this.changeDetector.markForCheck() ;
  }

  submitResponse(taskType: string) {
    this.numberOfAttemptsForTrial++;
    this.disableAVButtons = true;
    this.disableResponseButtons = true;
    this.disableSubmitButton = true;
    this.changeDetector.markForCheck() ;

    // Stop timer after the student selects a response
    this.endTime = this.timerService.stopTimer();
    if (this.numberOfAttemptsForTrial == 1) {
      this.firstResponseTime = this.timerService.computeTime(this.startTime, this.endTime) || 0;
      this.secondResponseTime = 0;
    }
    else {
      this.secondResponseTime = this.timerService.computeTime(this.startTime, this.endTime) || 0;
    }

    let isCorrect = false ;
    let responseText = '' ;
    let responseIdx = -1 ;
    if (taskType === 'find') {
      if (!this.selectedBaseResponse) {
        throw new Error('When trying to submit response for \'find\' CTM, could not find the base word response tile we wanted') ;
      }

      isCorrect = this.selectedBaseResponse.isCorrect ;
      responseText = this.selectedBaseResponse.text ;
      responseIdx = this.selectedBaseResponse.index ;

      // For the 'find' task (base), if we are correct retain our correct response option
      if (isCorrect)
      {
        this.correctBaseResponse = this.baseWordResponseOptions[responseIdx] ;
      }
    }
    else if (taskType === 'change') {
      // Sanity checks
      if ((this.currentTrial.morpheme === 'prefix' || this.currentTrial.morpheme === 'both') && !this.selectedPrefixResponse) {
        return ;
      }

      if ((this.currentTrial.morpheme === 'suffix' || this.currentTrial.morpheme === 'both') && !this.selectedSuffixResponse) {
        return ;
      }

      if (this.selectedPrefixResponse && this.selectedSuffixResponse) {
        isCorrect = this.selectedPrefixResponse.isCorrect && this.selectedSuffixResponse.isCorrect ;
        responseText = this.selectedPrefixResponse.text + this.selectedBaseResponse?.text + this.selectedSuffixResponse.text ;
      }
      else if (this.selectedPrefixResponse) {
        isCorrect = this.selectedPrefixResponse.isCorrect ;
        responseText = this.selectedPrefixResponse.text + this.selectedBaseResponse?.text ;
        responseIdx = this.selectedPrefixResponse.index ;
      }
      else if (this.selectedSuffixResponse) {
        isCorrect = this.selectedSuffixResponse.isCorrect ;
        responseText = this.selectedBaseResponse?.text + this.selectedSuffixResponse.text ;
        responseIdx = this.selectedSuffixResponse.index ;
      }
      else
      {
        throw new Error('When trying to submit response \'change\' CTM, could not find a suffix or prefix response tile') ;
      }

      // If we are in the 'change' portion, when the response is correct we want to show it in the bottom dictionary
      this.prefixSuffixResponseCorrect = isCorrect ;
    }
    else {
      throw new Error('When trying to submit response for CTM, unknown task type set (\'' + taskType + '\'') ;
    }

    // Record the student's response
    this.interventionTaskService.playSoundEffect(isCorrect);
    let runningPointsAnimation = this.trialTimerBar.sendResponseToTimerBar(isCorrect);
    let trialPoints = this.trialTimerBar.getPoints();
    this.interventionTaskService.recordResponseInTrialDataTrackerObject(this.dataTracker, responseText);
    this.changeDetector.markForCheck() ;

    if (isCorrect) {
      // If the response is correct or the student is taking the pre/post test version of this task
      let isTrialCorrect = isCorrect && (this.numberOfAttemptsForTrial == 1);
      this.interventionTaskService.trackResponseTrends(isTrialCorrect);
      let responseObject = this.interventionTaskService.createTrialResponseObject(
        isTrialCorrect,
        this.trialIndex,
        this.firstResponseTime,
        this.secondResponseTime,
        trialPoints,
        this.dataTracker,
        (responseIdx !== -1) ? responseIdx : undefined
      );

      if (isTrialCorrect) {
        this.sequentialIncorrect = 0 ;
        this.numberOfCorrectTrials++;
      }
      this.trialList.push(responseObject);

      this.taskService.answerTrial(isTrialCorrect) ;
      this.interventionTaskService.moveToNextTrial(responseObject, runningPointsAnimation).subscribe({
        complete: () => {
          this.updateTotalPoints(responseObject.points);
          this.endOfTrialCallback();
        }
      });
    }
    else if (this.numberOfAttemptsForTrial == 1) {
      this.sequentialIncorrect++ ;

      // First incorrect response for instructional unit
      this.reusableTimer = window.setTimeout(() => {
        // Play "Please try again..." followed by showing support audio buttons and enabling response/AV buttons
        let audio$ = this.audioPlayerService.play('Audio/Help/help_tryagain.mp3') ;
        if (this.currentTrial.morpheme !== 'base')
        {
          // For non-base, we need to also play the target audio
          audio$ = this.audioPlayerService.play('Audio/Help/help_tryagain.mp3').pipe(
            mergeMap(() => this.audioPlayerService.play(this.currentTrial['dictionary-list'].dictionary[2]['@audio'])),
          );
        }
        this.audioPlayerSubscription = audio$.subscribe({
          complete: () => this.firstResponseIncorrectSequence(this.currentTrial.morpheme),
          error: () => this.firstResponseIncorrectSequence(this.currentTrial.morpheme)
        });
      }, this.interventionTaskService.firstIncorrectDelay);
    }
    else {
      this.interventionTaskService.trackResponseTrends(isCorrect);
      let responseObject = this.interventionTaskService.createTrialResponseObject(
        isCorrect,
        this.trialIndex,
        this.firstResponseTime,
        this.secondResponseTime,
        trialPoints,
        this.dataTracker,
        (responseIdx !== -1) ? responseIdx : undefined
      );
      this.trialList.push(responseObject);
      this.taskService.answerTrial(isCorrect) ;
      this.reusableTimer = window.setTimeout(() => {
        this.audioPlayerSubscription = this.audioPlayerService.play('Audio/Help/help_correctansweris.mp3').subscribe({
          complete: () => this.secondResponseIncorrectSequence(this.currentTrial.morpheme),
          error: () => this.secondResponseIncorrectSequence(this.currentTrial.morpheme)
        });
      }, this.interventionTaskService.secondIncorrectDelay);
    }
  }

  trialInstructionalAudioComplete() {
    if (this.currentTrial.morpheme === 'base') {
      this.playMeaningAudio(() => this.trialLoopAudioComplete())
    }
  }

  trialLoopAudioComplete() {
    // Allow user to submit a response to the trial
    this.disableResponseButtons = false ;
    this.disableAVButtons = false;
    this.startTime = this.timerService.startTimer();
    this.changeDetector.markForCheck() ;

    //this.originalStartTime = this.timerService.startTimer();
    //this.startTime = this.originalStartTime;
    let timerBarTaskSettings = this.interventionTaskService.getTimerBarTaskSettings();
    if (timerBarTaskSettings.timerBarEnabled) {
      let initialDelay = this.interventionTaskService.trialBarBaseDelay + timerBarTaskSettings.timerBarDelay;
      this.trialTimerBar.startTrialTimer(timerBarTaskSettings.timerBarSpeed, initialDelay);
    }
  }

  private addResponseOptions(responseList: any[], targetOptions: any[], correctAnswer: string) {
    responseList.forEach((response: any, idx) => {
      let isCorrect = (response['#text'] === correctAnswer) ;
      if (isCorrect) {
        this.dataTracker.targetAnswer = response['#text'] ;
      }

      let respOpt: any = {
        index: idx,
        text: response['#text'],
        audio: response['@audio'],
        highlight: false,
        isCorrect: isCorrect,
      }

      if (response['@definition']) {
        respOpt.definition = response['@definition'] ;
      }

      if (response['@base']) {
        respOpt.base = response['@base'] ;
      }

      targetOptions.push(respOpt) ;
    }) ;
  }
}
