import {AfterViewInit, Component, OnDestroy, OnInit, ChangeDetectionStrategy, ChangeDetectorRef} from '@angular/core';
import { Router } from '@angular/router';
import { of, Subscription } from 'rxjs';
import { concatMap, first, map, mergeMap } from 'rxjs/operators';
import { InterventionTrial } from 'src/app/core/models/task.model';
import { AudioPlayerService } from 'src/app/core/services/audio-player.service';
import { InterventionTaskService } from 'src/app/core/services/intervention-task.service';
import { ShuffleService } from 'src/app/core/services/shuffle.service';
import { StudentDataService } from 'src/app/core/services/student-data.service';
import { TimerService } from 'src/app/core/services/timer.service';
import { InterventionTaskComponent } from '../intervention-task.component';
import { ResponseTile } from './answer-the-question-intervention.model';
import { TaskService } from '../../core/services/task.service';

@Component({
  selector: 'answer-the-question-intervention',
  templateUrl: './answer-the-question-intervention.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AnswerTheQuestionInterventionComponent extends InterventionTaskComponent implements OnInit, AfterViewInit, OnDestroy {
  showPassageDialog: boolean = false;
  passageTitle: string = '';
  passageTitleAudio: string = '';
  passageAudio: string = '';
  phrase: any[] = [];
  targetQuestion: string = '';
  targetQuestionAudioVisible: boolean = false;
  hideSubmitButton: boolean = false;
  disableSubmitButton: boolean = true;

  trials: InterventionTrial[] = this.task.trial;
  numberOfCorrectTrials: number = 0;
  numberOfAttemptsForTrial: number = 0;
  allResponsesInTrialHaveAudioDefined: boolean = false;
  responseOptions: ResponseTile[] = [];
  responseOptions1: ResponseTile[] = [];
  responseOptions2: ResponseTile[] = [];
  originalStartTime: number = 0;
  firstResponseTime: number = 0;
  secondResponseTime: number = 0;

  private backButtonSubscription?: Subscription;

  constructor(
    public studentDataService: StudentDataService,
    public interventionTaskService: InterventionTaskService,
    public timerService: TimerService,
    public audioPlayerService: AudioPlayerService,
    public router: Router,
    public shuffleService: ShuffleService,
    public changeDetector: ChangeDetectorRef,
    private taskService: TaskService,

  ) {
    super(studentDataService, interventionTaskService, timerService, audioPlayerService, router, changeDetector);
  }

  ngOnInit(): void {
    // Check to shuffle trials
    if (this.task.randomTrials) {
      this.trials = this.shuffleService.shuffleArray(this.trials);
    }
    // Get starting points for the total points cloud
    this.taskTotalPoints = this.interventionTaskService.getStartingPoints(this.task.id, this.currentDestination, this.wordListAttempt);
    let passage = this.stateParams.passage;
    this.passageTitle = passage.title['#text'];
    this.passageTitleAudio = passage.title['@audio'];
    this.passageAudio = passage['@audio'];
    this.phrase = passage.phrase ?? [];
  }

  ngOnDestroy() {
    super.ngOnDestroy();
    this.backButtonSubscription?.unsubscribe();
  }

  ngAfterViewInit(): void {
    // After view is initialized wait for task animation to complete and then initialize everything else
    this.taskBar.taskAnimationComplete.pipe(first())
    .subscribe(() => {
      // set this to tell the trial-counter that animation is complete
      this.animationComplete = true;
      this.interventionTaskService.initTaskContainerElements(this.task, this.alreadyCompleted, this.wordListAttempt, this.attempt, this.stateParams?.parentTaskId)
        .pipe(first(),
          map(() => {
            let timerBarSettings = this.interventionTaskService.getTimerBarTaskSettings();
            timerBarSettings.timerBarEnabled ? this.trialTimerBar.showTimerBar() : this.trialTimerBar.hideTimerBar();
          }),
          concatMap(() => {
            if (!this.studentDataService.hasCompletedAtLeastOneTaskLikeThis(this.task.id) && this.interventionTaskService.getPlayVideoFlag()) {
              this.playInstructionalAudio = false;
              return this.instructions.playInstructionalVideo();
            }
            else {
              return of({});
            }
          }),
          // NOTE: we always play the instructional audio on the first trial, so only check for video here
        )
        .subscribe({
          complete: () => this.defaultAudioCompleteFunc(),
        });
    });

    // Display the focus dialog if needs focus is set (from intevention task)
    if(this.needsFocus){
      this.focusDialog.showDialog();
    }
  }

  enableButtons() {
    this.reusableTimer = window.setTimeout(() => {
      this.disableResponseButtons = false;
      this.disableAVButtons =  false;
      this.changeDetector.markForCheck() ;
    }, 0);
  }

  // go to passage page
  togglePassage() {
    this.showPassageDialog = !this.showPassageDialog;

    // Stop all audios when we exit passage, but doesn't hurt on the way in either
    this.audioPlayerService.stopAll();
  }

  playLine(audio: string) {
    this.dataTracker.requestSupport++;
    this.audioPlayerService.play(audio).subscribe();
  }

  playTitle() {
    this.dataTracker.requestSupport++;
    this.audioPlayerService.play(this.passageTitleAudio).subscribe();
  }

  playPassage() {
    this.dataTracker.requestSupport++;
    this.audioPlayerService.play(this.passageAudio).subscribe();
  }

  // Set up audio for the speaker button
  playWordAudio(audioFile: string) {
    this.dataTracker.requestSupport++;
    this.disableResponseButtons = true;
    this.disableAVButtons =  true;
    this.changeDetector.markForCheck() ;

    this.audioPlayerService.play(audioFile).subscribe({
      complete: () => this.enableButtons(),
      error: () => this.enableButtons()
    });
  }

  // Update the total points on $scope (generally used as a callback to the interventionTaskFactory)
  updateTotalPoints(points: number) {
    this.taskTotalPoints += points;
  }

  addHoverClass = function(response: ResponseTile) {
    response.highlight = true;
  }

  removeHoverClass = function(response: ResponseTile) {
    response.highlight= false;
  }

  removeResponseHighlighting() {
    for (let response in this.responseOptions) {
      this.responseOptions[response].highlight = false;
    }
  }

  highlightCorrectResponse() {
    for (let response in this.responseOptions) {
      this.responseOptions[response].highlight = this.responseOptions[response].isCorrect;
    }
  }


  // Run this function after playing the instructional audio
  defaultAudioCompleteFunc() {
    this.reusableTimer = window.setTimeout(() => {
      this.hideSubmitButton = false;
      this.displayTask(this.trialIndex);
    }, 0);
  }

  playCorrectWordAudio() {
    let audio = this.trials[this.trialIndex]['resp-list']?.resp[0]['@audio'];
    if (audio) this.audioPlayerService.play(audio).subscribe();
  }

  // Set up audio for the speaker button
  playTargetAudio(audioCompleteCallback: Function) {
    this.audioPlayerService.play(this.trials[this.trialIndex].question!['@audio']).subscribe({
      complete: () => audioCompleteCallback.call(this),
      error: () => audioCompleteCallback.call(this),
    });
  }

  playTargetAudioViaSpeakerClick() {
    this.dataTracker.requestSupport++;
    this.disableResponseButtons = true;
    this.disableAVButtons = true;
    this.changeDetector.markForCheck() ;

    this.playTargetAudio(() => {
      this.reusableTimer = window.setTimeout(() => {
        this.disableResponseButtons = false;
        this.disableAVButtons = false;
        this.changeDetector.markForCheck() ;
      }, 0);
    });
  }

  firstResponseIncorrectSequence() {
    this.playTargetAudio(() => {
      this.reusableTimer = window.setTimeout(() => {
        this.removeResponseHighlighting();
        this.showResponseAudioButtons = this.allResponsesInTrialHaveAudioDefined;
        this.targetQuestionAudioVisible = true;
        this.startTime = this.timerService.startTimer();
        this.disableResponseButtons = false;
        this.disableAVButtons = false;
        this.changeDetector.markForCheck() ;
      }, 0);
    });
  }

  secondResponseIncorrectSequence() {
    this.reusableTimer = window.setTimeout(() => {
      this.highlightCorrectResponse();
      this.playCorrectWordAudio();
      this.changeDetector.markForCheck() ;

      this.reusableTimer = window.setTimeout(() => {
        let responseObject = this.trialList[this.trialList.length - 1];
        this.updateTotalPoints(responseObject.points)
        this.endOfTrialCallback();
      }, this.interventionTaskService.moveToNextTrialDelay);
    }, 0);
  }

  trialLoopAudioComplete() {
    this.reusableTimer = window.setTimeout(() => {
      // Allow user to submit a response to the trial
      this.disableResponseButtons = false;
      this.disableAVButtons = false;

      this.originalStartTime = this.timerService.startTimer();
      this.startTime = this.originalStartTime;
      this.changeDetector.markForCheck() ;
    }, 0);
  }

  trialInstructionalAudioComplete() {
    // Trial instructional audio is done

    // If target question audio button is visible, play target question audio
    // Else, we begin the trial immediately
    if (this.targetQuestionAudioVisible) {
      this.playTargetAudio(this.trialLoopAudioComplete);
    } else {
      this.trialLoopAudioComplete();
    }
  }

  displayTask(newIndex: number) {
    this.targetQuestionAudioVisible = this.studentDataService.isStudentSecondGradeOrLess() || this.interventionTaskService.hasInitialAudioSupport();
    this.showResponseAudioButtons = false;
    this.disableSubmitButton = true;
    this.numberOfAttemptsForTrial = 0;
    this.changeDetector.markForCheck() ;

    this.trialIndex = newIndex;
    if (this.trialIndex === 0){
      this.audioPlayerService.play(this.interventionTaskService.getTrialInstructionalAudio()).subscribe({
        complete: () => this.trialInstructionalAudioComplete(),
      });
    } else {
      this.trialInstructionalAudioComplete();
    }

    this.responseOptions = [];

    this.dataTracker = this.interventionTaskService.createTrialDataTrackerObject();

    this.targetQuestion = this.trials[this.trialIndex].question!['#text'];
    this.createResponseList(this.trials[this.trialIndex]);
  }

  // Function that executes when the user clicks on a response tile
  submitResponse(selectedResponse: number) {
    this.disableResponseButtons = true;
    this.disableAVButtons = true;
    this.numberOfAttemptsForTrial++;

    // Stop timer after the student selects a response
    this.endTime = this.timerService.stopTimer();
    if (this.numberOfAttemptsForTrial === 1) {
      this.firstResponseTime = this.timerService.computeTime(this.startTime, this.endTime) || 0;
      this.secondResponseTime = 0;
    }
    else {
      this.secondResponseTime = this.timerService.computeTime(this.startTime, this.endTime) || 0;
    }

    // Record the student's response
    let responseTile = this.responseOptions[selectedResponse];
    let isCorrect = responseTile.isCorrect;
    responseTile.highlight = true;
    this.interventionTaskService.playSoundEffect(isCorrect);
    let runningPointsAnimation = this.trialTimerBar.sendResponseToTimerBar(isCorrect);
    let trialPoints = this.trialTimerBar.getPoints();
    this.interventionTaskService.recordResponseInTrialDataTrackerObject(this.dataTracker, responseTile.text);
    this.changeDetector.markForCheck() ;

    if (isCorrect) {
      // If the response is correct
      let isTrialCorrect = isCorrect && (this.numberOfAttemptsForTrial === 1);
      this.interventionTaskService.trackResponseTrends(isTrialCorrect);
      let responseObject = this.interventionTaskService.createTrialResponseObject(isTrialCorrect, this.trialIndex,
          this.firstResponseTime, this.secondResponseTime, trialPoints, this.dataTracker, selectedResponse);
      if (isTrialCorrect) {
        this.numberOfCorrectTrials++;
      }
      this.trialList.push(responseObject);

      this.taskService.answerTrial(isTrialCorrect) ;
      this.interventionTaskService.moveToNextTrial(responseObject, runningPointsAnimation).subscribe({
        complete: () => {
          this.updateTotalPoints(responseObject.points);
          this.endOfTrialCallback();
        }
      });
    }
    else if (this.numberOfAttemptsForTrial === 1) {
      // First incorrect response for instructional unit
      this.reusableTimer = window.setTimeout(() => {
        // Play "Please try again..." followed by showing support audio buttons and enabling response/AV buttons
        this.audioPlayerService.play('Audio/Help/help_tryagain.mp3').subscribe({
          complete: () => this.firstResponseIncorrectSequence(),
          error: () => this.firstResponseIncorrectSequence()
        });
      }, this.interventionTaskService.firstIncorrectDelay);
    }
    else {
      // Second incorrect response for instructional unit
      this.disableAVButtons = true;
      this.interventionTaskService.trackResponseTrends(isCorrect);
      let responseObject = this.interventionTaskService.createTrialResponseObject(isCorrect, this.trialIndex, this.firstResponseTime, this.secondResponseTime, trialPoints, this.dataTracker, selectedResponse);
      this.trialList.push(responseObject);
      this.taskService.answerTrial(isCorrect) ;
      this.changeDetector.markForCheck() ;

      this.reusableTimer = window.setTimeout(() => {
        this.audioPlayerService.play('Audio/Help/help_correctansweris.mp3').subscribe({
          complete: () => this.secondResponseIncorrectSequence(),
          error: () => this.secondResponseIncorrectSequence()
        });
      }, this.interventionTaskService.secondIncorrectDelay);
    }
  }

  // Show the user the response they selected for one second before going to the next trial
  // Run through the task with the next trial in the curriculum
  endOfTrialCallback() {
    this.reusableTimer = window.setTimeout(() => {
      this.trialTimerBar.resetTrialTimer();

      var newIndex = this.trialIndex + 1;
      if (newIndex < this.trials.length) {
        this.hideResponses = true;
        this.displayTask(newIndex);
      }
      else {
        this.saveTaskData();
      }
    }, this.interventionTaskService.getDelayAfterSingleResponse(this.trialList));
  }

  saveTaskData() {
    this.interventionTaskService.handleEndOfTaskProcess(this.trialList, this.taskTotalPoints,
        this.numberOfTrials, this.numberOfCorrectTrials, this.attempt)
    .pipe(
      mergeMap(() => {
        let params = this.interventionTaskService.getTaskDataParams();
        if (params.taskData.length) {
          return this.studentDataService.saveTrialData(params.taskData, !params.taskFinished)
        } else {
          return of({});
        }
      })
    ).subscribe({
      next: () => {
        this.saveDataDialog.hideSaveDataDialog();
        this.completeTask(this.attempt);
      },
      error:() => this.saveDataDialog.showSaveDataDialog()
    });
  }

  createResponseList(trial: InterventionTrial){
    this.responseOptions = [];
    let index: number = 0;

    // Create a shallow copy of the response list
    let responseList = trial['resp-list']!.resp.slice();

    // Reduce the number of responses, if needed
    responseList = this.interventionTaskService.reduceResponsesIfNecessary(responseList);

    // Shuffle responses
    responseList = this.interventionTaskService.shuffleResponses(responseList, trial['resp-list']!['@randomResponses']);

    let notHighlighted = false;
    this.allResponsesInTrialHaveAudioDefined = true;

    // Build the response lists
    for (let i = 0; i < responseList.length; i++) {
      let responseText = responseList[i]['#text'];
      let responseAudio = responseList[i]['@audio'];
      let isCorrectResponse = (responseList[i]['@type'] === 'Correct');

      if (isCorrectResponse) {
        this.dataTracker.targetAnswer = responseText;
      }

      if (responseAudio === null || !responseAudio) {
        this.allResponsesInTrialHaveAudioDefined = false;
      }

      responseAudio = responseAudio || "";
      this.responseOptions.push(new ResponseTile(index, responseText, responseAudio, notHighlighted, isCorrectResponse));
      ++index;
    }

    if (this.responseOptions.length === 4){
        this.responseOptions1 = this.responseOptions.slice(0,2);
        this.responseOptions2 = this.responseOptions.slice(2,4);
    } else {
        // Should always be 4, but just in case
        this.responseOptions1 = this.responseOptions.slice(0,4);
        this.responseOptions2 = this.responseOptions.slice(4,8);
    }

    this.showResponseAudioButtons = this.interventionTaskService.hasInitialAudioSupport() && this.allResponsesInTrialHaveAudioDefined;
    this.changeDetector.markForCheck() ;
  }
}
