import { ChangeDetectorRef, Component, OnDestroy, ViewChild } from '@angular/core';
import { Router } from '@angular/router';

import { DiagnosticTask, DiagnosticTrialResponse } from 'src/app/core/models/task.model';
import { TrialCounterComponent } from './trial-counter/trial-counter.component'
import { SaveDataDialogComponent } from './save-data-dialog/save-data-dialog.component'
import { InstructionsComponent } from 'src/app/shared/components/instructions/instructions.component';
import { ApplicationStateService } from '../core/services/application-state.service';
import { StudentDataService } from '../core/services/student-data.service';
import { TaskBarComponent } from './task-bar/task-bar.component';
import { AudioPlayerService } from '../core/services/audio-player.service';
import { TimerService } from '../core/services/timer.service';
import { ShuffleService } from '../core/services/shuffle.service';
import { forkJoin, Observable } from 'rxjs';
import { AssetPreloaderService } from '../core/services/asset-preloader.service';
import { environment } from 'src/environments/environment';

@Component({
  template: '',
})
export abstract class DiagnosticTaskComponent implements OnDestroy {
  // Add ViewChild components
  @ViewChild(TrialCounterComponent)
  trialCounter!: TrialCounterComponent;

  @ViewChild(SaveDataDialogComponent)
  saveDataDialog!: SaveDataDialogComponent;

  @ViewChild(InstructionsComponent)
  instructions!: InstructionsComponent;

  @ViewChild(TaskBarComponent)
  taskBar!: TaskBarComponent;

  animationComplete: boolean = false;
  taskContainerColor: string;
  taskBackgroundImage: string;
  highlightColor: string;
  task: DiagnosticTask;

  startTime!: number;
  endTime!: number;
  currentSelectedResponse: string = '';

  // Disable speaker and next buttons while instructional audio is playing
  disableAVButtons: boolean = true;
  disableNextButton: boolean = true;
  hideNextButton: boolean = false;
  // Scope variable to do the toggling of the next button highlighting
  toggleNextButton: boolean = false;
  // Disable response buttons until trial is started
  disableResponseButtons: boolean = true;

  // Flag to determine if we should play instructional audio or not, we don't want it to play after instructional video
  playInstructionalAudio: boolean = true;

  // Keep track of which trial we are on
  trialIndex: number = 0;
  totalTaskPoints: number = 0;

  numberOfCorrectTrials: number = 0;

  // Keep track of all the trials the user completes so we can send them to the backend
  trialList: DiagnosticTrialResponse[] = [];

  reusableTimer: number | null = null;
  intervalTimer: number | null = null;
  displayTimer: number | null = null;
  responseTimeout: number | null = null;
  startTaskTimer: number | null = null;

  startSilenceCallback: boolean = true;

  instructionalVideoFile: string;
  instructionalAudioFile: string;
  instructionalMaskedAudioFile: string = '';

  trialMasked: boolean = false;

  readyToHighlight: boolean = false;

  constructor(
    public router: Router,
    public applicationStateService: ApplicationStateService,
    public studentDataService: StudentDataService,
    public audioPlayerService: AudioPlayerService,
    public timerService: TimerService,
    public shuffleService: ShuffleService,
    public assetPreloaderService: AssetPreloaderService,
    public changeDetector?: ChangeDetectorRef,
  ) {
    // Task theme setup
    this.taskContainerColor = this.studentDataService.getTaskContainerColor();
    // Get task bar color to use when highlighting buttons
    this.highlightColor = this.studentDataService.getTaskBarColor();
    // Set the task's background
    this.taskBackgroundImage = this.studentDataService.getTaskBackground();
    // get the selected task and start the time
    this.task = <DiagnosticTask>this.studentDataService.startSelectedTask();
    this.instructionalVideoFile = environment.VideoAssetServerURL + '/assets/video/' + (this.task.video) + '.mp4';
    this.instructionalAudioFile = 'Audio/Help/' + this.task.taskaudio + '.mp3';
    // There is more instructional audio if the task is masked
    if (this.task.taskaudiomasked) {
      this.instructionalMaskedAudioFile = 'Audio/Help/' + this.task.taskaudiomasked + '.mp3';
      this.trialMasked = true;
    }
  }

  ngOnDestroy(): void {
    if (this.intervalTimer !== null) {
      window.clearTimeout(this.intervalTimer);
    }
    if (this.startTaskTimer !== null) {
      window.clearTimeout(this.startTaskTimer);
    }
    if (this.reusableTimer !== null) {
      window.clearTimeout(this.reusableTimer);
    }
    if (this.displayTimer !== null) {
      window.clearTimeout(this.displayTimer);
    }
    if (this.responseTimeout !== null) {
      window.clearTimeout(this.responseTimeout);
    }
  }

  repeatWord(): void {
    this.audioPlayerService.play(this.task.trial[this.trialIndex].word['@audio']).subscribe({
      error(event) {
        console.error('Error playing audio: ', event);
      }
    });
  }

  replayVideo(): void {
    this.instructions.replayVideo();
  }

  disableVideo() {
    return (this.disableNextButton && this.disableResponseButtons);
  }

  checkForClick(): void {
    if (!this.disableNextButton) {
      this.toggleNextButton = !this.toggleNextButton;
      this.changeDetector?.markForCheck() ;
    }
  }

  createInterval(): void {
    this.intervalTimer = 0;

    // Run this function every second to see if the nextTrial button has been clicked
    this.intervalTimer = window.setInterval(() => {
      if (this.readyToHighlight) {
          this.checkForClick();
      }
    }, 1000, 0);
  }

  stopInterval(): void {
    if(this.intervalTimer !== null) {
      clearTimeout(this.intervalTimer);
    }
  }

  // TODO:
  //$scope.handleTouchEnd = function(response, $event)
  // Also handling adding/removing hover classes
  // I don't know if this will actually be necessary because
  // the click events should probably handle all this?
  // In the old implementation, the handleTouchEnd deals with class
  // changes and answer submission when responses are touched on a touch screen

  submitResponse(selectedResponse: string, foil: string | null, target: string, responsePosition: number, responseType: string): any {
    // Build a response object and save it to the trialList
    let trials = this.task.trial;
    let responseObject: DiagnosticTrialResponse = {
      trialNumber: this.trialIndex + 1 || 0, // trialNumber is zero-based, so add 1
      trialLevel: trials[this.trialIndex].level || 0,
      target: target || "",
      targetGPCType: trials[this.trialIndex].gpcTarg || "",
      targetWordType: trials[this.trialIndex].itemType || "",
      targetAnswer: trials[this.trialIndex].correct.resp || "",
      foil: foil,
      response: selectedResponse || "",
      numLawfulAlts: trials[this.trialIndex].numlawfulalts || null,
      responsePosition: responsePosition,
      responseTime: this.timerService.computeTime(this.startTime, this.endTime) || 0,
      responseType: responseType,
      correct: (trials[this.trialIndex].correct.resp === selectedResponse ) || null,
      maskTime: parseInt(trials[this.trialIndex].maskerTime) || 0,
      gpcV: trials[this.trialIndex].gpcV || "",
      gpcC1: trials[this.trialIndex].gpcC1 || "",
      gpcC2: trials[this.trialIndex].gpcC2 || "",
      itemID: trials[this.trialIndex].itemID || 0,
      masked: trials[this.trialIndex].masked || "",
      targetPosition: trials[this.trialIndex].targPos || "",
      testSyl: trials[this.trialIndex].testSyl || "",
      numberOfSyls: trials[this.trialIndex].nsyls || 0,
      consonantType: trials[this.trialIndex].consType || "",
      vowelType: trials[this.trialIndex].vowelType || "",
      matchTrial: trials[this.trialIndex].matchTrial || "",
      homophonicRhyme: trials[this.trialIndex].hphRhyme || "",
    };

    this.totalTaskPoints += (responseObject.correct) ? 500 : 0;
    if (responseObject.correct){ this.numberOfCorrectTrials++;}

    this.trialList.push(responseObject);
    return responseObject;
  }

  playWordAudio(): void {
    this.audioPlayerService.play(this.task.trial[this.trialIndex].word['@audio']).subscribe({
      error(event) {
        console.error('Error playing audio: ', event);
      }
    });
  }

  saveTrialData = () => {
    let taskObject: any = {};
    taskObject.taskIdentifier = this.task.id ;
    taskObject.trials = this.trialList;
    taskObject.durationMillis = this.studentDataService.getSelectedTaskDurationMillis();
    taskObject.points = this.totalTaskPoints;
    taskObject.numberOfTrials = this.task.trial.length;
    taskObject.numberOfCorrectTrials = this.numberOfCorrectTrials;

    // Send the data to the backend if not demo user
    if (!this.studentDataService.isDemoUser()) {
      this.studentDataService.saveTrialData(taskObject).subscribe({
        complete: () => {
        // Save successful
        this.saveDataDialog.hideSaveDataDialog();

        // Redirect back to the task selection screen and remove the task that was just completed
        this.router.navigateByUrl('/taskSelect', {state: {canGoToTaskSelect: true, lastTaskCompletedID: this.task.id}});
        },
        error: (err) => {
          // Save failed - display retry dialog
          this.saveDataDialog.showSaveDataDialog();
        }
      });
    } else {
      // If demo user, clean up unsaved data
      this.studentDataService.clearUnsavedData();
      this.saveDataDialog.hideSaveDataDialog();
      this.router.navigateByUrl('/taskSelect', {state: {canGoToTaskSelect: true, lastTaskCompletedID: this.task.id}});
    }
  }

  saveStudentData(): void {
    let currentDestination = this.studentDataService.getCurrentDestination();

    // Keep track of total points locally
    this.studentDataService.setTotalPoints(currentDestination, this.totalTaskPoints, this.task.trial.length, this.numberOfCorrectTrials);
    this.saveTrialData();
  }

  displayTaskContainerElements(): Observable<any[]> {
    return forkJoin([
      this.assetPreloaderService.preloadTaskAssets(this.task),
      new Observable((observer) => {
        // Wait for fade in of task container elements, and then start task
        this.startTaskTimer = window.setTimeout(() => {
          if (!this.studentDataService.isDemoUser() && !this.studentDataService.hasCompletedAtLeastOneTaskLikeThis(this.task.id)) {
            this.playInstructionalAudio = false;
            observer.next();
            observer.complete() ;
          } else {
            observer.next();
            observer.complete() ;
          }
        }, 1000);
      })
    ]);
  }
}
