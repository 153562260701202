import { Injectable } from '@angular/core';
import { Event, NavigationStart, Router } from '@angular/router';
import { forkJoin, Observable } from 'rxjs';
import { catchError, filter } from 'rxjs/operators';
import { DataTracker, InterventionTaskResponse, InterventionTaskStatus, InterventionTrialResponse } from '../models/student-data.model';
import { InterventionTask, Tile, Response } from '../models/task.model';
import { AssetPreloaderService } from './asset-preloader.service';
import { AudioPlayerService } from './audio-player.service';
import { CurriculumService } from './curriculum.service';
import { ShuffleService } from './shuffle.service';
import { StudentDataService } from './student-data.service';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class InterventionTaskService {

  constructor(
    private audioPlayerService: AudioPlayerService,
    private studentDataService: StudentDataService,
    private curriculumService: CurriculumService,
    private assetPreloaderService: AssetPreloaderService,
    private shuffleService: ShuffleService,
    private router: Router,
    private httpClient: HttpClient,
  ) { }

  task: InterventionTask = <InterventionTask>this.studentDataService.getSelectedTask();
  // Keep track of the nextTask if any
  nextTask: InterventionTask | null = null;
  parentTaskId: string = '' ;
  //Flag to determine if we should play instructional audio or not, we don't want it to play after instructional video
  playInstructionalAudio: boolean = true;
  // Flag to determine if we should play the trial instructional audio or not
  playTrialInstructionalAudio: boolean = true;

  // Previous data for this task (points + score will be reverted to this if the student clicks the back button)
  previousTaskScoreData: any;

  playVideoFlag: boolean = false;
  instructionalAudioFile!: string;
  trialInstructionalAudio: string | null = null;
  currentDestination!: number;
  alreadyCompleted: boolean | null = null;
  wordListAttempt: number = 1;
  // Specific info on the intervention task like points, numberoftrials, numberofcorrecttrials, etc
  interventionTask: InterventionTaskStatus | null = null;

  // Keep task data from previous wordlist attempt
  previousTaskData: InterventionTaskResponse | null = null;
  firstWordListTask!: InterventionTask;

  // Keep track of consecutive incorrect/correct responses
  onFirstTrial: boolean = true;
  hasTwoIncorrectInSequence: boolean = false ;
  subTask: boolean = false;
  studentWordListLevel: string | null = null;
  isReadAndListenTask: boolean = false;
  reducedResponses: boolean = false;
  consecutiveIncorrect: number = 0;
  consecutiveCorrect: number = 0;
  numIncorrectBeforeResponseReset: number = 0;
  onFirstTrialAudio: boolean = true ;
  showAudioSupport: boolean = false ;
  numIncorrectBeforeResponseResetAudio: number = 0 ;

  // Flag to show try again dialog
  showTryAgainDialogFlag: boolean = false;
  showSaveDataDialogFlag: boolean = false;

  // Global params used for saving task data
  taskDataParams!: {
    taskData: InterventionTaskResponse[],
    taskFinished: boolean
  };

  // TODO: I can probably somehow define these as constants
  floatUpAnimationDelayWithWait: number = 2000;
  trialBarBaseDelay: number = 750;
  public letterAudioDelay: number = 750;
  // Delay only as long as the actual animation duration
  floatUpAnimationDelay: number = 1000;
  // Delay long enough so the points can float to the cloud before updating them (time includes 1 second delay on animation plus animation duration)
  correctAnswerAudioDelay: number = 1000;
  incorrectAnswerAudioDelay: number = 3000;
  tryAgainAudioDelay: number = 1000;
  secondIncorrectDelay: number = 1000;
  firstIncorrectDelay: number = 1000;
  // Used for the momentary pause to show the user the correct answer at the end of a trial
  moveToNextTrialDelay: number = 3500;
  maskerSupportExtend: number = 60 ;

  private createTaskResponseObject(wordListType: number | null, trialList: InterventionTrialResponse[], totalPoints: number): InterventionTaskResponse {
    let interventionData = this.studentDataService.getStudentInterventionData();
    let taskObject: InterventionTaskResponse = {
      wordListType: wordListType,
      interventionTaskIdentifier: this.task.id,
      interventionTrials: trialList,
      points: totalPoints,
      durationMillis: this.studentDataService.getSelectedTaskDurationMillis(),
      objectiveNumber: interventionData!.objectiveNumber,
      type: interventionData!.type,
      unitNumber: interventionData?.unitNumber ?? 0,
    }
    return taskObject;
  }

  // Save the parameters used for saving the task and trial data so they can be accessed in saveTaskData() when it is used as a callback
  private saveTaskDataParams(wordListType: number | null, trialList: InterventionTrialResponse[], totalPoints: number, taskFinished: boolean) {
    let taskObject = this.createTaskResponseObject(wordListType, trialList, totalPoints);
    this.taskDataParams.taskData = [];

    // TODO: need to check the process of subtracting off points, currently, points can be undefined on previousTaskData, but
    //       we may just need to make it required so we don't need to check if its defined here
    if (this.previousTaskData !== null && this.previousTaskData.points) {
      // Total points are cummulative over wordlist attempts, so we need to subtract off the
      // points from previous attempt to find out how many points this particular wordlist earned
      taskObject.points -= this.previousTaskData.points;
      this.taskDataParams.taskData.push(this.previousTaskData);
    }
    this.taskDataParams.taskData.push(taskObject);
    this.taskDataParams.taskFinished = taskFinished;
  }

  public getTaskDataParams() {
    return this.taskDataParams;
  }

  // Save the task without setting it to complete
  private saveTask(wordListType: number, totalPoints: number, numberOfTrials: number, numberOfCorrectTrials: number, trialList: InterventionTrialResponse[]) {
    this.studentDataService.setTotalPoints(this.currentDestination, totalPoints, numberOfTrials, numberOfCorrectTrials);
    this.saveTaskDataParams(wordListType, trialList, totalPoints, false);
  }

  // Completes the task, sets it's points and score, update task completion count, and saves the trial data
  private completeAndSaveTask(wordListType: number | null, totalPoints: number, numberOfTrials: number, numberOfCorrectTrials: number, trialList: InterventionTrialResponse[]) {
    // NOTE: because this is completing the task, we set nextTask to null to ensure we navigate back to task select
    this.nextTask = null;
    this.studentDataService.setTotalPoints(this.currentDestination, totalPoints, numberOfTrials, numberOfCorrectTrials);
    this.studentDataService.updateTaskCompletionCount(this.task.id);
    this.saveTaskDataParams(wordListType, trialList, totalPoints, true);
  }

  // Searches for and returns a task of the same type with the next highest wordlist - returns null if none exist
  private findHigherWordListTask(taskWordList: string): InterventionTask | null {
    this.nextTask = null;
    if (taskWordList !== 'challenge') {
      // Grab task with next highest wordlist if available
      let nextWordList = (taskWordList === 'easy') ? 'medium' : 'challenge';
      let taskList = this.curriculumService.getTasksForDestination(this.currentDestination) as InterventionTask[];
      for (let i = 0; i < taskList.length; i++) {
        if (taskList[i].id === this.task.id && taskList[i].wordlistType === nextWordList) {
          this.nextTask = taskList[i];
          break;
        }
      }
    }
    return this.nextTask;
  }

  // Searches for and returns a task of the same type with the next lowest wordlist - returns null if none exist
  private findLowerWordListTask(taskWordList: string): InterventionTask | null {
    this.nextTask = null;
    if (taskWordList !== 'easy') {
      // Grab task with next highest wordlist if available
      let nextWordList = (taskWordList === 'challenge') ? 'medium' : 'easy';
      let taskList = this.curriculumService.getTasksForDestination(this.currentDestination) as InterventionTask[];
      for (let i = 0; i < taskList.length; i++) {
        if (taskList[i].id === this.task.id && taskList[i].wordlistType === nextWordList) {
          this.nextTask = taskList[i];
          break;
        }
      }
    }
    return this.nextTask;
  }

  // Check if the user should be able to see an instructional video for this task
  private checkVideoRules(attempt: number) {
    let taskId = this.task.id ;
    let playVideo = true;

    // Do not play the instructional video if any of these rules are met:
    if (this.studentDataService.isDemoUser()) {
      // Teacher demo users never have videos play initially
      playVideo = false;
    } else if ((this.wordListAttempt == 2) || (attempt == 2)) {
      // The student is on their 2nd wordlist attempt or their 2nd attempt for this task
      playVideo = false;
    }

    return playVideo;
  }

    /**
    * Initializes key variables for this factory and starts off the task with
    * instructional video and audio.
    *
    * NOTE: This method should be called by the task controller inside of $watch once the task animation is complete
    */
  public initTaskContainerElements(currentTask: InterventionTask, taskCompleted: boolean, wordListAtt: number, attempt: number, parentTaskId: string = ''): Observable<any[]> {
    // Set our factory global variables
    this.task = currentTask;
    this.parentTaskId = parentTaskId ;
    this.instructionalAudioFile = 'Audio/Help/' + currentTask.taskaudio + '.mp3';
    this.playInstructionalAudio = true;
    this.playTrialInstructionalAudio = true;
    this.currentDestination = this.studentDataService.getCurrentDestination();
    this.alreadyCompleted = taskCompleted;
    this.interventionTask = this.studentDataService.getInterventionTask(currentTask.id, this.currentDestination);
    this.wordListAttempt = wordListAtt;
    this.showTryAgainDialogFlag = false;
    this.taskDataParams = {
      taskData: [],
      taskFinished: false
    };

    // Initialize response trend tracking variables to 0
    this.onFirstTrial = true;
    this.showAudioSupport = false ;
    this.hasTwoIncorrectInSequence = false ;
    this.subTask = (this.task.taskType.indexOf("PASSAGES...") === 0);
    this.studentWordListLevel = this.studentDataService.getWordListType();
    this.reducedResponses = false;
    this.consecutiveCorrect = 0;
    this.consecutiveIncorrect = 0;
    this.numIncorrectBeforeResponseReset = 0;

    // Local variables
    let isUnit = this.studentDataService.isInterventionUnit();

    // Check if Read and Listen task (instructional audio will be handled differently):
    this.isReadAndListenTask = (this.task.id.startsWith('PASS'));

    // Reset previous trial data if first word list attempt and NOT a Read and Listen task
    // NOTE: Read and Listen tasks only have 1 wordlist and do not need to hold onto previous task data
    if (wordListAtt === 1) {
      if (!this.subTask){
        this.previousTaskData = null;

        // Save previous task score data
        this.previousTaskScoreData = {
          'points': this.interventionTask!.points ?? 0,
          'numberOfTrials': this.interventionTask!.numberOfTrials,
          'numberOfCorrectTrials': this.interventionTask!.numberOfCorrectTrials,
          'interventionTaskIdentifier': this.interventionTask!.interventionTaskIdentifier,
          'destination': this.interventionTask!.destination
        };
      }

      // Record the wordlist difficulty of this task to use again if the student needs to retry
      this.firstWordListTask = currentTask;
    }

    // Get trial instructional audio if it exists
    if (currentTask.trialinstructionalaudio) {
        this.trialInstructionalAudio = 'Audio/Help/' + currentTask.trialinstructionalaudio + '.mp3';
    } else {
        this.trialInstructionalAudio = null;
    }

    // Hide timer bar if the student status does not have the timer bar enabled or the task does not specify to use a timer bar
    let timerBarTaskSettings = this.getTimerBarTaskSettings();

    // Check for specific rules that inhibit the instructional video from playing
    this.playVideoFlag = this.checkVideoRules(attempt);

    //Wait for fade in of task container elements, then preload assets and start task
    return forkJoin([
      this.assetPreloaderService.preloadTaskAssets(this.task),
      new Observable((observer) => {
        window.setTimeout(() => {
          if (!this.studentDataService.hasCompletedAtLeastOneTaskLikeThis(this.task.id) && this.playVideoFlag) {
            this.playInstructionalAudio = false;
          }

          observer.next(this.playInstructionalAudio) ;
          observer.complete() ;
        }, 1000);
      })
    ]);
  }

  // Get the settings for the timer bar for the current task
  public getTimerBarTaskSettings(): any {
    let timerBarEnabled = false;
    let timerBarDelay = 100;
    let timerBarSpeed = 2000;

    if (this.studentDataService.getTimerEnabled()) {
      // Use the current task, unless this is the second wordlist attempt, in which case we use the same task
      // as we did for the first wordlist
      let timerBarTask = ((this.wordListAttempt === 2) && (this.firstWordListTask !== null) ? this.firstWordListTask : this.task);
      timerBarEnabled = timerBarTask.timerBar ;
      timerBarDelay = timerBarTask.timerBarDelay! ;
      timerBarSpeed = timerBarTask.timerBarSpeed! ;
    }

    return {
      'timerBarEnabled': timerBarEnabled,
      'timerBarDelay': timerBarDelay,
      'timerBarSpeed': timerBarSpeed
    };
  }

  // Get the amount of points this task should start with (points carry over between wordlist attempts)
  public getStartingPoints(taskId: string, currentDestination: number, wordListAttempt: number): number {
    if (this.studentDataService.isInterventionUnit() && wordListAttempt === 2) {
      let interventionTask = this.studentDataService.getInterventionTask(taskId, currentDestination);
      return interventionTask?.points ?? 0;
    }
    else {
      return 0;
    }
  }

  // Increment our response tracking variables
  public trackResponseTrends(isTrialCorrect: boolean) {
    if (isTrialCorrect) {
      this.consecutiveIncorrect = 0;
      this.consecutiveCorrect++;
    }
    else {
      this.consecutiveCorrect = 0;
      this.consecutiveIncorrect++;
      this.numIncorrectBeforeResponseReset++;
      this.numIncorrectBeforeResponseResetAudio++;
    }

    if (!this.hasTwoIncorrectInSequence && this.consecutiveIncorrect >= 2)
    {
      this.hasTwoIncorrectInSequence = true ;
    }
  }

  public createTrialDataTrackerObject(): DataTracker {
    let dataTrackerObj: DataTracker = {
      targetAnswer: null,
      response: null,
      response2: null,
      requestSupport: 0,
      redisplayMaskedWord: 0,
    }

    return dataTrackerObj;
  }

  public recordResponseInTrialDataTrackerObject(dataTrackerObj: DataTracker, response: string) {
    if (dataTrackerObj.response == null) {
      dataTrackerObj.response = response;
    } else {
      dataTrackerObj.response2 = response;
    }
  }

  sendTeacherNotification(): Observable<any> {
    let reqOptions = {
      withCredentials: true,
      headers: {
        'Access-Control-Allow-Origin' : "*",
        'X-FIL-Version' : environment.versionNumber,
      },
    };

    let studentData = this.studentDataService.getStudentData();

    return this.httpClient.post(`${environment.EC2URL}v3/student/taskResponsePoorUsage`, studentData, reqOptions);
  }

  /**
    * Create the response object (contains intervention trial data)
    * Returns the response object
    */
  public createTrialResponseObject(isCorrect: boolean, trialIndex: number, responseTimeOne: number,
    responseTimeTwo: number, trialPoints: number, dataTracker?: DataTracker, selectedResponse?: number): InterventionTrialResponse {
    let targetAnswer = (dataTracker != null ? dataTracker.targetAnswer : null);
    let response = (dataTracker != null ? dataTracker.response : null);
    let response2 = (dataTracker != null ? dataTracker.response2 : null);
    let requestSupport = (dataTracker != null ? dataTracker.requestSupport : 0);
    let redisplayMaskedWord = (dataTracker != null ? dataTracker.redisplayMaskedWord : 0);
    let morpheme = (dataTracker !== null ? dataTracker?.morpheme : null) ;
    let speechPart = (dataTracker !== null ? dataTracker?.speechPart : null) ;

    let taskId = this.task.id;
    let respFoil: string | null;
    let foil = [];
    if (taskId.startsWith("CHW")) {
      let addEToEnd = false;
      let tile = <Tile[]>this.task.trial[trialIndex].display.tile;
      for (let character in tile) {
        // If a tile in the target word contains the silent e syntax, remove the +e and append that to the end,
        // but keep the letter(s) before the +e.  Then set a flag to tell us to append an e to the end of the word
        let tileText = tile[character]['#text'];
        if (tileText.includes('+')) {
          addEToEnd = true;
          foil.push(tileText.substring(0, tileText.indexOf('+')));
        }
        else {
          foil.push(tileText);
        }
      }
      if (addEToEnd) {
        foil.push('e');
      }
      respFoil = foil.toString().replace(/\,/g,"") || "";
    } else if (taskId.startsWith("VER") || taskId.startsWith("MVER")) {
      respFoil = <string>this.task.trial[trialIndex].display.tile || "";
    } else {
      respFoil = null;
    }

    let numberOfSyls: number;
    if (this.task.trial[trialIndex].sylls) {
      numberOfSyls = parseInt(this.task.trial[trialIndex].sylls || "0");
    } else if (this.task.trial[trialIndex]['syllable-list'] &&
      this.task.trial[trialIndex]['syllable-list']?.syllable) {
      numberOfSyls = this.task.trial[trialIndex]['syllable-list']?.syllable.length || 1;
    } else {
      numberOfSyls = 0;
    }

    let masked = this.task.masked ? 'M' : 'U';
    let maskTime = 0;
    if (this.task.masked) {
      maskTime = (this.hasTwoIncorrectInSequence) ? this.task.maskerTime! + this.maskerSupportExtend : this.task.maskerTime!;
    }

    let responseObject: InterventionTrialResponse = {
      responseTimeOne: responseTimeOne,
      responseTimeTwo: responseTimeTwo,
      isCorrect: isCorrect,
      trialNumber: trialIndex + 1 || 0, // trialNumber is zero-based, so add 1,
      points: trialPoints,
      masked: masked,
      maskTime: maskTime,
      targetWordType: this.task.trial[trialIndex].wordType || "",
      targetAnswer: targetAnswer || "",
      response: response || "",
      response2: response2 || "",
      requestSupport: requestSupport || 0,
      redisplayMaskedWord: redisplayMaskedWord || 0,
      numberOfSyls: numberOfSyls,
      foil: respFoil,
      responsePosition: selectedResponse,
      morpheme,
      speechPart,
    }

    return responseObject;
  }

  // Handles the entire end of task process. Will determine next wordlist task, save the trial data, and transition to either the task select screen
  // or the next task when appropriate
  public handleEndOfTaskProcess(trialList: InterventionTrialResponse[], totalPoints: number, numberOfTrials: number, numberOfCorrectTrials: number, attempt?: number): Observable<any> {
    return new Observable<any>((observer) => {
    // Determine if this is first or second attempt on this task
    if (this.studentDataService.isInterventionUnit()) {
      let taskWordList = this.task.wordlistType ?? "";
      let taskWordListAsInt = this.studentDataService.getWordListTypeAsInt(taskWordList);

      if (this.wordListAttempt === 2) {
        // NOTE: we need to set nextTask to null here so task knows to go to task select
        this.nextTask = null;
        // Scores are averaged across word list attempts -- total up trial and correctTrial counts
        let totalNumberOfTrials = this.interventionTask!.numberOfTrials + numberOfTrials;
        let totalNumberOfCorrectTrials = this.interventionTask!.numberOfCorrectTrials + numberOfCorrectTrials;
        if (attempt === 2) {
          // This is the second time taking this task
          // Points are cumulative and scores are averaged!
          this.completeAndSaveTask(taskWordListAsInt, totalPoints, totalNumberOfTrials, totalNumberOfCorrectTrials, trialList);
        }
        else if (attempt === 1) {
          if (this.studentDataService.calculateTaskPercentage(totalNumberOfCorrectTrials, totalNumberOfTrials) >= 75) {
            // Save and exit to task select screen
            this.completeAndSaveTask(taskWordListAsInt, totalPoints, totalNumberOfTrials, totalNumberOfCorrectTrials, trialList);
          }
          else {
            this.showTryAgainDialogFlag = true;
            this.saveTask(taskWordListAsInt, totalPoints, totalNumberOfTrials, totalNumberOfCorrectTrials, trialList);
          }
        }
      }
      else if (this.wordListAttempt === 1) {
        // Does the student need to retake with a different wordlist? (Use trial counts from this attempt only)
        this.nextTask = null;
        let passed = false;
        if (this.studentDataService.calculateTaskPercentage(numberOfCorrectTrials, numberOfTrials) >= 75) {
          // Upgrade the wordlist and retake the task
          passed = true;
          this.nextTask = this.findHigherWordListTask(taskWordList);
        }
        else {
          // Downgrade the wordlist and retake the task
          this.nextTask = this.findLowerWordListTask(taskWordList);
        }

        if (this.nextTask !== null) {
          // Set the points/score on the intervention task list to keep them when transitioning to another wordlist
          this.studentDataService.setTotalPoints(this.currentDestination, totalPoints, numberOfTrials, numberOfCorrectTrials);
          // Keep the task data from this word list attempt before transitioning to the next one
          this.previousTaskData = this.createTaskResponseObject(taskWordListAsInt, trialList, totalPoints);
          // Start next task right away
          this.studentDataService.setSelectedTask(this.nextTask);
        }
        else if (attempt === 1 && !passed) {
          this.showTryAgainDialogFlag = true;
          this.saveTask(taskWordListAsInt, totalPoints, numberOfTrials, numberOfCorrectTrials, trialList);
        }
        else {
          // Save and exit to task select screen
          this.completeAndSaveTask(taskWordListAsInt, totalPoints, numberOfTrials, numberOfCorrectTrials, trialList);
        }
      }
    }
    else {
      // Pretest or Posttest so do normal completion process
      this.completeAndSaveTask(null, totalPoints, numberOfTrials, numberOfCorrectTrials, trialList);
    }
      observer.next();
    })
  }

  public playSoundEffect(isCorrect: boolean) {
    if (isCorrect) {
      this.audioPlayerService.play('Audio/Help/SNDpositive.mp3').subscribe();
    }
    else {
      this.audioPlayerService.play('Audio/Help/SNDnegative2.mp3').subscribe();
    }
  }

  /**
    * When randomizeResponses is equal to true or does not exist
    * as an argument, then shuffle the responses into a random order.
    */
  public shuffleResponses(responseList: any[], randomizeResponses: any): any[] {
    var randResponsesBool;
    switch (typeof (randomizeResponses)) {
      case 'string':
        randResponsesBool = (randomizeResponses.toLowerCase() === 'true');
        break;
      case 'boolean':
        randResponsesBool = randomizeResponses;
        break;
      default:
        randResponsesBool = true;
    }

    if (randResponsesBool) {
      return this.shuffleService.shuffleArray(responseList);
    } else {
      return responseList;
    }
  }

  /**
    * This function will return a reduced response list if:
    * + The student's word list is easy and they have just started the task
    * + The student has missed 2 trials in a row or 3 trials total
    * + The student has a reduced response list and hasn't consecutively answered 3 trials correctly
    *
    * This function will return a full response list if:
    * + The student has a reduced response list and has consecutively answered 3 trials correctly
    * + This is NOT an instructional task (ie, it is pretest or posttest)
    * + All other scenarios
    *
    * If responses are being reduced:
    * Returns shallow copy of the first half of the input array
    * If the array is an odd number, the method returns the length/2 rounded up.
    */
  public reduceResponsesIfNecessary(responseList: Response[]): Response[] {

    if (!this.studentDataService.isInterventionUnit()) {
      return responseList;
    }

    if ((this.onFirstTrial && this.studentWordListLevel !== 'challenge' && this.studentDataService.getStudentData().extraSupportNeeded) ||
      (this.onFirstTrial && this.studentWordListLevel === 'easy' && !this.subTask) ||
      this.consecutiveIncorrect >= 2 || (this.numIncorrectBeforeResponseReset >= 3 && this.consecutiveCorrect < 3) ||
      (this.reducedResponses && this.consecutiveCorrect < 3)) {
      // Reduce the response list
      let reducedListLength = Math.ceil(responseList.length / 2);
      let reducedList = responseList.slice(0, reducedListLength);
      this.reducedResponses = true;

      // If this was the first trial, flip the flag
      if (this.onFirstTrial) this.onFirstTrial = false;

      return reducedList;
    }
    else if (this.reducedResponses && this.consecutiveCorrect >= 3) {
      // The response list will be increased again so reset the total incorrect count
      this.numIncorrectBeforeResponseReset = 0;
      this.reducedResponses = false;
    }

    return responseList;
  }

  /**
    * Runs expected animations and transitions to the next trial. If the 'points floating up' animation is running, then it will wait for that to finish first.
    *
    * @param responseObject The object built from the student's response. InterventionTaskFactory.createTrialResponseObject() can be called to generate this.
    * @param runningPointsAnimation True/False if the 'points floating up' animation will be running
    */
  public moveToNextTrial(responseObject: InterventionTrialResponse, runningPointsAnimation: boolean): Observable<any> {
    return new Observable((observer) => {
      if (runningPointsAnimation) {
        // Wait for the animation to finish before moving on to the next trial
        window.setTimeout(() => {
          observer.next();
          observer.complete();
        }, this.floatUpAnimationDelayWithWait);
      }
      else {
        // Move on to the next trial after the trial counter updates
        observer.next();
        observer.complete();
      }
    })
  }


  /**
    * Used to get the previous intervention task data before the student began their latest attempt. The most common use for this method
    * should be for resetting the points and score in the task cloud on the task select screen when the student hits the back button.
    */
  public getPreviousTaskScoreData(): number {
    return this.previousTaskScoreData;
  }

  /**
    * Reset data in the factory due to a student starting a new curriculum in the same session
    */
  public resetData() {
    this.previousTaskScoreData = null;
  }

  /**
    * Generic properties used for every task's template
    */
  public taskBackgroundImage(): string { return this.studentDataService.getTaskBackground(); }
  public taskContainerColor(): string { return this.studentDataService.getTaskContainerColor(); }
  public taskBarColor(): string { return this.studentDataService.getTaskBarColor(); }
  public buttonColor(): string { return this.studentDataService.getButtonColor(); }
  public highlightColor(): string { return this.studentDataService.getTaskBarColor(); }

  // Set the test type if pretest or posttest
  public testType(): string {
    if (this.studentDataService.isInterventionPreTest()) {
      return "PRETEST";
    }
    else if (this.studentDataService.isInterventionPostTest()) {
      return "POSTTEST";
    }
    return '';
  }

  public getTrialMaskedAudio(task: InterventionTask): string | null {
    // There is more instructional audio if the task is masked
    if (task.taskaudiomasked) {
      return 'Audio/Help/' + task.taskaudiomasked + '.mp3';
    }
    return null;
  }

  public uploadAudioTrialSubmission(formData: FormData) {
    let reqOptions = {
      withCredentials: true,
      headers: {
        'Access-Control-Allow-Origin': "*",
      },
    };

    const audioUploadURL = environment.EC2URL + 'test/audio/upload';
    return this.httpClient.post(audioUploadURL, formData, reqOptions);
  }

  /**
   * Get delay after a single response in an intervention trial.
   *
   * @param trialList - List of responses in the trial.
   * @param delayDuration - Optional. Array containing two elements:
   *  - delayDuration[0]: Delay (ms) after a correct response (default: 1000).
   *  - delayDuration[1]: Delay (ms) after an incorrect response (default: value of this.incorrectAnswerAudioDelay).
   * Example: getDelayAfterSingleResponse(trialList, [300, 1000]) sets the delay
   * to 300ms for correct responses and 1000ms for incorrect responses.
   */
  public getDelayAfterSingleResponse(
    trialList: InterventionTrialResponse[],
    delayDuration?: [number, number]
  ): number {
    // Validate delayDuration if provided.
    if (
      delayDuration && (
        delayDuration.length !== 2 ||
        typeof delayDuration[0] !== 'number' ||
        typeof delayDuration[1] !== 'number' ||
        delayDuration[0] <= 0 ||
        delayDuration[1] <= 0
      )
    ) {
      throw new Error('delayDuration must be an array of two positive numbers.');
    }

    let isCorrect = trialList[trialList.length - 1].isCorrect;
    let testType = this.testType();

    // Use 1000ms delay for correct Pre/Post and all Unit Tasks.
    if (isCorrect || testType == '') {
      return delayDuration ? delayDuration[0] : this.correctAnswerAudioDelay;
    }

    return delayDuration ? delayDuration[1] : this.incorrectAnswerAudioDelay;
  }

  public hasInitialAudioSupport(): boolean {
    if (!this.studentDataService.isInterventionUnit()) return false ;

    if ((this.onFirstTrialAudio && this.studentWordListLevel !== 'challenge' && this.studentDataService.getStudentData().extraSupportNeeded) ||
        (this.onFirstTrial && this.studentWordListLevel === 'easy' && !this.subTask) ||
        this.consecutiveIncorrect >= 2 || (this.numIncorrectBeforeResponseResetAudio >= 3 && this.consecutiveCorrect < 3) ||
        (this.showAudioSupport && this.consecutiveCorrect < 3))
    {
      if (this.onFirstTrialAudio) this.onFirstTrialAudio = false ;
      this.showAudioSupport = true ;
    }
    else if (this.showAudioSupport && this.consecutiveCorrect >= 3)
    {
      this.numIncorrectBeforeResponseResetAudio = 0 ;
      this.showAudioSupport = false ;
    }

    return this.showAudioSupport ;
  }

  public getInstructionalAudioFile(): string {
    return this.instructionalAudioFile;
  }

  public getTrialInstructionalAudio(): string {
    // NOTE: if for some reason trialInstructionalAudio isn't set and we try to access it, just return silence
    return this.trialInstructionalAudio ?? 'Audio/Help/silence.mp3';
  }

  public getShowTryAgainDialogFlag(): boolean {
    return this.showTryAgainDialogFlag;
  }

  public getNextTask(): InterventionTask | null {
    return this.nextTask;
  }

  public getParentTaskId(): string {
    return this.parentTaskId ;
  }

  public getWordListAttempt(): number {
    return this.wordListAttempt;
  }

  public getFirstWordListTask(): InterventionTask {
    return this.firstWordListTask;
  }

  public getAlreadyCompleted(): boolean | null {
    return this.alreadyCompleted;
  }

  public getPlayVideoFlag(): boolean {
    return this.playVideoFlag
  }

  public browserBackButtonListener() {
    return this.router.events.pipe(
      filter((event: Event) => event instanceof NavigationStart && event.navigationTrigger === 'popstate')
    );
  }
}
