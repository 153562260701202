import { Injectable, EventEmitter } from '@angular/core';
import { Observable, forkJoin, of } from 'rxjs';
import { retry, catchError } from 'rxjs/operators';
import { DiagnosticTask, DiagnosticTrial, InterventionTask, InterventionTrial, RespList } from '../models/task.model';
import { StudentDataService } from './student-data.service';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import * as Sentry from "@sentry/angular";
import { Capacitor } from '@capacitor/core';

@Injectable({
  providedIn: 'root'
})
export class AssetPreloaderService {
  public loadingErrorOccurred: EventEmitter<string> = new EventEmitter();

  constructor(
    private studentDataService: StudentDataService,
    private httpClient: HttpClient,
  ) { }

  preloadTaskAssets(task: DiagnosticTask | InterventionTask) {
    let trials: DiagnosticTrial[] | InterventionTrial[] = task.trial;

    let targetAudioList: Set<string> = new Set();
    let imageList: Set<string> = new Set();

    if (task.trialaudiochange)
    {
      targetAudioList.add(`/assets/Audio/Help/${task.trialaudiochange}.mp3`) ;
    }

    if (task.trialaudiotomake)
    {
      targetAudioList.add(`/assets/Audio/Help/${task.trialaudiotomake}.mp3`) ;
    }

    if (task.taskaudio)
    {
      targetAudioList.add(`/assets/Audio/Help/${task.taskaudio}.mp3`) ;
    }

    if (task.masked && task.taskaudiomasked)
    {
      targetAudioList.add(`/assets/Audio/Help/${task.taskaudiomasked}.mp3`) ;
    }

    if (trials != null) {
      // Get audio file paths
      for (let i=0; i<trials.length; ++i) {
        // initial-audio
        if ((trials[i]['initial-audio'])) {
          targetAudioList.add("/assets/" + trials[i]['initial-audio']);
        }

        // target audio
        if ((trials[i].word) && (trials[i].word['@audio'])) {
          targetAudioList.add("/assets/" + trials[i].word['@audio']);
        }

        // phrase audio
        if (trials[i].phrase) {
          let trial = <InterventionTrial>trials[i];
          if (trial.phrase?.['@audio']) {
            targetAudioList.add("/assets/" + trial.phrase?.['@audio']);
          }
        }

        // question audio for answer the question task
        if (trials[i].question) {
          if (trials[i].question?.['@audio']) {
            targetAudioList.add("/assets/" + trials[i].question?.['@audio']);
          }
        }
      }

      // Preload SNDpositive, SNDneutral, and SNDnegative audio
      targetAudioList.add("/assets/Audio/Help/SNDnegative2.mp3");
      targetAudioList.add("/assets/Audio/Help/SNDneutral.mp3");
      targetAudioList.add("/assets/Audio/Help/SNDpositive.mp3");

      // Preload try again audio
      targetAudioList.add("/assets/Audio/Help/help_tryagain.mp3");
      targetAudioList.add("/assets/Audio/Help/help_correctansweris.mp3");

      // Preload inactivity audio
      targetAudioList.add("/assets/Audio/Help/inactivity.mp3");

      // Preload support images
      imageList.add("/assets/images/buttons/videoReplay.svg");
      imageList.add("/assets/images/audioBttn.svg");
      imageList.add("/assets/images/audioBttnSupport.svg");
      imageList.add("/assets/images/incorrectSlash.svg");
      imageList.add("/assets/images/destination/bttnGoBack.png");
      imageList.add("/assets/images/destination/iconUnmasked.svg");

      // Preload trialcounter images
      imageList.add("/assets/images/trialcounter/trialCounter_correct.svg");
      imageList.add("/assets/images/trialcounter/trialCounter_incorrect.svg");
      imageList.add("/assets/images/trialcounter/trialCounterNeutral_filled.svg");
      imageList.add("/assets/images/trialcounter/trialCounterNeutral_indent.svg");
      imageList.add("/assets/images/trialcounter/trialCounter_indent.svg");

      // Get spell the word audio file paths
      for (let i = 0; i < trials.length; ++i) {
        const respListArray = trials[i]['resp-list'] as RespList | RespList[] | undefined;
        if (respListArray !== undefined && respListArray !== null) {
          if (Array.isArray(respListArray)) {
            for (let j = 0; j < respListArray.length; ++j) {
              const respList = respListArray[j];
              if (respList !== undefined && respList !== null && respList.resp !== undefined && respList.resp !== null) {
                for (let k = 0; k < respList.resp.length; ++k) {
                  const resp = respList.resp[k];
                  if (resp !== undefined && resp !== null) {
                    const responseAudio = resp['@audio'];
                    if (responseAudio !== undefined && responseAudio !== null) {
                      targetAudioList.add("/assets/" + responseAudio);
                    }
                  }
                }
              }
            }
          } else if (respListArray !== undefined && respListArray !== null && respListArray.resp !== undefined && respListArray.resp !== null) {
            const respList = respListArray as RespList;
            for (let k = 0; k < respList.resp.length; ++k) {
              const resp = respList.resp[k];
              if (resp !== undefined && resp !== null) {
                const responseAudio = resp['@audio'];
                if (responseAudio !== undefined && responseAudio !== null) {
                  targetAudioList.add("/assets/" + responseAudio);
                }
              }
            }
          }
        }
      }


      // Get image file paths
      for (let i=0; i<trials.length; ++i) {
        if (trials[i]['resp-list'] && trials[i]['resp-list']?.resp !== undefined) {
          for (let j=0; j<trials[i]['resp-list']!.resp.length; ++j) {
            let resp = trials[i]['resp-list']!.resp[j];
            var responsePicture = resp['@image'];
            if (responsePicture !== undefined && (responsePicture !== null)) {
              imageList.add("/assets/" + responsePicture);
            }
          }
        } else {
          // No response pictures to load
          break;
        }
      }
    }
    else {
      // Passages Read and Listen task
      if (this.studentDataService.isIntervention()) {
        let passage = (<InterventionTask>task).passage;
        // Passage audio
        if (passage?.['@audio']) {
          targetAudioList.add("/assets/" + passage['@audio']);
        }

        // Passage title audio
        if (passage?.title && passage?.title['@audio']) {
          targetAudioList.add("/assets/" + passage.title['@audio']);
        }

        // Individual passage phrase audio
        if (passage?.phrase) {
          let phrases = passage.phrase;
          for (let i = 0; i < phrases.length; i++) {
            targetAudioList.add("/assets/" + phrases[i]['@audio']);
          }
        }
      }
    }

    return forkJoin([
      this.preloadImages([...imageList]),
      this.preloadAudio([...targetAudioList])
    ]) ;
  }

  preloadImages(imageList: string[]): Observable<any[]> {
    if (Capacitor.getPlatform() === 'ios') {
      console.debug(`Skipping image preloading on iOS`);
      return of(['']);
    }

    console.debug(`Preloading ${imageList.length} images`);
    // Map each image path to an observable that loads the image
    const imageLoadObservables = imageList.map(imagePath =>
      this.loadImage(imagePath).pipe(
        retry(2),
      )
    );

    if (!imageLoadObservables.length)
    {
      return of(['']) ;
    }

    return forkJoin(imageLoadObservables).pipe(
      catchError(error => {
        // NOTE: In this loading instance, we are considering a 403 error as a missing asset because asset files
        //     : are loaded from S3 buckets on production (or angular server in dev), regardless they do not
        //     : actually require any type of authentication, however S3 buckets will return a 403 if a file is
        //     : not found and the user does not have bucket list rights (which no one does and no one should)
        if (error && (error.status === 404 || error.status === 403))
        {
          Sentry.captureMessage(`Could not load image asset for task: ${error.url}`) ;
        }
        else
        {
          console.log('error loading images observables', error) ;
          this.loadingErrorOccurred.emit('image');
        }

        return of(['']);
      }));
  }

  preloadAudio(audioList: string[]): Observable<any[]> {
    if (Capacitor.getPlatform() === 'ios') {
      console.debug(`Skipping audio preloading on iOS`);
      return of(['']);
    }

    console.debug(`Preloading ${audioList.length} audio files`);
    // Map each audio path to an observable that loads the audio
    const audioLoadObservables = audioList.map(audioPath =>
      this.loadAudio(audioPath).pipe(
        retry(2),
      )
    );

    if (!audioLoadObservables.length)
    {
      return of(['']) ;
    }

    return forkJoin(audioLoadObservables).pipe(
      catchError(error => {
        // NOTE: In this loading instance, we are considering a 403 error as a missing asset because asset files
        //     : are loaded from S3 buckets on production (or angular server in dev), regardless they do not
        //     : actually require any type of authentication, however S3 buckets will return a 403 if a file is
        //     : not found and the user does not have bucket list rights (which no one does and no one should)
        if (error && (error.status === 404 || error.status === 403))
        {
          Sentry.captureMessage(`Could not load audio asset for task: ${error.url}`) ;
        }
        else
        {
          console.log('error loading audio observables', error) ;
          this.loadingErrorOccurred.emit('audio');
        }

        return of(['']);
      })) ;
  }

  loadImage(imagePath: string) {
    return this.httpClient.get(environment.AssetServerURL + imagePath, { responseType: 'blob' }) ;
  }

  loadAudio(audioPath: string): Observable<any> {
    return this.httpClient.get(environment.AssetServerURL + audioPath, { responseType: 'blob' }) ;
  }
}
