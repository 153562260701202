import {Component, ChangeDetectionStrategy, OnInit, AfterViewInit, ChangeDetectorRef} from '@angular/core';
import { Router } from '@angular/router';
import { of } from 'rxjs';
import { concatMap, first, map, mergeMap } from 'rxjs/operators';
import { InterventionTrial } from 'src/app/core/models/task.model';
import { AudioPlayerService } from 'src/app/core/services/audio-player.service';
import { InterventionTaskService } from 'src/app/core/services/intervention-task.service';
import { ShuffleService } from 'src/app/core/services/shuffle.service';
import { StudentDataService } from 'src/app/core/services/student-data.service';
import { TimerService } from 'src/app/core/services/timer.service';
import { InterventionTaskComponent } from '../intervention-task.component';
import { LetterSpaces, ResponseTile } from './spell-the-word-intervention.model';
import { TaskService } from '../../core/services/task.service';

const NO_BLANK_TILE: number = 99;

@Component({
  selector: 'spell-the-word-intervention',
  templateUrl: './spell-the-word-intervention.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SpellTheWordInterventionComponent extends InterventionTaskComponent implements OnInit, AfterViewInit {

  constructor(
    public studentDataService: StudentDataService,
    public interventionTaskService: InterventionTaskService,
    public timerService: TimerService,
    public audioPlayerService: AudioPlayerService,
    public router: Router,
    public shuffleService: ShuffleService,
    public taskService: TaskService,
    public changeDetector: ChangeDetectorRef,
  ) {
    super(studentDataService, interventionTaskService, timerService, audioPlayerService, router);
  }

  trials: InterventionTrial[] = this.task.trial;
  numberOfCorrectTrials: number = 0;
  incorrectFirstResponse: boolean = false;
  oneIncorrectAnswer: boolean = false;
  audioList: string[] = [];
  targetWord: LetterSpaces[] = [];
  blankTile: number = 0;
  hideSubmitButton: boolean = true;
  disableTile: boolean = true;
  disableSubmitButton: boolean = true;
  showTargetSilentE: boolean = false;
  //hideBottomResponses: boolean = true;
  targetAnswer: string = '';
  responseOptions: ResponseTile[] = [];
  responseOptions1: ResponseTile[] = [];
  responseOptions2: ResponseTile[] = [];
  originalStartTime: number = 0;

  ngOnInit(): void {
    // Check to shuffle trials
    if (this.task.randomTrials) {
      this.trials = this.shuffleService.shuffleArray(this.trials);
    }
    // Get starting points for the total points cloud
    this.taskTotalPoints = this.interventionTaskService.getStartingPoints(this.task.id, this.currentDestination, this.wordListAttempt);
  }

  ngAfterViewInit(): void {
    // After view is initialized wait for task animation to complete and then initialize everything else
    this.taskBar.taskAnimationComplete.pipe(first())
    .subscribe(() => {
      // set this to tell the trial-counter that animation is complete
      this.animationComplete = true;
      this.interventionTaskService.initTaskContainerElements(this.task, this.alreadyCompleted, this.wordListAttempt, this.attempt)
        .pipe(first(),
          map(() => {
            let timerBarSettings = this.interventionTaskService.getTimerBarTaskSettings();
            timerBarSettings.timerBarEnabled ? this.trialTimerBar.showTimerBar() : this.trialTimerBar.hideTimerBar();
          }),
          concatMap(() => {
            if (!this.studentDataService.hasCompletedAtLeastOneTaskLikeThis(this.task.id) && this.interventionTaskService.getPlayVideoFlag()) {
              this.playInstructionalAudio = false;
              return this.instructions.playInstructionalVideo();
            }
            else {
              return of({});
            }
          }),
          // NOTE: we always play the instructional audio on the first trial, so only check for video here
        )
        .subscribe({
          complete: () => this.defaultAudioCompleteFunc(),
        });
    });

    // Display the focus dialog if needs focus is set (from intevention task)
    if(this.needsFocus){
      this.focusDialog.showDialog();
    }
  }

  createLetterSpaces(text: string, disabled: boolean, hasSilentE: boolean | null, targetIndex: number | null) : LetterSpaces{
    let letterSpaces: LetterSpaces = {
      text: text,
      disabled: disabled,
      hasSilentE: hasSilentE,
      targetIndex: targetIndex,
    };
    return letterSpaces;
  }

  createResponseTile(text: string, audio: string, hasSilentE: boolean): ResponseTile {
    let responseTile: ResponseTile = {
      text: text,
      audio: audio,
      hasSilentE: hasSilentE,
    }
    return responseTile
  }

  // Set up audio for the speaker button
  playTargetAudioViaSpeakerClick() {
    this.dataTracker.requestSupport++;
    this.playWordAudio();
  }

  playWordAudio() {
    this.playTrialWordAudio()
    this.reusableTimer = window.setTimeout(() => {
      this.disableResponseButtons = this.isTargetAreaFull();
      this.disableAVButtons = false;
      this.changeDetector.markForCheck() ;
    }, this.interventionTaskService.letterAudioDelay);
  }

  // Set up audio for the speaker button
  playLetterAudio(audioFile: string) {
    this.dataTracker.requestSupport++;
    this.audioPlayerService.play(audioFile).subscribe();

    this.reusableTimer = window.setTimeout(() => {
      this.disableResponseButtons = this.isTargetAreaFull();
    }, this.interventionTaskService.letterAudioDelay);
  }

  // Set up audio for the speaker button
  playTargetLetterAudio(index: number) {
    this.dataTracker.requestSupport++;
    // TODO: forcing this as any, because right now resp is typed as just string, but it'll be
    //    an array here
    let response: any = this.trials[this.trialIndex].correct!.resp[index];
    this.audioPlayerService.play(response['@audio']).subscribe();

    this.reusableTimer = window.setTimeout(() => {
      this.disableResponseButtons = this.isTargetAreaFull();
    }, this.interventionTaskService.letterAudioDelay);
  }

  defaultAudioCompleteFunc() {
    this.hideSubmitButton = false;
    this.displayTask(this.trialIndex);
  }

  runFirstIncorrectSequence(trial: InterventionTrial) {
    // Disable buttons
    this.targetWord.forEach(function(element){
      element['disabled'] = true;
    });
    this.disableResponseButtons = true;
    this.disableSubmitButton = true;
    this.changeDetector.markForCheck() ;

    // Reset the target, create new responses, add support audio, play try again audio followed by target word audio
    this.reusableTimer = window.setTimeout(() => {
      this.removeIncorrectTiles(trial);
      this.blankTile = this.blankTileCheck();
      this.createResponseList(trial);
      this.oneIncorrectAnswer = true;
      this.showResponseAudioButtons = true;
      this.changeDetector.markForCheck() ;
      this.audioPlayerService.play('Audio/Help/help_tryagain.mp3').subscribe({
        complete: () => this.playWordAudio(),
        error: () => this.playWordAudio()
      });
      this.startTime = this.timerService.startTimer();
    }, this.interventionTaskService.firstIncorrectDelay);
  }

  runSecondIncorrectSequence(trial: InterventionTrial, isCorrect: boolean) {
    // Disable buttons and update trial counter
    this.targetWord.forEach(function(element){
      element['disabled'] = true;
    });
    this.disableResponseButtons = true;
    this.disableSubmitButton = true;
    this.taskService.answerTrial(isCorrect) ;

    let correctAnswerAudioCallback = () => {
      // Setting timeout function around displayCorrectTargetWord because otherwise the DOM is
      // not updating at the correct time, there is a slight pause before updating.
      window.setTimeout(() => { this.displayCorrectTargetWord(trial); }, 0);
      this.playWordAudio();
    }

    this.reusableTimer = window.setTimeout(() => {
      this.audioPlayerService.play('Audio/Help/help_correctansweris.mp3').subscribe({
        complete: () => correctAnswerAudioCallback(),
        error: () => correctAnswerAudioCallback()
      });
    }, this.interventionTaskService.secondIncorrectDelay);
  }

  // TODO: figure out correctResponse type
  initializeTargetWord(correctResponse: any) {
    this.targetWord = [];
    for (let i = 0; i < correctResponse.length; i++) {
      this.targetWord.push(this.createLetterSpaces("",true, false, null));
    }
  }

  // Displays the correct answer in the target area
  displayCorrectTargetWord(trial: InterventionTrial) {
    let correctResponse: any = trial.correct!.resp;
    this.initializeTargetWord(correctResponse);
    for(var i = 0; i < correctResponse.length; i++){
      if (correctResponse[i]['#text'].includes("+e")){
        this.showTargetSilentE = true;
        this.targetWord.push(this.createLetterSpaces("e",true, null, null));
        this.targetWord[i]['text'] = correctResponse[i]['#text'].replace("+e", "");
      }else{
        this.targetWord[i]['text'] = correctResponse[i]['#text'];
      }
      this.targetWord[i]['disabled'] = true;
    }

    this.changeDetector.markForCheck() ;
  }

  // Loop through the trial list and build the target word and responses for each item, and go to the next one after a click even updates the counter
  displayTask(newIndex: number) {
    this.incorrectFirstResponse = false;
    // TODO: pretty sure I can delete hideBottomResponses
    //this.hideBottomResponses = false;
    this.oneIncorrectAnswer = false;
    this.showResponseAudioButtons = false;
    this.showTargetSilentE = false;
    this.disableSubmitButton = true;
    this.trialIndex = newIndex;
    this.changeDetector.markForCheck() ;

    // Plays the trial instructional audio followed by playWordAudio() if on the first trial, otherwise just calls playWordAudio()
    if (this.trialIndex === 0){
      this.audioPlayerService.play(this.interventionTaskService.getTrialInstructionalAudio()).subscribe({
        complete: () => this.playWordAudio(),
      });
    } else {
      this.playWordAudio();
    }

    this.targetAnswer = '';
    this.responseOptions = [];

    // TODO: removed for loop and if block and just used this.trialIndex to get/set correct data
    // needs testing
    this.dataTracker = this.interventionTaskService.createTrialDataTrackerObject();

    let wordToSpell = this.trials[this.trialIndex].correct!.resp;
    this.initializeTargetWord(wordToSpell);

    this.blankTile = 0;
    this.createResponseList(this.trials[this.trialIndex]);

    this.originalStartTime = this.timerService.startTimer();
    this.startTime = this.originalStartTime;
    let timerBarTaskSettings = this.interventionTaskService.getTimerBarTaskSettings();
    if (timerBarTaskSettings.timerBarEnabled) {
      var initialDelay = this.interventionTaskService.trialBarBaseDelay + timerBarTaskSettings.timerBarDelay;
      this.trialTimerBar.startTrialTimer(timerBarTaskSettings.timerBarSpeed, initialDelay);
    }

    this.showResponseAudioButtons = this.interventionTaskService.hasInitialAudioSupport();
    this.changeDetector.markForCheck() ;
  }

  updateTargetTile(index: number, text: string, disabled: boolean, hasSilentE: boolean, targetIndex: number | null) {
    this.targetWord[index]['text'] = text;
    this.targetWord[index]['disabled'] = disabled;
    this.targetWord[index]['hasSilentE'] = hasSilentE;
    this.targetWord[index]['targetIndex'] = targetIndex;
    this.changeDetector.markForCheck() ;
  }

  // Function for what happens when user removes a target tile
  removeLetter(selectedResponse: number) {
    this.disableSubmitButton = true;
    let targetIndex = this.targetWord[selectedResponse]['targetIndex'] ?? 0;
    if (this.targetWord[selectedResponse]['hasSilentE'] || targetIndex !== selectedResponse) {
        // If the selected response has a silent e or
        //  is the silent e and needs to be popped.
        this.targetWord.pop();
        this.showTargetSilentE = false;
    }

    // Remove the data on this tile in the target word area
    this.updateTargetTile(targetIndex, "", true, false, null);

    this.blankTile = this.blankTileCheck();
    this.createResponseList(this.trials[this.trialIndex]);
  }

  // Function for what happens when user clicks on response tile
  addLetter(selectedResponse: number) {
    // If no blank tiles exist, ignore
    if (this.blankTile === NO_BLANK_TILE) {
      return;
    }

    // Put the data on this tile in the empty tile location in the target word area
    let responseOption = this.responseOptions[selectedResponse];
    this.updateTargetTile(this.blankTile, responseOption.text, false, responseOption.hasSilentE, this.blankTile);

    /*
        This is a small hack to determine if we need to append an e to the end of the target word because the user selected a silent e response
        Since we build a silentTiles list based on the responses, the indexes in silentTiles that are true are the response tiles that contain the silent e
        Knowing that, we can take a look at the silent tiles list at the index of the response the user selected (since the response tile has the same 'value' regard
        less of when we click it or parse it) and then if the value is true, just append an e to the target word
    */
    if (responseOption.hasSilentE === true) {
      // Add target letter of e to the end of the word, with the targetIndex of the
      // tile that the silent e is associated with.
      this.targetWord.push(this.createLetterSpaces("e",false, false, this.blankTile));
      this.showTargetSilentE = true;
    }

    this.blankTile = this.blankTileCheck();
    if (this.blankTile !== NO_BLANK_TILE){
      this.createResponseList(this.trials[this.trialIndex]);
    }
    else{
      this.disableSubmitButton = false;
    }

    this.changeDetector.markForCheck() ;
  }

  submitResponse() {
    // Save the student's response
    this.targetAnswer = this.trials[this.trialIndex].word['#text'];
    this.dataTracker.targetAnswer = this.targetAnswer;
    this.disableSubmitButton = true;
    this.disableAVButtons = true;
    let response = "";
    // To get integer representation of the student's answer
    let responseBinary = "";
    this.targetWord.forEach((element) => {
      response += element.text;
      element['disabled'] = true;
      responseBinary += element.text.charCodeAt(0);
    });

    let isCorrect = (this.targetAnswer === response);
    let runningPointsAnimation = this.trialTimerBar.sendResponseToTimerBar(isCorrect);
    let trialPoints = this.trialTimerBar.getPoints();
    this.interventionTaskService.playSoundEffect(isCorrect);
    this.interventionTaskService.recordResponseInTrialDataTrackerObject(this.dataTracker, response);

    // TODO: I don't think response times get calculated for this task, so need to check if that's intentional
    //  or not, for now, just putting zero for the response times
    let firstResponseTime = 0;
    let secondResponseTime = 0;
    if (isCorrect || !this.isUnit) {
      this.disableResponseButtons = true;
      // If the student missed their first try -- count the trial as incorrect
      let isTrialCorrect = isCorrect && !this.incorrectFirstResponse;
      this.interventionTaskService.trackResponseTrends(isTrialCorrect);
      let responseObject = this.interventionTaskService.createTrialResponseObject(isTrialCorrect, this.trialIndex,
          firstResponseTime, secondResponseTime, trialPoints, this.dataTracker, Number(responseBinary));
      if (isTrialCorrect) this.numberOfCorrectTrials++;

      this.trialList.push(responseObject);

      // Perform expected animations and move on to the next trial
      this.taskService.answerTrial(!this.incorrectFirstResponse) ;
      this.interventionTaskService.moveToNextTrial(responseObject, runningPointsAnimation).subscribe({
        complete: () => {
          this.updateTotalPoints(responseObject.points);
          this.afterUpdate();
        }
      });
    }
    else {
      // 2 incorrect responses
      if (this.oneIncorrectAnswer) {
        this.interventionTaskService.trackResponseTrends(isCorrect);
        let responseObject = this.interventionTaskService.createTrialResponseObject(isCorrect, this.trialIndex,
            firstResponseTime, secondResponseTime, trialPoints, this.dataTracker, Number(responseBinary));
        this.trialList.push(responseObject);

        this.runSecondIncorrectSequence(this.trials[this.trialIndex], isCorrect);

        window.setTimeout(() => {
          // Perform expected animations and move on to the next trial
          this.interventionTaskService.moveToNextTrial(responseObject, runningPointsAnimation).subscribe({
            complete: () => {
              this.afterUpdate();
              this.updateTotalPoints(responseObject.points);
            }
          });
        }, this.interventionTaskService.moveToNextTrialDelay);

      } else {
        // 1 incorrect response
        this.incorrectFirstResponse = true;
        this.runFirstIncorrectSequence(this.trials[this.trialIndex]);
      }
    }
  }

  blankTileCheck() {
    let blankTileIndex = NO_BLANK_TILE;
    for(let i = 0; i < this.targetWord.length; i++){
      if (this.targetWord[i]['text'] == ""){
        blankTileIndex = i;
        break;
      }
    }

    this.disableResponseButtons = (blankTileIndex === NO_BLANK_TILE);
    return blankTileIndex;
  }

  removeIncorrectTiles(trial: InterventionTrial) {
    // TODO: need to fix any here
    let correctResponseArray: any = trial.correct!.resp.slice();
    for(let i = 0; i < this.targetWord.length; i++) {
      // Continue if the letter is a silent e, continue because the
      // correctResonseArray won't line have the e in the array.
      if (this.targetWord[i].targetIndex !== i) {
        continue;
      }
      let correctIncludesSilentE = (correctResponseArray[i]['#text'].includes("+e"));
      let correctLetter = correctResponseArray[i]['#text'].replace("+e", "");

      if (correctLetter !== this.targetWord[i].text || correctIncludesSilentE !== this.targetWord[i].hasSilentE) {
        this.removeLetter(i);
      }
    }
  }

  createResponseList(trial: InterventionTrial) {
    // If no blank tiles exist, do not create a response list
    if (this.blankTile == NO_BLANK_TILE) {
      return;
    }

    this.responseOptions = [];

    // Create a shallow copy of the response list
    // TODO: need to update the type for resp-list so it can sometimes be an array,
    // Current I'm just forcing it into an array
    let trialResponseList: any[] = [trial['resp-list']];
    let responseList = trialResponseList[0][this.blankTile]!.resp.slice();

    // Reduce the number of responses, if needed
    responseList = this.interventionTaskService.reduceResponsesIfNecessary(responseList);

    // Shuffle responses
    responseList = this.interventionTaskService.shuffleResponses(responseList, true);

    // Build the response lists
    for (let i = 0; i < responseList.length; i++) {
      let responseText = responseList[i]['#text'];
      let responseAudio = responseList[i]['@audio']!;
      let foundSilentE = responseList[i]['#text'].includes('+');

      if (foundSilentE) {
          // Only display the text prior to the +e string.
          responseText = responseText.substring(0, responseList[i]['#text'].indexOf('+'));
      }

      this.responseOptions.push(this.createResponseTile(responseText, responseAudio, foundSilentE));
    }

    this.responseOptions1 = this.responseOptions.slice(0,4);
    this.responseOptions2 = this.responseOptions.slice(4,8);
    this.changeDetector.markForCheck() ;
  }

  isTargetAreaFull() {
    return (this.blankTile === NO_BLANK_TILE);
  }

  // Show the user the response they selected for one second before going to the next trial
  // Run through the task with the next trial in the curriculum
  afterUpdate() {
    this.reusableTimer = window.setTimeout(() => {
      this.trialTimerBar.resetTrialTimer();

      let newIndex = this.trialIndex + 1;
      if (newIndex < this.trials.length) {
        this.displayTask(newIndex);
      } else {
        this.saveTaskData();
      }
    }, this.interventionTaskService.getDelayAfterSingleResponse(this.trialList));
  }

  saveTaskData() {
    this.interventionTaskService.handleEndOfTaskProcess(this.trialList, this.taskTotalPoints,
        this.numberOfTrials, this.numberOfCorrectTrials, this.attempt)
    .pipe(
      mergeMap(() => {
        // TODO: should create type for task data params
        let params = this.interventionTaskService.getTaskDataParams();
        if (params.taskData.length) {
          return this.studentDataService.saveTrialData(params.taskData, !params.taskFinished)
        } else {
          return of({});
        }
      })
    ).subscribe({
      next: () => {
        this.saveDataDialog.hideSaveDataDialog();
        this.completeTask(this.attempt);
      },
      error:() => this.saveDataDialog.showSaveDataDialog()
    });
  }
}
