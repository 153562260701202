import { ChangeDetectorRef, Component, OnDestroy, ViewChild, AfterViewInit } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { DataTracker, InterventionTrialResponse } from '../core/models/student-data.model';
import { InterventionTask } from '../core/models/task.model';
import { AudioPlayerService } from '../core/services/audio-player.service';
import { InterventionTaskService } from '../core/services/intervention-task.service';
import { StudentDataService } from '../core/services/student-data.service';
import { TimerService } from '../core/services/timer.service';
import { InstructionsComponent } from '../shared/components/instructions/instructions.component';
import { SaveDataDialogComponent } from './save-data-dialog/save-data-dialog.component';
import { TaskBarComponent } from './task-bar/task-bar.component';
import { TrialCounterComponent } from './trial-counter/trial-counter.component';
import { TrialTimerBarComponent } from './trial-timer-bar/trial-timer-bar.component';
import { TryAgainDialogComponent } from './try-again-dialog/try-again-dialog.component';
import { FocusDialog } from '../shared/components/focus-dialog/focus-dialog.component';
import { ApplicationStateService } from '../core/services/application-state.service';

@Component({
  template: '',
})
export abstract class InterventionTaskComponent implements OnDestroy {
  // ViewChild setup
  @ViewChild(TrialCounterComponent)
  trialCounter!: TrialCounterComponent;

  @ViewChild(SaveDataDialogComponent)
  saveDataDialog!: SaveDataDialogComponent;

  @ViewChild(TaskBarComponent)
  taskBar!: TaskBarComponent;

  @ViewChild(InstructionsComponent)
  instructions!: InstructionsComponent;

  @ViewChild(TryAgainDialogComponent)
  tryAgainDialog!: TryAgainDialogComponent;

  @ViewChild(TrialTimerBarComponent)
  trialTimerBar!: TrialTimerBarComponent;

  @ViewChild(FocusDialog) focusDialog!: FocusDialog;
  needsFocus: boolean = false;

  task!: InterventionTask;
  alreadyCompleted!: boolean;

  // timers
  hideTimer: boolean = true;
  startTaskTimer: number | null = null;
  intervalTimer: number | null = null;
  reusableTimer: number | null = null;
  displayTimer: number | null = null;
  responseTimeout: number | null = null;

  // List to hold the response after each trial which will then be sent to backend for saving in db
  trialList: InterventionTrialResponse[] = [];
  dataTracker!: DataTracker;

  // Initialize total points for this task
  taskTotalPoints: number = 0;

  // Scope variable to do the toggling of the next button highlighting
  toggleNextButton: boolean = false;
  toggleAgainButton: boolean = false;

  // Disable speaker and next buttons while instructional audio is playing
  disableNextButton: boolean = true;
  disableAVButtons: boolean = true;
  hideNextButton: boolean = false;
  hideAgainButton: boolean = true;
  unmaskedTask: boolean;

  // task view variables
  prevTaskAnimationComplete: boolean = false;
  taskBackgroundImage: string;
  testType: string;
  taskContainerColor: string;

  // Flag to determine if we should play instructional audio or not, we don't want it to play after instructional video
  playInstructionalAudio: boolean = false;
  animationComplete: boolean = false;

  // Disable response buttons until trial is started
  disableResponseButtons: boolean = true;
  disableAgainButton: boolean = true;
  hideResponses: boolean = true;
  showResponseAudioButtons: boolean = false;

  // Keep track of which trial we are on
  trialIndex: number = 0;
  numberOfTrials: number;
  currentDestination: number = 0;

  // Define the masker and focuser variables that will be shown during the trials
  focuser: string = '______';
  masker: string = '########';

  // Flag to tell me if the focuser was already displayed for the trial. Will need to get reset to false on each trial
  focuserDisplayed: boolean = false;

  // Let me know if the trial is masked or not
  trialMasked: boolean = false;

  // Flag to know if the word has already been shown to the user once, so they can't make it display more than once
  wordSeen: boolean = false;

  wordListAttempt: number = 0;
  attempt: number = 0;

  // Instructional audio files
  instructionalMaskedAudioFile: string | null;
  instructionalVideoFile: string;

  readyToHighlight = false;

  // response times
  endTime!: number;
  startTime!: number;

  // TODO: could make a type for this
  timerBarTaskSettings: any;

  // router state params
  stateParams: any | null;
  isUnit: boolean = false;

  constructor(
    public studentDataService: StudentDataService,
    public interventionTaskService: InterventionTaskService,
    public timerService: TimerService,
    public audioPlayerService: AudioPlayerService,
    public router: Router,
    public changeDetector?: ChangeDetectorRef,
    public applicationStateService?: ApplicationStateService,
  ) {
    this.task = <InterventionTask>this.studentDataService.startSelectedTask();
    // Check if student has already completed this task before
    this.alreadyCompleted = this.studentDataService.isInterventionTaskComplete(this.studentDataService.getCurrentDestination(), this.task);
    this.currentDestination = this.studentDataService.getCurrentDestination();
    this.unmaskedTask = !this.task.masked ;
    this.instructionalVideoFile = environment.VideoAssetServerURL + '/assets/video/' + (this.task.video) + '.mp4';
    this.instructionalMaskedAudioFile = this.interventionTaskService.getTrialMaskedAudio(this.task);
    this.trialMasked = (this.instructionalMaskedAudioFile !== null);
    this.taskBackgroundImage = this.interventionTaskService.taskBackgroundImage();
    this.testType = this.interventionTaskService.testType();
    this.taskContainerColor = this.interventionTaskService.taskContainerColor();
    this.isUnit = this.studentDataService.isInterventionUnit();
    // NOTE: some intervention tasks (passages) don't have trials, so check before setting
    this.numberOfTrials = this.task.trial ? this.task.trial.length : 0;
    // Get the stateParams from the router if they exist
    this.stateParams = this.router.getCurrentNavigation()?.extras.state || null;
    this.wordListAttempt = this.stateParams ? this.stateParams.wordListAttempt : 1;
    this.attempt = this.stateParams ? this.stateParams.attempt : 1;
    // Check if we should play the instructional audio
    let isReadAndListenTask = this.task.id.startsWith('PASS');
    if (this.wordListAttempt !== 2 && !isReadAndListenTask || (isReadAndListenTask && !this.alreadyCompleted)) {
      this.playInstructionalAudio = true;
    }
    this.needsFocus = this.router.getCurrentNavigation()?.extras.state?.needsFocus;
  }

  // cancel any leftover timers
  ngOnDestroy(): void {
    if (this.intervalTimer !== null) {
      window.clearTimeout(this.intervalTimer);
    }
    if (this.startTaskTimer !== null) {
      window.clearTimeout(this.startTaskTimer);
    }
    if (this.reusableTimer !== null) {
      window.clearTimeout(this.reusableTimer);
    }
    if (this.displayTimer !== null) {
      window.clearTimeout(this.displayTimer);
    }
    if (this.responseTimeout !== null) {
      window.clearTimeout(this.responseTimeout);
    }
  }

  // TODO: this is the same as playTrialWordAudio
  repeatWord(): void {
    this.audioPlayerService.play(this.task.trial[this.trialIndex].word['@audio']).subscribe({
      error(event) {
        console.error('Error playing audio: ', event);
      }
    });
  }

  replayVideo(): void {
    this.instructions.replayVideo();
  }

  playTrialWordAudio(): void {
    this.audioPlayerService.play(this.task.trial[this.trialIndex].word['@audio']).subscribe({
      error(event) {
        console.error('Error playing audio: ', event);
      }
    });
  }

  // Update the total points
  updateTotalPoints(points: number) {
    this.taskTotalPoints += points;
  }

  pulseNextTrialButton() {
    if (!this.disableNextButton) {
      this.toggleNextButton = !this.toggleNextButton;
      this.changeDetector?.markForCheck() ;
    }
  }

  // Creates the interval that pulses the 'Next Trial' button
  createInterval() {
    this.intervalTimer = window.setInterval(() => {
      this.pulseNextTrialButton();
    }, 1000, 0);
  }

  stopInterval(): void {
    if (this.intervalTimer !== null) {
      clearTimeout(this.intervalTimer);
    }
  }

  // Retry or correctly navigate to the next task
  completeTask(attempt?: number): void {

    this.checkForPoorStudentUsage();

    // If next task is set, start that task
    if (this.interventionTaskService.getNextTask() !== null) {
      // Play transition audio and start the task over with a new wordlist
      let url = this.router.url;
      this.audioPlayerService.play('Audio/Help/transition_newwordlist.mp3').subscribe({
        complete: () => {
          this.router.routeReuseStrategy.shouldReuseRoute = () => false ;
          this.router.onSameUrlNavigation = 'reload' ;
          // Make sure to display the focus dialog in whatever intervention task after the reload,
          // needsFocus needs to be passed because the reload reinitializes it to false
          this.router.navigateByUrl(url, {state: {wordListAttempt: 2, attempt: attempt, needsFocus: this.needsFocus}}) ;
        }, error: () => {
          this.router.routeReuseStrategy.shouldReuseRoute = () => false ;
          this.router.onSameUrlNavigation = 'reload' ;
          this.router.navigateByUrl(url, {state: {wordListAttempt: 2, attempt: attempt, needsFocus: this.needsFocus}}) ;
        }
      });
    } else if (this.interventionTaskService.getShowTryAgainDialogFlag()) {
      this.tryAgainDialog.showTryAgainDialog(this.needsFocus);
    } else {
      if (this.interventionTaskService.getParentTaskId()) {
        // If just finished a sub-task -- go back to subtask select
        this.router.navigateByUrl('subtaskSelect', {
          state: {
            backFromTask: true,
            mainTaskId: this.interventionTaskService.getParentTaskId(),
          }
        }) ;
      }
      else {
        // Redirect back to the task selection screen and remove the task that was just completed
        let navigationExtras = {
          state:
          {
            canGoToTaskSelect: true,
            lastTaskCompletedID: this.task.id,
            alreadyCompleted: this.alreadyCompleted,
            // To display the focus dialog after redirected to task select, needsFocus needs to be passed because
            // task select is not inheriting intevention task
            needsFocus: this.needsFocus,
          }
        }
        this.router.navigateByUrl('/taskSelect', navigationExtras);
      }
    }
  }

  // Detects student poor usage or disengagement through tracking their behavior responding to tasks
  checkForPoorStudentUsage() {
    let takingLongerTime = false;
    let repeatingResponses = false;

    // Dont bother for demo users
    if (this.studentDataService.isDemoUser()) return ;

    // Determine the percentage of trials we got correct, only report poor usage if < 50% correct and other conditions hold
    let percentCorrect = 0 ;
    if (this.trialList.length)
    {
      percentCorrect = ((this.trialList.filter((trial) => trial.isCorrect)).length) / (this.trialList.length) ;
    }

    // Check if the student has taken longer than needed to complete the task
    let taskCompletionTime = this.timerService.computeTime(this.startTime, new Date().getTime());
    let timeThreshold = this.task.trial.length * environment.trialSecondsLimit * 1000;
    if (taskCompletionTime >= timeThreshold) {
      takingLongerTime = true;
    }

    // Check if they selected same response position
    let positions = this.trialList.map(trial => trial.responsePosition);
    if (positions.length > 0) {
      repeatingResponses = positions.every((position, i, positions) => position === positions[0]);
    }

    if ((takingLongerTime || repeatingResponses) && percentCorrect < 0.5) {
      this.needsFocus = true;
      this.interventionTaskService.sendTeacherNotification().subscribe({
        next: () => {
        }, error: (e) => {
          console.log('error sending teacher notification. It might be because of the current logged in user does not have a record in Student table');
        }
      });
    }
  }
}
