import { InterventionTaskTheme, TaskTheme } from './theme.model';

export class SubtaskSelectButton {
  public buttonImage: string = '' ;
  public icon: string = '' ;
  public showPassageTitle: boolean ;
  public secondaryTaskType: string = '' ;
  public secondaryTaskName: string = '' ;

  constructor(
    public isMain: boolean,
    public type: string,
    public name: string,
    public passageTitle: string | null,
    public score: number,
    public visible: boolean,
    public disabled: boolean,
    public completed: boolean,
    private levelProperties: InterventionTaskTheme | TaskTheme | undefined,
  ) {
    this.icon = `/assets/images/destination/iconPassageTask_${this.type}.svg` ;
    this.showPassageTitle = (this.passageTitle !== null && this.passageTitle.trim() !== '') ;

    if (this.disabled)
    {
      this.buttonImage = levelProperties!.subtaskSelectBtnGrey ;
    }
    else if (this.completed)
    {
      this.buttonImage = levelProperties!.subtaskSelectBtnComplete ;
    }
    else
    {
      this.buttonImage = levelProperties!.subtaskSelectBtnUp ;
    }

    // If this is not the Main task on subtask select, we format the display of the task type/name differently
    // something like "Find the picture" becomes "FIND THE...\nPicture"
    if (!this.showPassageTitle)
    {
      const taskNameArray = this.name.split(' ') ;
      this.secondaryTaskType = `${taskNameArray[0]} ${taskNameArray[1]}...` ;
      this.secondaryTaskName = taskNameArray[2] ;
    }
  }

  displayScore(): string {
    return this.score + '%' ;
  }

  shouldDisplayScore(): boolean {
    return this.score >= 0 ;
  }
}
