import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit, NgZone } from '@angular/core';
import { InterventionTaskComponent } from '../intervention-task.component';
import { StudentDataService } from '../../core/services/student-data.service';
import { InterventionTaskService } from '../../core/services/intervention-task.service';
import { ShuffleService } from '../../core/services/shuffle.service';
import { TimerService } from '../../core/services/timer.service';
import { AudioPlayerService } from '../../core/services/audio-player.service';
import { Router } from '@angular/router';
import { InterventionTrial } from '../../core/models/task.model';
import { ResponseOption, TargetSyllable } from './find-the-syllables-intervention.model';
import { Observable, of, Subscription } from 'rxjs';
import { concatMap, first, map, mergeMap } from "rxjs/operators";
import { TaskService } from '../../core/services/task.service';

/**
 * NOTE: The decision to move to an OnPush was made after the AngularJS -> Angular IO (v13) upgrade when there
 *     : were some noticeable performance issues in certain tasks on the Capacitor iOS build. Much of the change
 *     : detection feels like a bit of a code smell, or rather is revealing that with more time we might have
 *     : been able to focus on the upgrade more as a refactor to create more components for parts of these Tasks.
 *     : By passing much of the template controlling variables off as @Inputs to new components, Angular would
 *     : have monitored those changes for us automatically (even with OnPush), that would have removed the need for
 *     : much of the manual markForCheck calls that we have to put in place when we are changing the template variables
 *     : ourselves here. In a future implementation, it would be worthwhile to look to transition parts of these
 *     : Tasks into more modular, reusable components that will alleviate our change detection calls.
 */

@Component({
  selector: 'app-find-the-syllables-intervention',
  templateUrl: './find-the-syllables-intervention.component.html',
  styleUrls: ['./find-the-syllables-intervention.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FindTheSyllablesInterventionComponent extends InterventionTaskComponent implements OnInit, OnDestroy, AfterViewInit {
  private trials: InterventionTrial[] = this.task.trial ;
  private numberOfAttemptsForTrial: number = 0 ;
  private numberOfCorrectTrials: number = 0 ;
  private firstResponseTime: number = 0 ;
  private secondResponseTime: number = 0 ;
  private audioPlayerSubscription: Subscription = new Subscription() ;
  private moveToNextTrialSubscription: Subscription = new Subscription() ;
  private saveTaskDataSubscription: Subscription = new Subscription() ;
  private targetMaskedAgain: boolean = false ;
  private internalMaskTime: number = 0 ;

  auditoryTask: boolean = false ;
  firstResponseIncorrect: boolean = false ;
  responseOptions: ResponseOption[] = []
  shouldMask: boolean = false ;
  showSupportAudioAndHighlights: boolean = false ;
  targetWord: any ;
  targetSyllables: TargetSyllable[] = [] ;

  constructor(
    public studentDataService: StudentDataService,
    public interventionTaskService: InterventionTaskService,
    public shuffleService: ShuffleService,
    public timerService: TimerService,
    public audioPlayerService: AudioPlayerService,
    public taskService: TaskService,
    public router: Router,
    public changeDetector: ChangeDetectorRef,
    private zone: NgZone,
  ) {
    super(studentDataService, interventionTaskService, timerService, audioPlayerService, router, changeDetector) ;

    this.auditoryTask = (this.task.id.indexOf('A') > 0) ;
  }

  ngOnInit(): void {
    // Check to shuffle trials
    if (this.task.randomTrials) {
      this.trials = this.shuffleService.shuffleArray(this.trials);
    }
    // Get starting points for the total points cloud
    this.taskTotalPoints = this.interventionTaskService.getStartingPoints(this.task.id, this.currentDestination, this.wordListAttempt);
  }

  ngOnDestroy() {
    super.ngOnDestroy();

    this.audioPlayerSubscription.unsubscribe() ;
    this.saveTaskDataSubscription.unsubscribe() ;
    this.moveToNextTrialSubscription.unsubscribe() ;
  }

  ngAfterViewInit() {
    // After view is initialized wait for task animation to complete and then initialize everything else
    this.taskBar.taskAnimationComplete.pipe(first())
      .subscribe(() => {
        // set this to tell the trial-counter that animation is complete
        this.animationComplete = true;
        this.interventionTaskService.initTaskContainerElements(this.task, this.alreadyCompleted, this.wordListAttempt, this.attempt)
          .pipe(first(),
            map(() => {
              let timerBarSettings = this.interventionTaskService.getTimerBarTaskSettings();
              // TODO: could probably find a better way to do this
              timerBarSettings.timerBarEnabled ? this.trialTimerBar.showTimerBar() : this.trialTimerBar.hideTimerBar();
              this.hideTimer = !timerBarSettings.timerBarEnabled
            }),
            concatMap(() => {
              if (!this.studentDataService.hasCompletedAtLeastOneTaskLikeThis(this.task.id) && this.interventionTaskService.getPlayVideoFlag()) {
                this.playInstructionalAudio = false;
                return this.instructions.playInstructionalVideo();
              }
              else {
                return of({});
              }
            }),
            concatMap(() => {
              if (this.playInstructionalAudio) {
                return this.audioPlayerService.play(this.interventionTaskService.getInstructionalAudioFile());
              } else {
                return of({});
              }
            }),
            /**
             * Bug #2622 indicates that the masked audio should not be played before the task, and indeed in the
             * legacy app, either masked or unmasked, Find the Number of Syllables never plays the masked audion.
             * The legacy codebase indicates the masked audtion should play if a masked audio file is set and the
             * $scope.playInstructionalAudio is true. From what I can tell, $scope.playInstructionalAudio is
             * undefined so the audio never plays. Because in the masked or unmasked variant, the masked audio
             * never plays, I am commenting out this code. MFS, BNI (08.11.21)
            concatMap(() => {
              if (this.instructionalMaskedAudioFile !== null && this.instructionalMaskedAudioFile !== '' && this.playInstructionalAudio) {
                return this.audioPlayerService.play(this.instructionalMaskedAudioFile);
              } else {
                return of({});
              }
            })
            */
          )
          .subscribe({
            complete: () => {
              this.buildResponseList() ;
              if (this.auditoryTask || this.unmaskedTask)
              {
                this.displayTrial() ;
              }
              else
              {
                this.disableNextButton = false ;
                this.disableAgainButton = true ;
                this.hideAgainButton = true ;
                this.disableResponseButtons = true ;
                // Enable video replay button while waiting for student to start the next trial
                this.disableAVButtons = false;
                this.changeDetector.markForCheck() ;
                this.createInterval() ;
              }
            }
          });
      });

    // Display the focus dialog if needs focus is set (from intervention task)
    if(this.needsFocus){
      this.focusDialog.showDialog();
    }
  }

  addHoverClass = function(event: Event) {
    (event.target as Element).classList.add('hover');
  }

  displayTrial() {
    this.stopInterval() ;
    this.removeResponseHighlighting() ;
    this.firstResponseIncorrect = false ;
    this.numberOfAttemptsForTrial = 0 ;
    this.shouldMask = false ;
    this.disableNextButton = true ;
    this.hideAgainButton = true ;
    this.disableAgainButton = false ;
    this.targetWord = '______' ;
    this.internalMaskTime = this.task.maskerTime! ;
    // If we have extra support and have gotten two in a row incorrect, our maskertime is extended for the whole task
    if (!this.unmaskedTask && this.interventionTaskService.hasTwoIncorrectInSequence && this.studentDataService.getStudentData().extraSupportNeeded)
    {
      this.internalMaskTime += this.interventionTaskService.maskerSupportExtend ;
    }

    this.changeDetector.markForCheck() ;

    this.reusableTimer = window.setTimeout(() => {
      // Play the instructional audio if this is our first trial, then proceed with our callback (enables buttons and such)
      if (this.trialIndex === 0 && !this.interventionTaskService.getTrialInstructionalAudio().endsWith("silence.mp3"))
      {
        this.audioPlayerSubscription.unsubscribe() ;
        this.audioPlayerSubscription = this.audioPlayerService.play(this.interventionTaskService.getTrialInstructionalAudio()).subscribe({
          complete: () => (this.auditoryTask) ? this.auditoryTrialInstructionalCallback() : this.displayMasker(),
          error: () => (this.auditoryTask) ? this.auditoryTrialInstructionalCallback() : this.displayMasker(),
        }) ;
      }
      else
      {
        (this.auditoryTask) ? this.auditoryTrialInstructionalCallback() : this.displayMasker() ;
      }
    }, this.task.focuserTime) ;
  }

  displayTrialAgain() {
    // Remove any left over highlighting from the previous trial
    this.removeResponseHighlighting() ;
    this.dataTracker.redisplayMaskedWord++ ;
    this.targetMaskedAgain = true ;
    this.shouldMask = false ;
    this.hideAgainButton = true ;
    this.disableAgainButton = true ;
    this.targetWord = '______' ;
    this.internalMaskTime = this.task.maskerTime! + 60 ;

    this.reusableTimer = window.setTimeout(() => {
      // Play the instructional audio if this is our first trial, then proceed with our callback (enables buttons and such)
      if (this.trialIndex === 0)
      {
        this.audioPlayerSubscription.unsubscribe() ;
        this.audioPlayerSubscription = this.audioPlayerService.play(this.interventionTaskService.getTrialInstructionalAudio()).subscribe({
          complete: () => (this.auditoryTask) ? this.auditoryTrialInstructionalCallback() : this.displayMasker(),
          error: () => (this.auditoryTask) ? this.auditoryTrialInstructionalCallback() : this.displayMasker(),
        }) ;
      }
      else
      {
        (this.auditoryTask) ? this.auditoryTrialInstructionalCallback() : this.displayMasker() ;
      }
    }, this.task.focuserTime) ;
  }

  getButtonColor() {
    return this.interventionTaskService.buttonColor() ;
  }

  getHighlightColor() {
    return this.interventionTaskService.highlightColor() ;
  }

  getTaskBackgroundImage() {
    return this.interventionTaskService.taskBackgroundImage() ;
  }

  getTaskBarColor() {
    return this.interventionTaskService.taskBarColor() ;
  }

  getTaskContainerColor() {
    return this.interventionTaskService.taskContainerColor() ;
  }

  getTestType() {
    return this.interventionTaskService.testType() ;
  }

  isSpeakerVisible() {
    if (this.isUnit)
    {
      if (this.showSupportAudioAndHighlights) return true ;
      else return (this.task.wordlistType === 'easy' && this.unmaskedTask) ;
    }
    else
    {
      return this.auditoryTask;
    }
  }

  playTargetAudioViaSpeakerClick() {
    this.dataTracker.requestSupport++ ;
    this.playWordAudio().subscribe() ;
  }

  removeHoverClass = function(event: Event) {
    (event.target as Element).classList.remove('hover');
  }

  requestSyllableHelp(index: number) {
    // Called by student selecting the audio button
    this.dataTracker.requestSupport++ ;
    this.playSyllableAudio(index).subscribe() ;
  }

  saveTaskData() {
    this.saveTaskDataSubscription = this.interventionTaskService.handleEndOfTaskProcess(
      this.trialList,
      this.taskTotalPoints,
      this.numberOfTrials,
      this.numberOfCorrectTrials,
      this.attempt
    ).pipe(
        mergeMap(() => {
          let params = this.interventionTaskService.getTaskDataParams();
          if (params.taskData.length) {
            return this.studentDataService.saveTrialData(params.taskData, !params.taskFinished)
          } else {
            return of({});
          }
        })
    ).subscribe({
      next: () => {
        this.saveDataDialog.hideSaveDataDialog();
        this.completeTask(this.attempt);
        this.saveTaskDataSubscription.unsubscribe();
      },
      error:() => {
        this.saveDataDialog.showSaveDataDialog();
        this.saveTaskDataSubscription.unsubscribe();
      }
    });
  }

  submitResponse(selectedResponse: ResponseOption) {
    this.disableNextButton = true ;
    this.disableAgainButton = true ;
    this.hideAgainButton = true ;
    this.disableResponseButtons = true ;
    this.disableAVButtons = true ;
    this.numberOfAttemptsForTrial++ ;
    this.targetMaskedAgain = false ;

    // Stop timer after the student selects a response
    this.endTime = this.timerService.stopTimer() ;
    if (this.numberOfAttemptsForTrial === 1)
    {
      this.firstResponseTime = this.timerService.computeTime(this.startTime, this.endTime) || 0 ;
      this.secondResponseTime = 0 ;
    }
    else
    {
      this.secondResponseTime = this.timerService.computeTime(this.startTime, this.endTime) || 0 ;
    }

    // Record the student's response
    selectedResponse.highlight = true ;
    let isCorrect = selectedResponse.isCorrect ;
    let runningPointsAnimation = this.trialTimerBar.sendResponseToTimerBar(isCorrect) ;
    let trialPoints = this.trialTimerBar.getPoints();
    this.interventionTaskService.playSoundEffect(isCorrect) ;
    this.interventionTaskService.recordResponseInTrialDataTrackerObject(this.dataTracker, selectedResponse.syllables.toString()) ;
    this.changeDetector.markForCheck() ;

    if (isCorrect || !this.isUnit)
    {
      // If the response is correct or the student is taking the pre/post test version of this task
      let isTrialCorrect = isCorrect && (this.numberOfAttemptsForTrial === 1) ;
      let responseObject = this.interventionTaskService.createTrialResponseObject(
        isTrialCorrect,
        this.trialIndex,
        this.firstResponseTime,
        this.secondResponseTime,
        trialPoints,
        this.dataTracker,
        selectedResponse.syllables
      );

      if (isTrialCorrect) this.numberOfCorrectTrials++ ;
      this.trialList.push(responseObject);
      this.interventionTaskService.trackResponseTrends(isTrialCorrect);

      // Perform expected animations and move on to the next trial
      this.taskService.answerTrial(isTrialCorrect) ;
      this.moveToNextTrialSubscription = this.interventionTaskService.moveToNextTrial(responseObject, runningPointsAnimation).subscribe({
        complete: () => {
          this.moveToNextTrialSubscription.unsubscribe() ;
          this.updateTotalPoints(responseObject.points);
          this.endOfTrialCallback();
        }
      });
    }
    else if (this.numberOfAttemptsForTrial === 1)
    {
      // One incorrect response
      this.firstResponseIncorrect = true ;
      this.shouldMask = false ;
      this.showSupportAudioAndHighlights = true ;
      this.reusableTimer = window.setTimeout(() => {
        this.audioPlayerSubscription.unsubscribe() ;
        this.audioPlayerSubscription = this.audioPlayerService.play('Audio/Help/help_tryagain.mp3').subscribe({
          complete: () => this.firstResponseIncorrectSequence(),
          error: () => this.firstResponseIncorrectSequence()
        }) ;
      }, this.interventionTaskService.firstIncorrectDelay) ;
    }
    else
    {
      // Two incorrect responses
      let responseObject = this.interventionTaskService.createTrialResponseObject(
        isCorrect,
        this.trialIndex,
        this.firstResponseTime,
        this.secondResponseTime,
        trialPoints,
        this.dataTracker,
        selectedResponse.syllables
      );

      this.trialList.push(responseObject) ;
      this.interventionTaskService.trackResponseTrends(isCorrect);
      this.taskService.answerTrial(isCorrect) ;
      this.reusableTimer = window.setTimeout(() => {
        this.audioPlayerSubscription.unsubscribe() ;
        this.audioPlayerSubscription = this.audioPlayerService.play('Audio/Help/help_correctansweris.mp3').subscribe({
          complete: () => this.secondResponseIncorrectSequence(),
          error: () => this.secondResponseIncorrectSequence(),
        })
      }, this.interventionTaskService.secondIncorrectDelay) ;
    }
  }

  private auditoryTrialInstructionalCallback() {
    this.playWordAudio().subscribe({
      complete: () => this.startTaskCallback()
    }) ;
    this.targetWord = this.trials[this.trialIndex].word['#text'] ;
  }

  private buildResponseList() {
    this.dataTracker = this.interventionTaskService.createTrialDataTrackerObject();

    let correctResponse = this.trials[this.trialIndex].correct!.resp ;
    this.responseOptions = [] ;
    for (let cnt = 1 ; cnt < 5 ; cnt++)
    {
      this.responseOptions.push(new ResponseOption(cnt, (cnt.toString() === correctResponse), false)) ;
      if (cnt.toString() === correctResponse)
      {
        this.dataTracker.targetAnswer = cnt.toString() ;
      }
    }
  }

  private buildTarget() {
    if (this.isUnit)
    {
      this.targetSyllables = [] ;

      let syllableList = this.trials[this.trialIndex]['syllable-list']!['syllable'] ;
      if (parseInt(this.trials[this.trialIndex].sylls!) > 1)
      {
        for (let syllIdx = 0 ; syllIdx < syllableList.length ; syllIdx++)
        {
          this.targetSyllables.push(new TargetSyllable(syllIdx, syllableList[syllIdx]["#text"], syllableList[syllIdx]["@audio"], false)) ;
        }
      }
      else
      {
        // FIXME: The legacy implementation relied on the syllables list still, however when a single syllable is present it
        //      : went from an array to an object -- I think this will cause issues, but here I instead rely on the word
        this.targetSyllables.push(new TargetSyllable(0, this.trials[this.trialIndex].word['#text'], this.trials[this.trialIndex].word['@audio'], false)) ;
      }
    }
    else
    {
      this.targetWord = this.trials[this.trialIndex].word ;
    }
  }

  // Builds the target word and displays the masker over it if necessary. Enables everything needed to begin the trial.
  private displayMasker() {
    // Play the word audio if on an 'easy' wordlist
    if (this.task.wordlistType === 'easy')
    {
      this.playWordAudio().subscribe() ;
    }

    this.reusableTimer = window.setTimeout(() => {
      this.buildTarget() ;
      this.disableAVButtons = false ;
      this.changeDetector.detectChanges() ;

      if (this.internalMaskTime)
      {
        this.reusableTimer = window.setTimeout(() => {
          this.shouldMask = true ;
          this.disableResponseButtons = false ;

          if (this.studentDataService.isInterventionPreTest() || this.studentDataService.isInterventionPostTest())
          {
            this.hideAgainButton = true ;
            this.disableAgainButton = true ;
          }
          else
          {
            if (!this.targetMaskedAgain)
            {
              this.hideAgainButton = false ;
              this.disableAgainButton = false ;
            }
          }

          this.changeDetector.detectChanges() ;
          this.startTime = this.timerService.startTimer() ;
          this.startTrialTimer() ;
        }, this.internalMaskTime) ;
      }
      else
      {
        this.disableResponseButtons = false ;
        this.changeDetector.markForCheck() ;
        this.startTime = this.timerService.startTimer() ;
        this.startTrialTimer() ;
      }
    }, 0) ;
  }

  private enableButtonsAndStartTimer() {
    this.reusableTimer = window.setTimeout(() => {
      this.removeResponseHighlighting() ;
      this.removeUnderlines() ;
      this.disableResponseButtons = false ;
      this.disableAVButtons = false ;
      this.changeDetector.markForCheck() ;
      this.startTime = this.timerService.startTimer() ;
    }, 0) ;
  }

  private endOfTrialCallback() {
    this.reusableTimer = window.setTimeout(() => {
      this.showSupportAudioAndHighlights = false ;
      this.trialTimerBar.resetTrialTimer() ;

      if (this.trialIndex + 1 < this.trials.length)
      {
        this.trialIndex++ ;
        this.buildResponseList() ;

        this.disableNextButton = false ;
        this.hideNextButton = false ;
        this.disableAgainButton = true ;
        this.hideAgainButton = true ;
        this.shouldMask = false;
        this.targetWord = '' ;
        this.targetSyllables = [] ;

        if (this.auditoryTask || this.unmaskedTask)
        {
          this.displayTrial() ;
        }
        else
        {
          // Enable video replay button while waiting for student to start the next trial
          this.disableAVButtons = false;
          this.createInterval() ;
        }
      }
      else
      {
        this.reusableTimer = window.setTimeout(() => {
          this.hideNextButton = true ;
          this.hideAgainButton = true ;
          this.saveTaskData() ;
        }, 750); // Just needs a little time to finish trial counter animation
      }
    }, this.interventionTaskService.getDelayAfterSingleResponse(this.trialList, [250, 1000])) ;
  }

  // OPTIMIZE: This could be better implemented I think now with Observables to essentially play the word, then pipe
  //         : the other play methods (of the syllables) together, returning of({}) if no audio is necessary, all then
  //         : subscribing with a single 'complete' that calls the enableButtonsAndStartTimer method
  private firstResponseIncorrectSequence() {
    // Play the target word audio followed by the audio for each syllable
    this.playWordAudio().pipe(
      concatMap(() => {
        // If we have more than 1 syllable, play the first
        if (this.targetSyllables.length > 1) return this.playSyllableAudio(0) ;
        else return of({}) ;
      }),
      concatMap(() => {
        // If we have more than 2 syllables, play the second
        if (this.targetSyllables.length >= 2) return this.playSyllableAudio(1) ;
        else return of({}) ;
      }),
      concatMap(() => {
        // If we have more than 3 syllables, play the third
        if (this.targetSyllables.length >= 3) return this.playSyllableAudio(2) ;
        else return of({}) ;
      }),
      concatMap(() => {
        // If we have more than 4 syllables, play the fourth
        if (this.targetSyllables.length >= 4) return this.playSyllableAudio(3) ;
        else return of({}) ;
      })
    ).subscribe({
      complete: () => this.enableButtonsAndStartTimer(),
      error: (err) => {
        console.log(err) ;
        this.enableButtonsAndStartTimer() ;
      }
    }) ;
  }

  private highlightCorrectResponse() {
    this.responseOptions.forEach((resp) => {
      resp.highlight = resp.isCorrect ;
    }) ;
    this.changeDetector.markForCheck() ;
  }

  private playCorrectResponseAudio() {
    let correctResponse = parseInt((this.trials[this.trialIndex].correct!.resp as string)) ;
    let stringNum = '' ;
    if (correctResponse === 1) stringNum = 'one' ;
    else if (correctResponse === 2) stringNum = 'two' ;
    else if (correctResponse === 3) stringNum = 'three' ;
    else stringNum = 'four' ;

    return this.audioPlayerService.play(`Audio/Help/help_${stringNum}.mp3`) ;
  }

  private playSyllableAudio(index: number): Observable<any> {
    return new Observable((subscriber) => {
      this.removeUnderlines() ;
      this.targetSyllables[index].underline = true ;
      this.changeDetector.detectChanges() ;
      subscriber.next() ;
      subscriber.complete() ;
    }).pipe(concatMap(() => {
      return this.audioPlayerService.play(this.targetSyllables[index].audio) ;
    }));
  }

  private playWordAudio(): Observable<any> {
    return this.audioPlayerService.play(this.trials[this.trialIndex].word['@audio']) ;
  }

  private removeResponseHighlighting() {
    this.responseOptions.forEach((resp) => {
      resp.highlight = false ;
    })
  }

  private removeUnderlines() {
    this.targetSyllables.forEach((syllable: TargetSyllable) => {
      syllable.underline = false ;
    }) ;
  }

  private secondResponseIncorrectSequence() {
    // Play the 'correct number of syllables' at the same time as highlighting the correct response
    this.playCorrectResponseAudio().subscribe() ;
    this.highlightCorrectResponse() ;

    this.reusableTimer = window.setTimeout(() => {
      //this.highlightCorrectResponse() ;
      let responseObject = this.trialList[this.trialList.length - 1] ;

      this.moveToNextTrialSubscription = this.interventionTaskService.moveToNextTrial(responseObject, false).subscribe({
        complete: () => {
          this.moveToNextTrialSubscription.unsubscribe() ;
          this.updateTotalPoints(responseObject.points) ;
          this.endOfTrialCallback() ;
        }
      }) ;
    }, this.interventionTaskService.moveToNextTrialDelay)
  }

  private startTaskCallback() {
    // These changes were not being detected on iOS so we are explicitly telling
    // this timeout to run inside of the angular zone. Before using zone.run(), there
    // would be a noticeable lag between when the word audio would play and when the
    // target word and response buttons would be enabled
    this.zone.run(() => {
      this.reusableTimer = window.setTimeout(() => {
        this.disableAVButtons = false ;
        this.disableResponseButtons = false ;
        this.changeDetector.markForCheck() ;
        this.startTime = this.timerService.startTimer() ;
      }) ;
    });
  }

  private startTrialTimer() {
    let timerBarTaskSettings = this.interventionTaskService.getTimerBarTaskSettings() ;
    if (timerBarTaskSettings.timerBarEnabled)
    {
      let initialDelay = this.interventionTaskService.trialBarBaseDelay + timerBarTaskSettings.timerBarDelay ;
      this.trialTimerBar.startTrialTimer(timerBarTaskSettings.timerBarSpeed, initialDelay) ;
    }
  }
}
