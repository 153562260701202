import { AfterViewInit, ChangeDetectorRef, Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { InterventionTaskComponent } from '../intervention-task.component';
import { StudentDataService } from '../../core/services/student-data.service';
import { InterventionTaskService } from '../../core/services/intervention-task.service';
import { ShuffleService } from '../../core/services/shuffle.service';
import { TimerService } from '../../core/services/timer.service';
import { AudioPlayerService } from '../../core/services/audio-player.service';
import { TaskService } from '../../core/services/task.service';
import { Router } from '@angular/router';
import { concatMap, first, map, mergeMap } from 'rxjs/operators';
import { of } from 'rxjs';
import { ApplicationStateService } from '../../core/services/application-state.service';
import { DomSanitizer, SafeResourceUrl, SafeUrl } from '@angular/platform-browser';
import { InterventionTrial, Response, Tile } from 'src/app/core/models/task.model';
import { environment } from 'src/environments/environment';

interface Word {
    word: string;
    punct?: string;
}

@Component({
    selector: 'read-the-sentence-intervention',
    templateUrl: './read-the-sentence-intervention.component.html',
})
export class ReadTheSentenceInterventionComponent extends InterventionTaskComponent implements OnInit, AfterViewInit {
    @ViewChild('player') player!: ElementRef;
    @ViewChild('progress') progress!: ElementRef;

    submitting: boolean = false;
    aiVendor: string = 'deepgram';
    status: string = '';
    sentenceType: string = '';
    transcription: string = '';
    confidence: number = 0.0;
    words: any[] = [];
    mediaRecorder: any;
    chunks: BlobPart[] = [];
    recordedAudio: Blob = new Blob();
    isRecording: boolean = false;
    isPlayingRecording: boolean = false;
    audioRecordingURL: SafeResourceUrl = '';

    trials: InterventionTrial[] = this.task.trial;
    numberOfCorrectTrials: number = 0;
    audioBlob: Blob | null = null;
    firstResponseTime: number = 0;
    secondResponseTime: number = 0;
    incorrectFirstResponse: boolean = false;
    originalStartTime: number = 0;
    durationDisplay: string = '0:00';

    trialRecordingStartTime: number = 0;
    trialRecordingEndTime: number = 0;
    currentTargetSentence: Word[] = [];
    transcriptionCorrectness: string[] = [];

    // debugging flags
    showDebug: boolean = false;

    // support flags
    extraSupportNeeded: boolean = false;

    constructor(
        public studentDataService: StudentDataService,
        public interventionTaskService: InterventionTaskService,
        public shuffleService: ShuffleService,
        public timerService: TimerService,
        public audioPlayerService: AudioPlayerService,
        public taskService: TaskService,
        public router: Router,
        public changeDetector: ChangeDetectorRef,
        public applicationStateService: ApplicationStateService,
        private sanitizer: DomSanitizer,
    ) {
        super(studentDataService, interventionTaskService, timerService, audioPlayerService, router, changeDetector, applicationStateService);
    }

    ngOnInit(): void {
        this.currentDestination = this.studentDataService.getCurrentDestination();
        this.extraSupportNeeded = this.studentDataService.getStudentData().extraSupportNeeded;
        this.disableAVButtons = true;

        const MIN_DECIBELS = -55; // minimum decibels to consider as sound activity
        const SILENCE_THRESHOLD = 2000; // stop recording after 2 second of silence

        let soundDetected = false;
        let lastSoundTime = Date.now();
        let recording = false;
        let detectSound: FrameRequestCallback;

        // Shuffle the trials if randomTrials is set
        if (this.task.randomTrials) {
            this.trials = this.shuffleService.shuffleArray(this.trials);
        }

        navigator.mediaDevices.getUserMedia({ audio: true })
            .then(stream => {
                this.mediaRecorder = new MediaRecorder(stream);

                const audioContext = new AudioContext();
                const audioStreamSource = audioContext.createMediaStreamSource(stream);
                const analyser = audioContext.createAnalyser();
                analyser.minDecibels = MIN_DECIBELS;
                audioStreamSource.connect(analyser);

                const bufferLength = analyser.frequencyBinCount;
                const domainData = new Uint8Array(bufferLength);

                detectSound = () => {
                    if (!recording) {
                        return;
                    }

                    analyser.getByteFrequencyData(domainData);

                    soundDetected = false;
                    for (let i = 0; i < bufferLength; i++) {
                        if (domainData[i] > 0) {
                            soundDetected = true;
                            break;
                        }
                    }

                    if (soundDetected) {
                        lastSoundTime = Date.now();
                    } else if (Date.now() - lastSoundTime > SILENCE_THRESHOLD) {
                        console.log('Silence detected');
                        this.stopRecording();
                        return;
                    }

                    window.requestAnimationFrame(detectSound);
                };

                this.mediaRecorder.ondataavailable = (e: any) => {
                    console.log('Data available:', e.data);
                    this.chunks.push(e.data);
                };

                this.mediaRecorder.onstart = () => {
                    this.trialRecordingStartTime = this.timerService.startTimer();
                    recording = true;
                    lastSoundTime = Date.now(); // set lastSoundTime when the recording starts
                    window.requestAnimationFrame(detectSound);
                }

                this.mediaRecorder.onstop = () => {
                    recording = false;
                    this.trialRecordingEndTime = this.timerService.stopTimer();
                    console.log('Recording stopped. Chunks:', this.chunks);
                    this.recordedAudio = new Blob(this.chunks, { type: 'audio/ogg; codecs=opus' });
                    this.chunks = [];
                    let audioURL = this.sanitizer.bypassSecurityTrustResourceUrl(window.URL.createObjectURL(this.recordedAudio));
                    console.log('Blob URL:', audioURL);
                    this.audioRecordingURL = audioURL;
                    this.player.nativeElement.src = this.audioRecordingURL;

                    // update the duration display
                    this.updateDurationDisplay();
                };
            })
            .catch(err => console.log('Error instantiating media recorder:', err));
    }

    ngAfterViewInit() {
        // After view is initialized wait for task animation to complete and then initialize everything else
        this.taskBar.taskAnimationComplete.pipe(first())
            .subscribe(() => {
                // set this to tell the trial-counter that animation is complete
                this.animationComplete = true;
                this.interventionTaskService.initTaskContainerElements(this.task, this.alreadyCompleted, this.wordListAttempt, this.attempt)
                    .pipe(first(),
                        map(() => {
                            let timerBarSettings = this.interventionTaskService.getTimerBarTaskSettings();
                            // TODO: OPTIMIZE could probably find a better way to set this.hideTimer and handle timerBarSettings
                            timerBarSettings.timerBarEnabled ? this.trialTimerBar.showTimerBar() : this.trialTimerBar.hideTimerBar();
                            this.hideTimer = !timerBarSettings.timerBarEnabled
                        }),
                        concatMap(() => {
                            if (!this.studentDataService.hasCompletedAtLeastOneTaskLikeThis(this.task.id) && this.interventionTaskService.getPlayVideoFlag()) {
                                this.playInstructionalAudio = false;
                                return this.instructions.playInstructionalVideo();
                            }
                            else {
                                return of({});
                            }
                        }),
                        concatMap(() => {
                            if (this.playInstructionalAudio) {
                                return this.audioPlayerService.play(this.interventionTaskService.getInstructionalAudioFile());
                            } else {
                                return of({});
                            }
                        }),
                    )
                    .subscribe({
                        complete: () => this.instructionsCompleted(),
                        error: (err) => console.log(err),
                    });
            });

        if (this.player && this.progress) {
            this.player.nativeElement.addEventListener('timeupdate', () => {
                const progressPercent = this.player.nativeElement.currentTime / this.player.nativeElement.duration;
                this.progress.nativeElement.style.width = `${progressPercent * 100}%`;
            });

            this.player.nativeElement.addEventListener('ended', () => {
                this.isPlayingRecording = false;
            });

            // listen for loading of the metadata to update the duration
            this.player.nativeElement.onloadedmetadata = () => {
                this.updateDurationDisplay();
            };

            this.progress.nativeElement.parentElement.addEventListener('click', (event: MouseEvent) => {
                const rect = this.progress.nativeElement.parentElement.getBoundingClientRect();
                const clickX = event.pageX - rect.left;
                const progressPercent = clickX / this.progress.nativeElement.parentElement.offsetWidth;
                this.player.nativeElement.currentTime = progressPercent * this.player.nativeElement.duration;
            });
        }

        // Display the focus dialog if needs focus is set (from intervention task)
        if (this.needsFocus) {
            this.focusDialog.showDialog();
        }
    }

    togglePlay() {
        if (this.player.nativeElement.paused) {
            this.player.nativeElement.play();
            this.isPlayingRecording = true;
        } else {
            this.player.nativeElement.pause();
            this.isPlayingRecording = false;
        }
    }

    saveTaskData() {
        this.interventionTaskService.handleEndOfTaskProcess(this.trialList, this.taskTotalPoints, this.numberOfTrials, this.numberOfCorrectTrials, this.attempt)
            .pipe(
                mergeMap(() => {
                    let params = this.interventionTaskService.getTaskDataParams();
                    if (params.taskData.length) {
                        return this.studentDataService.saveTrialData(params.taskData, !params.taskFinished)
                    } else {
                        return of({});
                    }
                })
            ).subscribe({
                next: () => {
                    this.saveDataDialog.hideSaveDataDialog();
                    this.completeTask(this.attempt);
                },
                error: () => this.saveDataDialog.showSaveDataDialog()
            });
    }

    instructionsCompleted() {
        console.debug('instructions completed, building task');
        this.displayWordTarget(0);
    }

    displayWordTarget(newIndex: number) {
        console.debug('displaying sentence target...');
        this.trialIndex = newIndex;
        console.debug('trial info:', this.trials[this.trialIndex]);
        
        const currentTrialSentence = this.trials[this.trialIndex].sentence['#text']
        this.currentTargetSentence = currentTrialSentence.split(' ').map((word: string) => {
            // check if the word has punctuation
            const lastChar = word[word.length - 1];
            const hasPunctuation = lastChar.match(/[.,!?]/);
            return {
                word: hasPunctuation ? word.slice(0, -1) : word,
                punct: hasPunctuation ? lastChar : '',
            };
        });
        console.debug('current target sentence:', this.currentTargetSentence);
        this.transcriptionCorrectness = new Array(this.currentTargetSentence.length).fill('');

        // set the sentence type of the trial for styling
        this.sentenceType = 'sentence-type-' + (this.trials[this.trialIndex].sentenceType || 'sentence').toLowerCase();
        const audio = this.trials[this.trialIndex].sentence['@audio'];
        // create a new data tracker object
        this.dataTracker = this.interventionTaskService.createTrialDataTrackerObject();

        // Initial audio given for "easy" wordlists or if the student needs extra support
        if (this.task.wordlistType === 'easy' || this.extraSupportNeeded) {
            
            this.audioPlayerService.play(audio).subscribe({
                complete: () => this.trialLoopAudioComplete(),
            });
        } else {
            this.trialLoopAudioComplete();
        }

        this.changeDetector.markForCheck();
    }

    trialLoopAudioComplete() {
        // Allow user to submit a response to the trial
        this.reusableTimer = window.setTimeout(() => {
            this.disableAVButtons = false;
            this.changeDetector.markForCheck();
        }, 0);

        this.originalStartTime = this.timerService.startTimer();
        this.startTime = this.timerService.startTimer();
        let timerBarTaskSettings = this.interventionTaskService.getTimerBarTaskSettings();
        if (timerBarTaskSettings.timerBarEnabled) {
            let initialDelay = this.interventionTaskService.trialBarBaseDelay + timerBarTaskSettings.timerBarDelay;
            this.trialTimerBar.startTrialTimer(timerBarTaskSettings.timerBarSpeed, initialDelay);
        }
    }

    startRecording() {
        if (this.isRecording) {
            this.stopRecording();
        } else {
            console.debug('starting recording...');
            this.mediaRecorder.start();
            this.isRecording = true;
        }
    }

    stopRecording() {
        console.debug('stopping recording...');
        this.mediaRecorder.stop();
        this.isRecording = false;
        this.progress.nativeElement.style.width = '0%';
    }

    submitResponse() {
        console.debug('submitting response...');
        this.uploadAudioRecording();

        if (this.trialIndex < this.task.trial.length - 1) {
            this.displayWordTarget(this.trialIndex);
        } else {
            console.debug('end of trial list');
        }
    }

    async uploadAudioRecording() {
        console.debug('Uploading audio recording trial:', this.trialIndex);
        const formData = new FormData();
        formData.append('file', this.recordedAudio, 'audio.wav');
        formData.append('vendor', this.aiVendor);

        this.submitting = true;
        this.interventionTaskService.uploadAudioTrialSubmission(formData).subscribe({
            next: (response: any) => {

                this.transcription = response.results.channels[0].alternatives[0].transcript;
                this.confidence = response.results.channels[0].alternatives[0].confidence;
                this.words = response.results.channels[0].alternatives[0].words;

                // this.status = (this.words.length === 1 && this.words[0].word === this.targetWord) ? 'Correct' : 'Incorrect';

                this.endTime = this.timerService.stopTimer();
                if (this.incorrectFirstResponse) {
                    this.secondResponseTime = this.timerService.computeTime(this.startTime, this.endTime) || 0;
                }
                else {
                    this.firstResponseTime = this.timerService.computeTime(this.startTime, this.endTime) || 0;
                    this.secondResponseTime = 0;
                }

                this.dataTracker.targetAnswer = this.trials[this.trialIndex].sentence['#text'];
                const trimmedTranscription = this.transcription.split(' ').map((word: string) => word.toLowerCase());
                const targetSentenceNoPunct = this.trials[this.trialIndex].sentence['#text'].replace(/[.,\/#!$%\^&\*;:{}=\-_`~()]/g, "");
                // Removing punctuation that way can create extra spaces, so we split and filter out empty strings
                const targetSentenceTrimmed = targetSentenceNoPunct.replace(/\s{2,}/g, " ").toLowerCase().split(' ');

                console.debug('transcription:', trimmedTranscription);
                console.debug('target:', targetSentenceTrimmed);

                const sentenceConfidence = this.words.reduce((acc: number, word: any) => acc + word.confidence, 0) / this.words.length;
                // loop through the words and check if any one word is under 50% confidence.
                const isLowConfidence = this.words.some((word: any) => word.confidence < 0.50);
                const responseMatchesTarget = trimmedTranscription.join(' ') === targetSentenceTrimmed.join(' ');

                // Response considered CORRECT if:
                // 1. The transcription matches the target sentence
                // 2. The confidence is above 70%
                // 3. There are individual words with confidence rating below 50%
                const isCorrect = responseMatchesTarget && sentenceConfidence > 0.70 && !isLowConfidence;

                // make an array of boolean values for each word in the sentence, true if the word is correct
                this.transcriptionCorrectness = targetSentenceTrimmed.map((word: string, index: number) => {
                    return word === trimmedTranscription[index] ? 'correct' : 'incorrect';
                });

                // play the response audio
                let runningPointsAnimation = this.trialTimerBar.sendResponseToTimerBar(isCorrect);
                let trialPoints = this.trialTimerBar.getPoints();
                this.interventionTaskService.playSoundEffect(isCorrect);
                this.interventionTaskService.recordResponseInTrialDataTrackerObject(this.dataTracker, this.transcription);

                if (isCorrect) { // Correct response

                    // If the student missed their first try -- count the trial as incorrect
                    let isTrialCorrect = isCorrect && !this.incorrectFirstResponse;
                    this.interventionTaskService.trackResponseTrends(isTrialCorrect);
                    let responseObject = this.interventionTaskService.createTrialResponseObject(isTrialCorrect, this.trialIndex,
                        this.firstResponseTime, this.secondResponseTime, trialPoints, this.dataTracker);
                    if (isTrialCorrect) this.numberOfCorrectTrials++;

                    this.trialList.push(responseObject);

                    // Perform expected animations and move on to the next trial
                    this.taskService.answerTrial(isTrialCorrect);
                    this.interventionTaskService.moveToNextTrial(responseObject, runningPointsAnimation).subscribe({
                        complete: () => {
                            this.updateTotalPoints(responseObject.points);
                            this.afterUpdate();
                        }
                    });
                } else if (!this.incorrectFirstResponse) { // Incorrect response
                    this.incorrectFirstResponse = true;

                    // 1 incorrect response
                    this.reusableTimer = window.setTimeout(() => {

                        this.changeDetector.markForCheck();

                        // Play "Please try again..." and then play the word audio
                        this.reusableTimer = window.setTimeout(() => {
                            this.audioPlayerService.play('Audio/Help/help_tryagain.mp3').subscribe({
                                complete: () => this.firstResponseIncorrectSequence(),
                                error: () => this.firstResponseIncorrectSequence()
                            });
                        }, this.interventionTaskService.floatUpAnimationDelay);
                    }, this.interventionTaskService.firstIncorrectDelay);

                    this.originalStartTime = this.timerService.startTimer();
                }
                else
                {
                  // Second incorrect
                  this.interventionTaskService.trackResponseTrends(isCorrect);
                  let responseObject = this.interventionTaskService.createTrialResponseObject(isCorrect, this.trialIndex, this.firstResponseTime, this.secondResponseTime, trialPoints, this.dataTracker);
                  this.trialList.push(responseObject);
                  this.taskService.answerTrial(isCorrect);
                  this.reusableTimer = window.setTimeout(() => {
                    this.audioPlayerService.play('Audio/Help/help_correctansweris.mp3').subscribe({
                      complete: () => this.secondResponseIncorrectSequence(),
                      error: () => this.secondResponseIncorrectSequence()
                    });
                  }, this.interventionTaskService.secondIncorrectDelay);
                }
                this.audioRecordingURL = '';
                this.submitting = false;
            },
            error: (error) => {
                this.submitting = false;
                console.error('Upload error:', error);
            }
        });
    }

    afterUpdate() {
        this.reusableTimer = window.setTimeout(() => {
            this.trialTimerBar.resetTrialTimer();

            let newIndex = this.trialIndex + 1;
            if (newIndex < this.trials.length) {
                this.trialIndex = newIndex;
                this.incorrectFirstResponse = false;
                this.durationDisplay = '0:00';
                this.displayWordTarget(newIndex);
            } else {
                this.saveTaskData();
            }
        }, this.interventionTaskService.getDelayAfterSingleResponse(this.trialList));
    }

    playTargetAudioViaSpeakerClick() {
        this.dataTracker.requestSupport++;
        this.audioPlayerService.play(this.trials[this.trialIndex].sentence['@audio']).subscribe();
    }

    firstResponseIncorrectSequence() {
        let audio = this.trials[this.trialIndex].sentence['@audio'];

        // Set up styling for the user's next response, change 'correct' entries to '' and 'incorrect' to 'caution'.
        this.transcriptionCorrectness = this.transcriptionCorrectness.map((word: string) => word === 'correct' ? '' : 'caution');
        this.changeDetector.markForCheck();

        this.audioPlayerService.play(audio).subscribe({
            complete: () => {
                this.showResponseAudioButtons = true;
                this.disableAVButtons = false;
                // Sometimes angular won't update until another event occurs if we don't force it here
                window.setTimeout(() => {
                    this.changeDetector.markForCheck();
                }, 0);
            }
        });
    }

  secondResponseIncorrectSequence() {
    this.disableResponseButtons = true;
    this.changeDetector.markForCheck() ;

    let audio = this.trials[this.trialIndex].sentence['@audio'];
    this.audioPlayerService.play(audio).subscribe();
    this.reusableTimer = window.setTimeout(() => {
      let responseObject = this.trialList[this.trialList.length - 1];
      this.updateTotalPoints(responseObject.points);
      this.afterUpdate();
      // }, this.interventionTaskService.moveToNextTrialDelay);
      // Testing a shorter delay before moving on, 3.5 seconds seems long for the RTS/RTW tasks
    }, 1500);
  }

    formatTime(seconds: number): string {
        if (!isFinite(seconds)) {
            return '0:00';
        }

        const minutes = Math.floor(seconds / 60);
        seconds = seconds % 60;
        return `${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
    }

    updateDurationDisplay(): void {
        this.durationDisplay = this.trialRecordingStartTime === 0 ? '0:00' : this.formatTime(Math.floor((this.trialRecordingEndTime - this.trialRecordingStartTime) / 1000));
        this.changeDetector.markForCheck();
    }

    get currentTime(): string {
        return this.formatTime(this.player ? Math.floor(this.player.nativeElement.currentTime) : 0);
    }

    get isRecordingDisabled(): boolean {
        return this.disableAVButtons || this.isPlayingRecording;
    }

    get isSubmitDisabled(): boolean {
        return this.isRecording || !this.audioRecordingURL || this.submitting || this.disableAVButtons;
    }

    get isPlayDisabled(): boolean {
        return this.isRecording || !this.audioRecordingURL || this.submitting || this.disableAVButtons;
    }

    get isAudioSupportEnabled(): boolean {
        return this.extraSupportNeeded || this.incorrectFirstResponse || this.task.wordlistType === 'easy';
    }

    // TODO: The mediaRecorder may need to be stopped when the component is destroyed, but this causes an error
    //   super.ngOnDestroy();
    //   if (this.mediaRecorder) {
    //     this.mediaRecorder.stop();
    //   }
    //   if (this.player) {
    //     this.player.nativeElement.onloadedmetadata = null;
    //   }
    // }
}
