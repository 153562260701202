import {AfterViewInit, Component, OnInit, ChangeDetectionStrategy, ChangeDetectorRef} from '@angular/core';
import { Router } from '@angular/router';
import { of } from 'rxjs';
import { concatMap, first } from 'rxjs/operators';
import { DiagnosticTrial } from 'src/app/core/models/task.model';
import { AssetPreloaderService } from 'src/app/core/services/asset-preloader.service';
import { AudioPlayerService } from 'src/app/core/services/audio-player.service';
import { ApplicationStateService } from 'src/app/core/services/application-state.service';
import { ShuffleService } from 'src/app/core/services/shuffle.service';
import { StudentDataService } from 'src/app/core/services/student-data.service';
import { TimerService } from 'src/app/core/services/timer.service';
import { DiagnosticTaskComponent } from '../diagnostic-task.component';
import { TaskService } from '../../core/services/task.service';

@Component({
  selector: 'app-fill-in-blank',
  templateUrl: './fill-in-blank.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FillInBlankComponent extends DiagnosticTaskComponent implements OnInit, AfterViewInit {
  trials: DiagnosticTrial[] = this.task.trial;

  // Task specific variables
  letter: string = '';
  response: string = '';
  showTargetSilentE: boolean = false;
  addEToEnd: boolean = false;
  targetWord: string[] = [];
  responseOptions: string[] = [];
  responseOptions1: string[] = [];
  responseOptions2: string[] = [];
  responseOptionsSilentE: boolean[] = [];
  silentTiles: boolean[] = [];
  blankTile: number = 0;

  constructor(
    public router: Router,
    public applicationStateService: ApplicationStateService,
    public studentDataService: StudentDataService,
    public audioPlayerService: AudioPlayerService,
    public timerService: TimerService,
    public shuffleService: ShuffleService,
    public assetPreloaderService: AssetPreloaderService,
    public taskService: TaskService,
    public changeDetector: ChangeDetectorRef,
  ) {
    super(router, applicationStateService, studentDataService, audioPlayerService,
      timerService, shuffleService, assetPreloaderService);}

  ngOnInit(): void {
    // If random trials is set to true on the task object, shuffle the trials
    if (this.task.randomTrials) {
      this.trials = this.shuffleService.shuffleArray(this.trials);
    }
  }

  ngAfterViewInit(): void {
    // After view is initialized wait for task animation to complete and then initialize everything else
    this.taskBar.taskAnimationComplete.pipe(first()).subscribe((complete) => {
      if (complete) {
        // set this to tell the trial-counter that animation is complete
        this.animationComplete = true;
        this.displayTaskContainerElements().pipe(first(),
          concatMap(() => {
            if (!this.studentDataService.isDemoUser() && !this.studentDataService.hasCompletedAtLeastOneTaskLikeThis(this.task.id)) {
              this.playInstructionalAudio = false;
              return this.instructions.playInstructionalVideo();
            }
            else {
              return of({});
            }
          }),
          concatMap(() => {
            if (this.playInstructionalAudio) {
              return this.audioPlayerService.play(this.instructionalAudioFile);
            } else {
              return of({});
            }
          })
        )
        .subscribe({
          complete: () => this.defaultAudioCompleteFunc(),
        });
      }
    });
  }

  // Set up audio for the speaker button
  playTrialWordAudio() {
    this.playWordAudio();
    this.reusableTimer = window.setTimeout(() => {
      this.disableResponseButtons = false;
      this.disableAVButtons = false;
      this.changeDetector.markForCheck() ;
    }, 750);
  }

  defaultAudioCompleteFunc() {
    this.displayTask(this.trialIndex);
  }

  // Loop through the trial list and build the target word and responses for each item, and go to the next one after a click even updates the counter
  displayTask(newIndex: number) {
    this.showTargetSilentE = false;

    this.trialIndex = newIndex;

    this.playTrialWordAudio();

    // Flag to let me know if I need to add an e to the end of the target word because of the silent e or not
    this.addEToEnd = false;
    this.targetWord = [];
    this.responseOptions = [];
    this.responseOptionsSilentE = [];

    for (let trial in this.trials) {

      // Shuffle the response list if the randomize flag is set
      if (this.trials[trial]['resp-list']['@randomResponses']) {
        this.shuffleService.shuffleArray(this.trials[trial]['resp-list'].resp);
      }

      if (parseInt(trial) === this.trialIndex) {
        for (let character in this.trials[trial].display.tile) {
          // If a tile in the target word contains the silent e syntax, remove the +e and append that to the end, but keep the letter(s) before the +e
          if (this.trials[trial].display.tile[character].includes('+')) {
            this.targetWord.push(this.trials[trial].display.tile[character].substring(0, this.trials[trial].display.tile[character].indexOf('+')));
            this.addEToEnd = true;
          }
          else {
            this.targetWord.push(this.trials[trial].display.tile[character]);
          }
        }
        if (this.addEToEnd) {
          this.targetWord.push('e');
        }

        // Keep track of which tiles have the silent e display
        this.silentTiles = [];

        // Build the response lists
        for (let responses in this.trials[trial]['resp-list'].resp) {
          if (this.trials[trial]['resp-list'].resp[responses]['#text'].includes('+')) {
            this.silentTiles[responses] = true;
            this.responseOptions.push(this.trials[trial]['resp-list'].resp[responses]['#text'].substring(0, this.trials[trial]['resp-list'].resp[responses]['#text'].indexOf('+')));
            this.responseOptionsSilentE[responses] = true;
          }
          else {
            this.silentTiles[responses] = false;
            this.responseOptionsSilentE[responses] = false;
            this.responseOptions.push(this.trials[trial]['resp-list'].resp[responses]['#text']);
          }
        }
        this.responseOptions1 = this.responseOptions.slice(0,4);
        this.responseOptions2 = this.responseOptions.slice(4,8);
        // Figure out the blank tile position and make it blank in the target word
        this.blankTile = parseInt(this.trials[trial].blankTilePos!) -1;

        // Need to do this splicing to insert the blank tile position where it is needed because the way the tiles are populated would leave us one tile
        // short because it does not account for the blank tile in the length of the target word
        this.targetWord.splice(this.blankTile, 0, ' ');
        this.startTime = this.timerService.startTimer();
        break;
      }
    }
    this.changeDetector.markForCheck() ;
  }

  // Function for what happens when user clicks on response tile
  submitAnswer(selectedResponse: number) {
    this.showTargetSilentE = false;

    // Stop timer after the student selects a response
    this.endTime = this.timerService.stopTimer();
    this.disableResponseButtons = true;
    this.disableAVButtons = true;

    // Play neutral audio sound after each response tile click
    this.audioPlayerService.play('Audio/Help/SNDneutral.mp3').subscribe({
      error(event) {
        console.error('Error playing audio: ', event);
      }
    });

    // Put the data on this tile in the empty tile location in the target word area
    this.targetWord[this.blankTile] = this.responseOptions[selectedResponse];

    /*
        This is a small hack to determine if we need to append an e to the end of the target word because the user selected a silent e response
        Since we build a silentTiles list based on the responses, the indexes in silentTiles that are true are the response tiles that contain the silent e
        Knowing that, we can take a look at the silent tiles list at the index of the response the user selected (since the response tile has the same 'value' regard
        less of when we click it or parse it) and then if the value is true, just append an e to the target word
    */
    if (this.silentTiles[selectedResponse] === true) {
      this.targetWord.push('e');
      this.showTargetSilentE = true;
    }
    let selectedResponseText: string;
    // If the user selects a response with a silent e, we want to send the '+e' back as well so we know if they were right or wrong
    if (this.responseOptionsSilentE[selectedResponse]) {
      selectedResponseText = this.responseOptions[selectedResponse] + '+e' || "";
    }
    else {
      selectedResponseText = this.responseOptions[selectedResponse] || "";
    }
    let responsePosition = selectedResponse + 1 || 0;
    let responseType = this.trials[this.trialIndex]['resp-list'].resp[selectedResponse]['@type'] || "";
    let target = this.trials[this.trialIndex].word['#text'] || "";
    let response = this.submitResponse(selectedResponseText, null, target, responsePosition, responseType)
    // Show the user the response they selected for one second before going to the next trial
    // Run through the task with the next trial in the curriculum
    this.taskService.answerTrial(response.correct) ;
    this.reusableTimer = window.setTimeout(() => {
      let newIndex = this.trialIndex + 1;
      if (newIndex < this.trials.length) {
        this.displayTask(newIndex);
      } else {
        let currentDestination = this.studentDataService.getCurrentDestination();

        // Keep track of total points locally
        this.studentDataService.setTotalPoints(currentDestination, this.totalTaskPoints, this.trials.length, this.numberOfCorrectTrials);
        this.saveTrialData();
      }
    }, 1000);
  }
}
