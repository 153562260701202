import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { of } from 'rxjs';
import { concatMap, first, map, mergeMap } from 'rxjs/operators';
import { InterventionTrial, Response, Tile } from 'src/app/core/models/task.model';
import { AudioPlayerService } from 'src/app/core/services/audio-player.service';
import { InterventionTaskService } from 'src/app/core/services/intervention-task.service';
import { ShuffleService } from 'src/app/core/services/shuffle.service';
import { StudentDataService } from 'src/app/core/services/student-data.service';
import { TimerService } from 'src/app/core/services/timer.service';
import { InterventionTaskComponent } from '../intervention-task.component';
import { ResponseOption } from './change-the-word-intervention.model';
import { TaskService } from '../../core/services/task.service';

@Component({
  selector: 'change-the-word-intervention',
  templateUrl: './change-the-word-intervention.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChangeTheWordInterventionComponent extends InterventionTaskComponent implements OnInit, AfterViewInit {

  trials: InterventionTrial[] = this.task.trial;
  numberOfCorrectTrials: number = 0;
  numberOfAttemptsForTrial: number = 0;
  correctResponse!: ResponseOption;
  currentDestination: number = 0;
  changeAudio: string = '';
  toMakeAudio: string = '';
  showResponseAudioButtons: boolean = false;
  showTargetAudioSupportButtons: boolean = false;
  blankTileContainsSilentE: boolean = false;
  // Toggles the highlighting on the blank target tile
  highlightTargetTile: boolean = false;

  // Trial specific display data
  blankTile: number = 0;
  originalStartTime: number = 0;
  targetWord: string[] = [];
  showTargetSilentE: boolean = false;
  targetSilentEIndex: number = 0;
  firstResponseTime: number = 0;
  secondResponseTime: number = 0;

  responseOptions!: ResponseOption[];
  responseOptions1!: ResponseOption[];
  responseOptions2!: ResponseOption[];

  constructor(
    public studentDataService: StudentDataService,
    public interventionTaskService: InterventionTaskService,
    public shuffleService: ShuffleService,
    public timerService: TimerService,
    public audioPlayerService: AudioPlayerService,
    public taskService: TaskService,
    public router: Router,
    public changeDetector: ChangeDetectorRef,
  ) {
    super(studentDataService, interventionTaskService, timerService, audioPlayerService, router, changeDetector);
  }

  ngOnInit(): void {
    // Check to shuffle trials
    if (this.task.randomTrials) {
      this.trials = this.shuffleService.shuffleArray(this.trials);
    }
    this.currentDestination = this.studentDataService.getCurrentDestination();
    this.changeAudio = 'Audio/Help/' + this.task.trialaudiochange + '.mp3';
    this.toMakeAudio = 'Audio/Help/' + this.task.trialaudiotomake + '.mp3';
    // Get starting points for the total points cloud
    this.taskTotalPoints = this.interventionTaskService.getStartingPoints(this.task.id, this.currentDestination, this.wordListAttempt);
  }

  ngAfterViewInit() {
    // After view is initialized wait for task animation to complete and then initialize everything else
    this.taskBar.taskAnimationComplete.pipe(first())
    .subscribe(() => {
      // set this to tell the trial-counter that animation is complete
      this.animationComplete = true;
      this.interventionTaskService.initTaskContainerElements(this.task, this.alreadyCompleted, this.wordListAttempt, this.attempt)
        .pipe(first(),
          map(() => {
            // As specified in Feature 4226, the timer bar should always be removed from any Change the Word tasks
            this.hideTimer = true ;
            this.trialTimerBar.hideTimerBar() ;
          }),
          concatMap(() => {
            if (!this.studentDataService.hasCompletedAtLeastOneTaskLikeThis(this.task.id) && this.interventionTaskService.getPlayVideoFlag()) {
              this.playInstructionalAudio = false;
              return this.instructions.playInstructionalVideo();
            }
            else {
              return of({});
            }
          }),
          concatMap(() => {
            if (this.playInstructionalAudio) {
              return this.audioPlayerService.play(this.interventionTaskService.getInstructionalAudioFile());
            } else {
              return of({});
            }
          }),
        )
        .subscribe({
          complete: () => this.defaultAudioCompleteFunc(),
        });
    });

    // Display the focus dialog if needs focus is set (from intevention task)
    if(this.needsFocus){
      this.focusDialog.showDialog();
    }
  }

  // TODO: this will probably get wrapped into the complete after audioPlay subscription
  enableResponsesCallback() {
    this.reusableTimer = window.setTimeout(() => {
      this.disableResponseButtons = false;
    }, 0);
  }

  playTargetAudioViaSpeakerClick() {
    this.dataTracker.requestSupport++;
    this.playTrialWordAudio();
  }

  // Play audio for response letters
  playLetterAudio(index: number) {
    this.dataTracker.requestSupport++;
    this.audioPlayerService.play(this.responseOptions[index]['audio']).subscribe({
      complete: () => this.enableResponsesCallback(),
      error: () => this.enableResponsesCallback()
    });
  }

  // Play audio for target letters
  playTargetLetterAudio(index: number) {
    this.dataTracker.requestSupport++;
    if (index === this.blankTile) {
      // Play the audio corresponding with the correct answer
      this.audioPlayerService.play(this.correctResponse.audio).subscribe({
        complete: () => this.enableResponsesCallback(),
        error: () => this.enableResponsesCallback()
      });
    } else {
      let tile = <Tile>this.trials[this.trialIndex]['display'].tile[index];
      this.audioPlayerService.play(tile['@audio']).subscribe({
        complete: () => this.enableResponsesCallback(),
        error: () => this.enableResponsesCallback()
      });
    }
  }

  defaultAudioCompleteFunc() {
    this.reusableTimer = window.setTimeout(() => {
      this.displayTrial(this.trialIndex);
    }, 0);
  }

  firstResponseIncorrectSequence() {
    this.reusableTimer = window.setTimeout(() => {
      this.removeLetterToChange();
      this.disableResponseButtons = true;
      this.changeDetector.markForCheck() ;

      this.audioPlayerService.play(this.task.trial[this.trialIndex].word['@audio']).subscribe({
        complete: () => {
          window.setTimeout(() => {
            this.originalStartTime = this.timerService.startTimer();
            this.disableResponseButtons = false;
            this.disableAVButtons = false;
            this.changeDetector.markForCheck() ;
          }, 0);
        }
      });
    }, 0);
  }

  secondResponseIncorrectSequence() {
    this.reusableTimer = window.setTimeout(() => {
      this.disableResponseButtons = true;
      this.showCorrectAnswer();
      this.changeDetector.markForCheck() ;

      // TODO: maybe just need to change below to audioPlayerService.play()
      this.audioPlayerService.play(this.task.trial[this.trialIndex].word['@audio']).subscribe();
      this.reusableTimer = window.setTimeout(() => {
        let responseObject = this.trialList[this.trialList.length - 1];
        // TODO: need to test this, but because there's no points animation, should just be able to replace with executing callbacks
        //   endOfTrialCallback and updateTotalPoints, no need to call moveToNextTrial
        //this.interventionTaskService.moveToNextTrial(responseObject, false, $scope.endOfTrialCallback, updateTotalPoints);
        this.updateTotalPoints(responseObject.points);
        this.endOfTrialCallback();
      }, this.interventionTaskService.moveToNextTrialDelay);
    }, 0);
  }

  startTrialTimerBar() {
    this.originalStartTime = this.timerService.startTimer();
    this.startTime = this.originalStartTime;
    this.timerBarTaskSettings = this.interventionTaskService.getTimerBarTaskSettings();
    if (this.timerBarTaskSettings.timerBarEnabled) {
      let initialDelay = this.interventionTaskService.trialBarBaseDelay + this.timerBarTaskSettings.timerBarDelay;
      this.trialTimerBar.startTrialTimer(this.timerBarTaskSettings.timerBarSpeed, initialDelay);
    }
  }

  removeLetterToChange() {
    this.reusableTimer = window.setTimeout(() => {
      this.targetWord[this.blankTile] = '';
      if ((this.blankTileContainsSilentE || this.showTargetSilentE) && this.targetWord[this.targetWord.length - 1] === 'e') {
        // If the blank tile position includes a silent-e, remove that e when resetting the target word
        this.targetWord.pop();
      }
      this.changeDetector.markForCheck() ;
    }, 0);
  }

  enableButtonsAndStartTimer() {
    window.setTimeout(() => {
      this.disableResponseButtons = false;
      this.disableAVButtons = false;
      this.changeDetector.markForCheck() ;

      // Start timer after the responses are able to be clicked
      this.startTime = this.timerService.startTimer();
    }, 0);
  }

  /** Chain of callbacks used to play the audio at the beginning of each trial **/
  playTargetAudio() {
    this.audioPlayerService.play(this.trials[this.trialIndex].word['@audio']).subscribe({
      complete: () => this.startTrialTimerBar(),
      error: () => this.startTrialTimerBar()
    });
    this.removeLetterToChange();
    this.enableButtonsAndStartTimer();
    this.highlightTargetTile = true;
    this.changeDetector.markForCheck() ;
  }

  playToMakeAudio() {
    this.audioPlayerService.play(this.toMakeAudio).subscribe({
      complete: () => this.playTargetAudio(),
      error: () => this.playTargetAudio()
    });
  }

  playInitialAudio() {
    this.audioPlayerService.play(this.trials[this.trialIndex]['initial-audio']!).subscribe({
      complete: () => this.playToMakeAudio(),
      error: () => this.playToMakeAudio()
    });
  }

  playChangeAudio() {
    this.audioPlayerService.play(this.changeAudio).subscribe({
      complete: () => this.playInitialAudio(),
      error: () => this.playInitialAudio()
    });
  }

  // TODO: This seems like an unneccessary method
  playTrialAudio() {
    this.playChangeAudio();
  }

  showCorrectAnswer() {
    this.targetWord[this.blankTile] = this.correctResponse.text;
    if (this.correctResponse.hasSilentE && !this.showTargetSilentE) {
      // Add a silent e to the correct answer if the last incorrect response doesn't already have it showing AND the correct answer contains one
      this.targetWord.push('e');
      this.showTargetSilentE = true;
    }
    if (!this.correctResponse.hasSilentE && this.showTargetSilentE) {
      // If answer doesn't have silent e but currently showing one, remove it
      this.targetWord.pop();
    }
  }

  // Loop through the trial list and build the target word and responses for each item, and go to the next one after a click even updates the counter
  displayTrial(newIndex: number) {
    this.numberOfAttemptsForTrial = 0;
    this.blankTileContainsSilentE = false;
    this.highlightTargetTile = false;
    this.showResponseAudioButtons = false;
    this.showTargetAudioSupportButtons = false;
    this.showTargetSilentE = false;

    // Get the current trial
    // TODO: how I'm determining the trialIndex is a little different so needs testing.
    //  Old version used getCurrentTrial() but I'm pretty sure it should just be the current index
    this.trialIndex = newIndex;
    this.dataTracker = this.interventionTaskService.createTrialDataTrackerObject();

    this.targetWord = [];
    this.responseOptions = [];

    this.playTrialAudio();

    // Figure out the blank tile position and make it blank in the target word
    this.blankTile = parseInt(this.trials[this.trialIndex].blankTilePos!) -1;

    // Flag to let me know if I need to add an e to the end of the target word because of the silent e or not
    let addEToEnd = false;
    let tiles = <Tile[]> this.trials[this.trialIndex].display.tile;
    for (let characterIndex = 0; characterIndex < tiles.length; characterIndex++) {
      let tileText = tiles[characterIndex]['#text'];
      // If a tile in the target word contains the silent e syntax, remove the +e and append that to the end, but keep the letter(s) before the +e
      if (tileText.includes('+')) {
        this.targetWord.push(tileText.substring(0, tileText.indexOf('+')));
        addEToEnd = true;
        this.blankTileContainsSilentE = (this.blankTile === characterIndex);
      }
      else {
        this.targetWord.push(tileText);
      }
    }
    if (addEToEnd) {
      this.targetSilentEIndex = this.targetWord.push('e') - 1;
    }
    else {
      this.targetSilentEIndex = this.targetWord.length;
    }

    // Create a shallow copy of the response list
    let responseList = this.trials[this.trialIndex]['resp-list']!.resp.slice();

    // Reduce the response list if applicable
    responseList = this.interventionTaskService.reduceResponsesIfNecessary(responseList);

    // Shuffle the responses if the curriculum calls for it.
    responseList = this.interventionTaskService.shuffleResponses(responseList, this.trials[this.trialIndex]['resp-list']!['@randomResponses']);

    // Build the response lists
    this.createResponseList(responseList);
  }

  // Build the response list for a single trial
  createResponseList(responseList: Response[]) {
    for (let respIndex = 0; respIndex < responseList.length; respIndex++) {
      let responseIsCorrect = responseList[respIndex]['@type'].toLowerCase() === 'correct';
      let responseText = responseList[respIndex]['#text'];
      let containsSilentE = responseText.includes('+');
      if (containsSilentE) {
        responseText = responseText.substring(0, responseText.indexOf('+'));
      }

      let responseOption = this.createResponseOption(respIndex, responseText, responseList[respIndex]['@audio']!, containsSilentE, responseIsCorrect);
      this.responseOptions.push(responseOption);

      if (responseIsCorrect) {
        this.correctResponse = responseOption;
        this.dataTracker.targetAnswer = responseList[respIndex]['#text'];
      }
    }

    // Split responses into 2 rows
    var numResponses = this.responseOptions.length;
    if (numResponses <= 3) {
      this.responseOptions1 = this.responseOptions.slice(0,numResponses);
      this.responseOptions2 = [];
    }
    else {
      this.responseOptions1 = this.responseOptions.slice(0,numResponses/2);
      this.responseOptions2 = this.responseOptions.slice(numResponses/2,numResponses);
    }

    this.showResponseAudioButtons = this.interventionTaskService.hasInitialAudioSupport();
    this.changeDetector.markForCheck() ;
  }

  createResponseOption(index: number, text: string, audio: string, hasSilentE: boolean, isCorrect: boolean): ResponseOption {
    return <ResponseOption>{
      index: index,
      text: text,
      audio: audio,
      hasSilentE: hasSilentE,
      isCorrect: isCorrect,
    }
  }

  // Function for what happens when user clicks on response tile
  submitResponse(selectedResponseIndex: number) {
    this.disableResponseButtons = true;
    this.disableAVButtons = true;
    this.showTargetSilentE = false;
    this.numberOfAttemptsForTrial++;

    // Stop timer after the student selects a response
    this.endTime = this.timerService.stopTimer();
    if (this.numberOfAttemptsForTrial === 1) {
      this.firstResponseTime = this.timerService.computeTime(this.startTime, this.endTime) || 0;
      this.secondResponseTime = 0;
    }
    else {
      this.secondResponseTime = this.timerService.computeTime(this.startTime, this.endTime) || 0;
    }

    let response = this.responseOptions[selectedResponseIndex];
    // Put the data on this tile in the empty tile location in the target word area
    this.targetWord[this.blankTile] = response.text;
    if (response.hasSilentE === true) {
      this.targetWord.push('e');
      this.showTargetSilentE = true;
    }

    let isCorrect = response.isCorrect;
    let runningPointsAnimation = this.trialTimerBar.sendResponseToTimerBar(isCorrect);
    let trialPoints = this.trialTimerBar.getPoints();
    this.interventionTaskService.playSoundEffect(isCorrect);
    this.interventionTaskService.recordResponseInTrialDataTrackerObject(this.dataTracker, response.hasSilentE ? response.text + '+e': response.text);

    if (isCorrect || !this.isUnit){
      // If the student missed their first try -- count the trial as incorrect
      let isTrialCorrect = isCorrect && (this.numberOfAttemptsForTrial === 1);
      this.interventionTaskService.trackResponseTrends(isTrialCorrect);
      let responseObject = this.interventionTaskService.createTrialResponseObject(isTrialCorrect, this.trialIndex,
          this.firstResponseTime, this.secondResponseTime, trialPoints, this.dataTracker, selectedResponseIndex);
      if (isTrialCorrect) this.numberOfCorrectTrials++;

      this.trialList.push(responseObject);

      // Perform expected animations and move on to the next trial
      this.taskService.answerTrial(isTrialCorrect) ;
      this.interventionTaskService.moveToNextTrial(responseObject, runningPointsAnimation).subscribe({
        complete: () => {
          this.updateTotalPoints(responseObject.points);
          this.endOfTrialCallback();
        }
      });
    }
    else if (this.numberOfAttemptsForTrial === 1) {
      // 1 incorrect response
      this.showResponseAudioButtons = true;
      this.showTargetAudioSupportButtons = true;
      this.reusableTimer = window.setTimeout(() => {
        this.audioPlayerService.play('Audio/Help/help_tryagain.mp3').subscribe({
          complete: () => this.firstResponseIncorrectSequence(),
          error: () => this.firstResponseIncorrectSequence()
        });
      }, this.interventionTaskService.firstIncorrectDelay);
    }
    else {
      // 2 incorrect responses
      this.interventionTaskService.trackResponseTrends(isCorrect);
      let responseObject = this.interventionTaskService.createTrialResponseObject(isCorrect, this.trialIndex, this.firstResponseTime,
          this.secondResponseTime, trialPoints, this.dataTracker, selectedResponseIndex);
      this.trialList.push(responseObject);
      this.taskService.answerTrial(isCorrect) ;
      this.reusableTimer = window.setTimeout(() => {
        this.audioPlayerService.play('Audio/Help/help_correctansweris.mp3').subscribe({
          complete: () => this.secondResponseIncorrectSequence(),
          error: () => this.secondResponseIncorrectSequence()
        });
      }, this.interventionTaskService.secondIncorrectDelay);
    }
  }

  // Show the user the response they selected for one second before going to the next trial
  // Run through the task with the next trial in the curriculum
  endOfTrialCallback() {
    this.reusableTimer = window.setTimeout(() => {
      this.trialTimerBar.resetTrialTimer();

      let newIndex = this.trialIndex + 1;
      if (newIndex < this.trials.length) {
        this.displayTrial(newIndex);
      } else {
        this.saveTaskData();
      }
    }, this.interventionTaskService.getDelayAfterSingleResponse(this.trialList));
  }

  saveTaskData() {
    this.interventionTaskService.handleEndOfTaskProcess(this.trialList, this.taskTotalPoints,
        this.numberOfTrials, this.numberOfCorrectTrials, this.attempt)
    .pipe(
      mergeMap(() => {
        let params = this.interventionTaskService.getTaskDataParams();
        if (params.taskData.length) {
          return this.studentDataService.saveTrialData(params.taskData, !params.taskFinished)
        } else {
          return of({});
        }
      })
    ).subscribe({
      next: () => {
        this.saveDataDialog.hideSaveDataDialog();
        this.completeTask(this.attempt);
      },
      error:() => this.saveDataDialog.showSaveDataDialog()
    });
  }
}
