import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class TimerService {

  constructor() { }

  startTimer(): number {
    return new Date().getTime();
  }

  stopTimer(): number {
    return new Date().getTime();
  }
  
  computeTime(start: number, end: number): number {
    return end - start;
  }
}
