import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of, throwError } from 'rxjs';
import { catchError, map, retry } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { DiagnosticTask, InterventionTask } from 'src/app/core/models/task.model';
import { DiagnosticTaskResponse, InterventionTaskResponse, InterventionTaskStatus, Leaderboard, StudentAssessmentTaskStatus, StudentData, Team, AvatarData, AvatarItem, InterventionData } from '../models/student-data.model';
import { TaskTheme, InterventionTaskTheme, ThemeProperties, THEMES } from 'src/app/core/models/theme.model';
import { ApplicationStateService } from './application-state.service';
import { Curriculum, CurriculumAdapter } from '../models/curriculum.model';
import { TaskService } from './task.service';

@Injectable({
  providedIn: 'root'
})
export class StudentDataService {

  constructor(
    private httpClient: HttpClient,
    private applicationStateService: ApplicationStateService,
    private curriculumAdapter: CurriculumAdapter,
    private taskService: TaskService,
  ) { }

  clearSelectedTask(): void {
    this.applicationStateService.clearSelectedTask();
  }

  getUsername(): string | null {
    return this.applicationStateService.getUsername();
  }

  loadAvailableAvatars() {
    let avatarList: AvatarItem[] = [];
    let studentAvatar = this.applicationStateService.getStudentAvatarData();
    let avatarIconPath = "/assets/images/avatarIcons/";

    if (studentAvatar != null) {
      avatarIconPath += "pack" + studentAvatar.packID + "/";

      // Load the avatars in the reverse order that we expect to earn them,
      // resuling in the most recent avatar awarded appearing first in the list

      // Winning Team avatar - 1
      if (studentAvatar.winningTeamAvatar) {
        avatarList.push(this.newAvatarItem(avatarIconPath, 'winningteam', 1));
      }

      // Weekly Goal avatar - 1
      if (studentAvatar.weeklyGoalAvatar) {
        avatarList.push(this.newAvatarItem(avatarIconPath, 'weeklygoal', 1));
      }

      let goldIndex = 2;
      let bronzeIndex = 6;
      for (let i = 24; i > 0; --i) {
        if (i % 12 == 0) {
          this.addAvatarToListIfAvailable(avatarList, studentAvatar.goldAvatars, avatarIconPath, 'gold', goldIndex);
          goldIndex--;
        }
        if (i % 4 == 0) {
          this.addAvatarToListIfAvailable(avatarList, studentAvatar.bronzeAvatars, avatarIconPath, 'bronze', bronzeIndex);
          bronzeIndex--;
        }
        this.addAvatarToListIfAvailable(avatarList, studentAvatar.unitAvatars, avatarIconPath, 'unit', i);
      }

    } else {
      avatarIconPath += "pack1/";
    }

    // Default avatar - 1
    avatarList.push(this.newAvatarItem(avatarIconPath, 'default', 1));
    this.applicationStateService.setAvatarList(avatarList);
  }

  getAvatarList() {
    return this.applicationStateService.getAvatarList();
  }

  checkForAvatarAward(): void {
    if (!this.areAllTasksComplete()) {
      return;
    }

    let interventionData = this.applicationStateService.getStudentInterventionData();
    if (this.isInterventionUnit()) {
      // If we are an intervention unit, we have interventionData
      this.awardAvatar('unit', interventionData!.unitNumber!);
    }
    else if (!this.isPreDiagnostic() && !this.isInterventionPreTest()) {
      let taskList = this.getTaskStatusList();
      let previousScores: number[] = [];
      let currentScores: number[] = [];
      for (let i = 0; i < taskList.length; i++) {
        let task = taskList[i];
        if (this.isInterventionTaskStatus(task) && task.pretestScore !== null) {
          previousScores.push(task.pretestScore);
        }
        else if (this.isStudentAssessmentTaskStatus(task) && task.previousScore !== null) {
          previousScores.push(task.previousScore);
        }
        currentScores.push(task.numberOfCorrectTrials / task.numberOfTrials);
      }

      let averageCurrentScore = currentScores.reduce((a, b) => a + b, 0) / currentScores.length * 100;
      let averagePreviousScore = 0;
      if (previousScores.length != 0) {
        averagePreviousScore = previousScores.reduce((a, b) => a + b, 0) / previousScores.length;
      }

      if ((averageCurrentScore > averagePreviousScore) || averageCurrentScore >= 90) {
        if (this.isInterventionPostTest()) {
          // If we are an intervention unit, we have interventionData
          this.awardAvatar('bronze', interventionData!.objectiveNumber);
        }
        else if (this.isMidDiagnostic()) {
          this.awardAvatar('gold', 1);
        }
        else if (this.isPostDiagnostic()) {
          this.awardAvatar('gold', 2);
        }
      }
    }
  }

  awardAvatar(avatarCategory: string, avatarIndex: number): void {
    let studentAvatar = this.getStudentAvatar();

    if (studentAvatar != null) {
      // Award the specified avatar in the category
      if (avatarCategory == "unit") {
        studentAvatar.unitAvatars |= (1 << (avatarIndex - 1));
      } else if (avatarCategory == "bronze") {
        studentAvatar.bronzeAvatars |= (1 << (avatarIndex - 1));
      } else if (avatarCategory == "gold") {
        studentAvatar.goldAvatars |= (1 << (avatarIndex - 1));
      } else if (avatarCategory == "weeklygoal") {
        studentAvatar.weeklyGoalAvatar = true;
      }
      // We need to save this avatar data back into session storage now.
      this.applicationStateService.setStudentAvatarData(studentAvatar);

      // reload avatar list
      this.loadAvailableAvatars();
      this.setRecentlyAwardedAvatar(this.findAvatarInList(this.applicationStateService.getAvatarList(), avatarCategory, avatarIndex));
    }
  }

  private findAvatarInList(avatarList: AvatarItem[], category: string, index: number): AvatarItem | null {
    for (let avatarItem of avatarList) {
      if ((avatarItem.category == category) &&
        (avatarItem.index == index)) {
        return avatarItem;
      }
    }

    return null;
  }

  private newAvatarItem(avatarIconPath: string, category: string, index: number): AvatarItem {
    let newAvatarItem: AvatarItem;
    newAvatarItem = {
      avatar: avatarIconPath + category + "/avatar_" + index + ".svg",
      avatarMarker: avatarIconPath + category + "/avatarMarker_" + index + ".svg",
      category: category,
      index: index
    }
    return newAvatarItem;
  }

  private addAvatarToListIfAvailable(avatarList: AvatarItem[], bitMaskValue: number,
    avatarIconPath: string, category: string, index: number) {
    if ((bitMaskValue & (1 << (index - 1))) > 0) {
      avatarList.push(this.newAvatarItem(avatarIconPath, category, index));
    }
  }

  isFullProductObjectiveOrDiagnosticComplete(): boolean {
    return (this.isFullProductSubscription() &&
      (this.isInterventionPostTest() || this.isPreDiagnostic() || this.isMidDiagnostic() || this.isPostDiagnostic()) &&
      this.areAllTasksComplete());
  }

  /**
   * Return a zero based position index of the level that
   * the student is currently on.  The values should range
   * between 0 and 23 inclusively.
   */
  getCurrentPosition() {
    let isIntervention = this.isIntervention();
    let currentPosition = 0;
    let currentDestination = 1;
    let completedDestinations = this.computeCompletedDestinations();
    if (this.isDemoUser()) {
      currentDestination = Object.values(completedDestinations).length;
    }
    else {
      currentDestination = 1 + Object.values(completedDestinations).findIndex((isComplete) => {
        return !isComplete
      });

      // All Destinations Complete for this Unit
      if (currentDestination == 0) {
        currentDestination = Object.values(completedDestinations).length;
      }
    }

    //if user is intervention, calculate their position based on current destination in unit/assessment
    if (isIntervention) {
      let levelsPerUnit = this.getNumLevelsInUnit();
      let levelsPrePost = this.getNumLevelsInPrePost();

      if (this.isInterventionPreTest()) {
        currentPosition = currentDestination - 1;
      }
      else if (this.isInterventionUnit()) {
        let studentInterventionData = this.getStudentInterventionData();
        let unitNumber = (studentInterventionData!.unitNumber! - 1) % 4 + 1; // Gives us a unit number from 1 to 4 inclusive
        currentPosition = levelsPrePost + ((unitNumber - 1) * levelsPerUnit) + currentDestination - 1;
      }
      else { // Post test
        currentPosition = this.getNumberOfLevelSelectButtons() - levelsPrePost + currentDestination - 1;
      }
    }
    else {
      currentPosition = currentDestination - 1;
    }

    return currentPosition;
  }

  // Returns the InterventionTaskTheme or TaskTheme for the current location.
  getLevelProperties(): InterventionTaskTheme | TaskTheme {
    let themeProperties = this.getThemeProperties();
    let levelSelectPropertyList = themeProperties.levelSelect;
    let studentInterventionData = this.getStudentInterventionData();

    if (this.isIntervention()) {
      return (<InterventionTaskTheme[]>levelSelectPropertyList)[studentInterventionData!.objectiveNumber - 1];
    }
    else {
      return <TaskTheme>levelSelectPropertyList;
    }
  }

  getLevelSelectBackgroundA(): string {
    return this.getLevelProperties().backgroundImageA;
  }

  getLevelSelectBackgroundB(): string | null {
    if (this.isIntervention()) {
      var levelPropertyList = <InterventionTaskTheme>this.getLevelProperties();
      return levelPropertyList.backgroundImageB;
    }
    return null;
  }

  areAllTasksComplete(): boolean {
    let allTasksComplete = true;
    let taskList = this.getTaskStatusList();

    for (let item in taskList) {
      if (!taskList[item].complete) {
        allTasksComplete = false;
        break;
      }
    }
    return allTasksComplete;
  }

  // Indicate if all tasks are complete (true) or not (false) for a given level
  areAllTasksCompleteForDestination(destinationNumber: number): boolean {
    let taskList = this.getTaskStatusList();

    let allTasksComplete = true;

    for (let item in taskList) {
      if ((taskList[item].destination == destinationNumber) &&
        (!taskList[item].complete)) {
        allTasksComplete = false;
        break;
      }
    }
    return allTasksComplete;
  }

  // Indicate if all tasks are incomplete (true) or not (false) for a given level
  areAllTasksIncompleteForDestination(destinationNumber: number): boolean {
    let taskList = this.getTaskStatusList();

    for (let item in taskList) {
      if ((taskList[item].destination == destinationNumber) && (taskList[item].complete)) {
        return false;
      }
    }

    return true;
  }

  setStudentInterventionTaskStatusCompleted(destinationNumber: number, taskIdentifier: string, taskList: InterventionTaskStatus[]): void {
    for (let item in taskList) {
      if ((taskList[item].destination === destinationNumber) &&
        (taskList[item].interventionTaskIdentifier === taskIdentifier)) {
        taskList[item].complete = true;
        break;
      }
    }
  }

  setStudentDiagnosticTaskStatusCompleted(destinationNumber: number, taskIdentifier: string, taskList: StudentAssessmentTaskStatus[]): void {
    for (let item in taskList) {
      if ((taskList[item].destination === destinationNumber) &&
        (taskList[item].taskIdentifier === taskIdentifier)) {
        taskList[item].complete = true;
        break;
      }
    }
  }

  updateIfLastTaskCompletedForDestination(destinationNumber: number): void {
    // If this was the last task in a destination -- indicate it on the $sessionStorage
    if (this.areAllTasksCompleteForDestination(destinationNumber)) {
      this.applicationStateService.setJustCompletedDestinationNumber(destinationNumber);
      this.applicationStateService.setLevelCompleted(1);
      return;
    }

    this.applicationStateService.setLevelCompleted(0);
  }

  getTaskBarTitleBasedOnCurriculumPlacement(): string {
    if (this.isScreenerDiagnostic()) {
      return "SCREENER";
    } else if (this.isDiagnosticProductSubscription()) {
      return "DIAGNOSTIC";
    } else if (this.isPreDiagnostic()) {
      return "PRE-DIAGNOSTIC";
    } else if (this.isMidDiagnostic()) {
      return "MID-DIAGNOSTIC";
    } else if (this.isPostDiagnostic()) {
      return "POST-DIAGNOSTIC";
    } else {
      // Intervention Instructional
      let studentInterventionData = this.getStudentInterventionData();
      if (this.isInterventionPreTest()) {
        return "OBJ " + studentInterventionData?.objectiveNumber + " - PRETEST";
      } else if (this.isInterventionPostTest()) {
        return "OBJ " + studentInterventionData?.objectiveNumber + " - POSTTEST";
      }

      if (this.isDemoUser()) {
        return "DEMO";
      } else {
        // Units
        return "LEVEL " + this.getCurrentLevel();
      }
    }
  }

  // Level Property methods from the theme
  // FIXME: There may be a better way to check if the theme type is InterventionTaskTheme vs TaskTheme
  getInterventionTaskLevelProperties(): InterventionTaskTheme {
    let themeProperties = this.getThemeProperties();
    let studentInterventionData = this.getStudentInterventionData();
    let levelSelectPropertyList = <InterventionTaskTheme[]>themeProperties.levelSelect;

    if (studentInterventionData) {
      return levelSelectPropertyList[studentInterventionData.objectiveNumber - 1];
    } else {
      return levelSelectPropertyList[0];
    }
  }

  getTaskLevelProperties(): TaskTheme {
    let themeProperties = this.getThemeProperties();
    let levelSelectProperties = <TaskTheme>themeProperties.levelSelect;
    return levelSelectProperties;
  }

  getTaskBarTotalPointsBackgroundColor(): string {
    let levelSelectProperties = this.getInterventionTaskLevelProperties();
    return levelSelectProperties.totalTaskPointsBackgroundColor;
  }

  getTaskBarTotalPointsBorderColor(): string {
    let levelSelectProperties = this.getInterventionTaskLevelProperties();
    return levelSelectProperties.totalTaskPointsBorderColor;
  }

  getTaskBarColor(): string {
    let levelSelectProperties = this.isIntervention() ?
      this.getInterventionTaskLevelProperties() : this.getTaskLevelProperties();
    return levelSelectProperties.taskBarColor;
  }

  getTaskDarkContainerColor(): string {
    let levelSelectProperties = this.getInterventionTaskLevelProperties();
    return levelSelectProperties.taskContainerDarkColor;
  }

  getTaskContainerColor(): string {
    let levelSelectProperties = this.isIntervention() ?
      this.getInterventionTaskLevelProperties() : this.getTaskLevelProperties();
    return levelSelectProperties.taskContainerColor;
  }

  getButtonColor(): string {
    let levelSelectProperties = this.getLevelProperties();
    return levelSelectProperties.buttonColor;
  }

  getTaskBackground(): string {
    let levelSelectProperties = this.isIntervention() ?
      this.getInterventionTaskLevelProperties() : this.getTaskLevelProperties();
    return levelSelectProperties.taskBackground;
  }

  getTaskSelectBackground(): string {
    return this.getLevelProperties().taskSelectBackground;
  }

  getSubtaskSelectBackground(completed: boolean = false): string {
    let levelSelectProperties = this.isIntervention() ? this.getInterventionTaskLevelProperties() : this.getTaskLevelProperties();
    return !completed ? levelSelectProperties.subtaskSelectBackground : levelSelectProperties.subtaskSelectBackgroundComplete ;
  }

  getTaskSelectButtonImages(): string[] {
    // Task buttons is an object with keys representing different images, the values being the URLs
    return Object.values(this.getLevelProperties().taskButtons);
  }

  getSelectedTask(): DiagnosticTask | InterventionTask {
    // TODO: It's technically possible that selectedTask is null in state but this should not happen at the times
    // we are accessing it through this method. In the future, we should add better handling of a possible null task.
    const selectedTask = this.applicationStateService.getSelectedTask();
    if (selectedTask == null) {
      console.warn(`[StudentDataService.getSelectedTask] selectedTask is null`);
    }

    return selectedTask!;
  }

  setSelectedTask(task: DiagnosticTask | InterventionTask) {
    this.applicationStateService.setSelectedTask(task);
  }

  startSelectedTask(): DiagnosticTask | InterventionTask {
    let selectedTask = this.getSelectedTask();
    if (selectedTask) {
      this.applicationStateService.setSelectedTaskStartTime();
    }
    return selectedTask;
  }

  getSelectedTaskDurationMillis(): number {
    let currentTime = new Date();
    let startTime = this.applicationStateService.getSelectedTaskStartTime() ?? currentTime;
    return currentTime.getTime() - startTime.getTime();
  }

  /**
  * Sets the total points and trial counts on the task in session storage
  */
  // TODO: there has to be a better way to cast taskList as either type
  setTotalPoints(currentDestination: number, points: number, numberOfTrials: number, numberOfCorrectTrials: number) {
    if (this.isIntervention()) {
      let taskList = <InterventionTaskStatus[]>this.getTaskStatusList();
      let task = this.getSelectedTask();
      for (let i = 0; i < taskList.length; i++) {
        if (this.isInterventionTaskStatus(taskList[i])) {
          if (currentDestination == taskList[i].destination && task.id == taskList[i].interventionTaskIdentifier) {
            // The original backend object that corresponds to this "task" object doesn't have a points member, and so this
            // data point never makes it to the backend or is persisted there.
            // The points are only used locally for intervention unit tasks where the student utilizes a second wordlist in the same task.
            // This helps us to show a cumulative number of points during the second wordlist.

            // The numberOfTrials and numberOfCorrectTrials are used by the backend and persisted in the database
            taskList[i].points = points;
            taskList[i].numberOfTrials = numberOfTrials;
            taskList[i].numberOfCorrectTrials = numberOfCorrectTrials;
            // Save the new taskList
            this.applicationStateService.setStudentInterventionTaskList(taskList);
            break;
          }
        }
      }
    } else {
      let taskList = <StudentAssessmentTaskStatus[]>this.getTaskStatusList();
      let task = this.getSelectedTask();
      for (let i = 0; i < taskList.length; i++) {
        if (this.isStudentAssessmentTaskStatus(taskList[i])) {
          if (currentDestination == taskList[i].destination && task.id == taskList[i].taskIdentifier) {
            // The original backend object that corresponds to this "task" object doesn't have a points member, and so this
            // data point never makes it to the backend or is persisted there.
            // The points are only used locally for intervention unit tasks where the student utilizes a second wordlist in the same task.
            // This helps us to show a cummulative number of points during the second wordlist.

            // The numberOfTrials and numberOfCorrectTrials are used by the backend and persisted in the database
            taskList[i].points = points;
            taskList[i].numberOfTrials = numberOfTrials;
            taskList[i].numberOfCorrectTrials = numberOfCorrectTrials;
            // Save the new taskList
            this.applicationStateService.setStudentAssessmentTaskList(taskList);
            break;
          }
        }
      }
    }
  }

  initInterventionTaskCompletionCounts(studentData: StudentData) {
    this.applicationStateService.initInterventionTaskCompletionCounts(studentData);
  }

  // Keeps track of how many times a student completed a particular task, currently using session storage
  // Could be changed to permanently track the data through the database
  updateTaskCompletionCount(taskId: string) {
    this.applicationStateService.updateStudentTaskCompletionCount(taskId);
  }

  // Returns how many times a student has completed a specific task
  getTaskCompletionCount(taskId: string): number | null {
    return this.applicationStateService.getStudentTaskCompletionCount(taskId);
  }

  // FIXME: Why is there a separate isInterventionTaskComplete method, that actually does not look to do the same checking
  isTaskComplete(taskList: (StudentAssessmentTaskStatus | InterventionTaskStatus)[], destination: number, taskIdentifier: string, taskIdString: string, subtask: boolean = false): boolean {
    for (let taskIdx = 0 ; taskIdx < taskList.length ; taskIdx++)
    {
      let task = taskList[taskIdx] ;
      if (task.destination === destination &&
         ((taskIdString === 'taskIdentifier' && (task as StudentAssessmentTaskStatus).taskIdentifier === taskIdentifier) ||
          (taskIdString === 'interventionTaskIdentifier' && (task as InterventionTaskStatus).interventionTaskIdentifier === taskIdentifier)))
      {
        if (taskIdentifier.startsWith('PASS') && !subtask)
        {
          // Different rules for Passage tasks since they can contain sub-tasks:
          // - Check to see if this specific Passage task contains sub tasks
          let taskIdArr = taskIdentifier.split('-');
          let subTaskSuffix = '-' + taskIdArr[1] + '-' + taskIdArr[2];
          let picSubTaskId = 'PAP' + subTaskSuffix;
          let questionSubTaskId = 'PAQ' + subTaskSuffix;
          let picSubTaskFound = false;
          let questionSubTaskFound = false;
          let picSubTaskComplete = false;
          let questionSubTaskComplete = false;

          for (let subIdx = 0; subIdx < taskList.length; subIdx++) {
            let subTask = taskList[subIdx];
            if ((taskIdString === 'taskIdentifier' && (subTask as StudentAssessmentTaskStatus).taskIdentifier === picSubTaskId) ||
              (taskIdString === 'interventionTaskIdentifier' && (subTask as InterventionTaskStatus).interventionTaskIdentifier === picSubTaskId)) {
              picSubTaskFound = true;
              picSubTaskComplete = subTask.complete;
            }

            if ((taskIdString === 'taskIdentifier' && (subTask as StudentAssessmentTaskStatus).taskIdentifier === questionSubTaskId) ||
              (taskIdString === 'interventionTaskIdentifier' && (subTask as InterventionTaskStatus).interventionTaskIdentifier === questionSubTaskId)) {
              questionSubTaskFound = true;
              questionSubTaskComplete = subTask.complete;
            }
          }

          // - If all of a Passage tasks sub-tasks are complete, then show the whole task as complete
          if (picSubTaskFound && questionSubTaskFound) {
            return picSubTaskComplete && questionSubTaskComplete;
          }
          else if (picSubTaskFound) {
            return picSubTaskComplete;
          }
          else if (questionSubTaskFound) {
            return questionSubTaskComplete;
          }
          else {
            // This passage has no sub tasks -- mark complete if all of the passage audio was listened to
            return task.complete;
          }
        }
        else {
          return task.complete;
        }
      }
    }

    return false;
  }

  isInterventionTaskComplete(currentDestination: number, task: InterventionTask | null): boolean {
    let interventionData = this.getStudentInterventionData()
    let interventionTaskList = interventionData?.interventionTaskList ?? [];
    for (let i = 0; i < interventionTaskList.length; i++) {
      if (currentDestination == interventionTaskList[i].destination && task!.id == interventionTaskList[i].interventionTaskIdentifier) {
        return interventionTaskList[i].complete;
      }
    }
    return false;
  }

  fetchStudentData(): Observable<StudentData> {
    // Create a request
    let reqOptions = {
      withCredentials: true,
      headers: {
        'Access-Control-Allow-Origin': "*",
      },
    };

    return this.httpClient.get(environment.EC2URL + 'v3/student/data', reqOptions)
      .pipe(
        // NOTE using map doesn't seem appropriate here since
        //   we aren't manipulating data coming back, we are just
        //   using it to call setStudentData
        map((res: any) => {
          this.setStudentData(res);
          return res;
        }),
      );
  }

  getDemoStudentData(data: any): any {
    // Create a request
    let reqOptions = {
      withCredentials: true,
      headers: {
        'Access-Control-Allow-Origin': "*",
      },
      params: data,
    };

    return this.httpClient.get(`${environment.EC2URL}v3/student/demo/data`, reqOptions);
  }

  getDemoPlacementOptions() {
    let url = `${environment.EC2URL}v3/student/demo/placementOptions`;
    let reqOptions = {
      withCredentials: true,
      headers: {
        'Access-Control-Allow-Origin': "*",
      },
    };

    return this.httpClient.get(url, reqOptions);
  }

  getDemoCurriculumOptions() {
    let url = `${environment.EC2URL}v3/student/demo/curriculumOptions`;
    let reqOptions = {
      withCredentials: true
    };

    return this.httpClient.get(url, reqOptions);
  }

  getDemoSubscriptionOptions() {
    let url = `${environment.EC2URL}v3/student/demo/subscriptionOptions`;
    let reqOptions = {
      withCredentials: true
    };

    return this.httpClient.get(url, reqOptions);
  }

  getDemoWordListOptions() {
    let url = `${environment.EC2URL}v3/student/demo/wordListOptions`;
    let reqOptions = {
      withCredentials: true
    };

    return this.httpClient.get(url, reqOptions);
  }

  /**
    * Makes an API call to the backend to save the trial data for the student's last completed task
    * @param {DiagnosticTaskResponse || InterventionTaskResponse[]} taskResponse - Diagnostic task will always be an object, Intervention tasks can be either (multiple wordlists)
    * @param {Boolean} unfinished - [optional] True - Keep the task(s) as incomplete, False/undefined - task(s) will be marked as complete
    */
  saveTrialData(taskResponse: DiagnosticTaskResponse | InterventionTaskResponse[], unfinished?: boolean): Observable<StudentData> {
    // Do not save the data if the user is demoing the site.
    if (this.isDemoUser()) {
      // Update our points in the leaderboard
      let studentData = this.getStudentData();
      if (this.isIntervention() && this.isInterventionTaskResponse(taskResponse)) {
        taskResponse.forEach((response: InterventionTaskResponse) => {
          studentData.leaderboard.studentDailyPoints += response.points;
          studentData.leaderboard.studentTotalPoints += response.points;
          studentData.leaderboard.studentWeeklyPoints += response.points;

          studentData.leaderboard.teamsWithPoints.forEach((team) => {
            if (team.team.teamID === studentData.team.teamID) {
              team.dailyPoints += response.points;
              team.weeklyPoints += response.points;
            }
          });
        });
      }
      else if (this.isDiagnosticTaskResponse(taskResponse)) {
        studentData.leaderboard.studentDailyPoints += taskResponse.points;
        studentData.leaderboard.studentTotalPoints += taskResponse.points;
        studentData.leaderboard.studentWeeklyPoints += taskResponse.points;

        studentData.leaderboard.teamsWithPoints.forEach((team) => {
          if (team.team.teamID === studentData.team.teamID) {
            team.dailyPoints += taskResponse.points;
            team.weeklyPoints += taskResponse.points;
          }
        });
      }

      // Need to sort the leaderboard teams
      studentData.leaderboard.teamsWithPoints.sort((t1, t2) => t2.weeklyPoints - t1.weeklyPoints);

      this.onSuccessfulTaskSave(studentData.leaderboard);
      this.clearUnsavedData();

      return of(studentData);
    }

    // Get a copy of the current session so that we can easily revert on failed save
    let sessionCopy = this.applicationStateService.getCopyOfSessionData();

    // Add the unsaved task data to the current session student data
    if (this.isIntervention() && this.isInterventionTaskResponse(taskResponse)) {
      this.addUnsavedInterventionTaskData(taskResponse, unfinished);
    } else if (this.isDiagnosticTaskResponse(taskResponse)) {
      this.addUnsavedDiagnosticTaskData(taskResponse, unfinished);
    }

    // When saving intervention trial data (finishing a task), if this completes the destination, determine if there are
    // any beta tasks at the destination that were hidden from the student, if so mark them as complete as well
    const betaTaskResponses = this.addBetaTasksIfNecessary() ;
    if (betaTaskResponses.length && this.isInterventionTaskResponse(taskResponse))
    {
      // We need to reset the unsaved intervention task data because the method addUnsavedInterventionTaskData does not really
      // add unsaved data but instead replaces the unsavedIntervetionData property
      taskResponse.push(...betaTaskResponses) ;
      this.addUnsavedInterventionTaskData(taskResponse, unfinished);
    }

    // Award any avatars based on finished task data
    this.checkForAvatarAward();

    // If this was the last task in a destination -- indicate it on the $sessionStorage
    this.updateIfLastTaskCompletedForDestination(this.getCurrentDestination());

    let reqOptions = {
      withCredentials: true,
      headers: {
        'Access-Control-Allow-Origin': "*",
      },
    };

    // Send the request to the backend using http and auto-retry one
    // time before reporting failure
    return this.httpClient.post<StudentData>(environment.EC2URL + 'v3/student/saveAndGetLeaderboard', this.getStudentData(), reqOptions)
      .pipe(
        retry(1),
        catchError((err) => {
          this.onFailedTaskSave(sessionCopy);
          return throwError(err)
        }),
        map((response: any): any => {
          this.onSuccessfulTaskSave(response);
        })
      );
  };

  onSuccessfulTaskSave(response: any) {
    let leaderboard: Leaderboard = response;
    this.applicationStateService.setStudentLeaderboard(leaderboard);
    this.clearUnsavedData();
  }

  onFailedTaskSave(sessionCopy: string) {
    this.applicationStateService.restoreSessionFromCopy(sessionCopy);
  }

  // Type checks
  // FIXME: TODO there should be a better way to check these types
  isInterventionTaskResponse(task: DiagnosticTaskResponse | InterventionTaskResponse[]): task is InterventionTaskResponse[] {
    return (<InterventionTaskResponse[]>task).length !== undefined;
  }

  isDiagnosticTaskResponse(task: DiagnosticTaskResponse | InterventionTaskResponse[]): task is DiagnosticTaskResponse {
    return (<DiagnosticTaskResponse>task).taskIdentifier !== undefined;
  }

  isStudentAssessmentTaskStatus(taskStatus: StudentAssessmentTaskStatus | InterventionTaskStatus):
    taskStatus is StudentAssessmentTaskStatus {
    return (<StudentAssessmentTaskStatus>taskStatus).taskIdentifier !== undefined;
  }

  isInterventionTaskStatus(taskStatus: StudentAssessmentTaskStatus | InterventionTaskStatus):
    taskStatus is InterventionTaskStatus {
    return (<InterventionTaskStatus>taskStatus).interventionTaskIdentifier !== undefined;
  }

  clearUnsavedData() {
    if (this.getStudentData() == null) {
      return;
    }
    if (this.getStudentData().studentAssessment != null) {
      this.applicationStateService.clearStudentAssessmentUnsavedData();
    }
    if (this.getStudentData().interventionData != null) {
      this.applicationStateService.clearUnsavedInterventionTaskData();
    }
  }

  // Updates the attempt count for intervention tasks
  updateAttemptCountForInterventionTask(taskId: string, taskList: InterventionTaskStatus[]): void {
    taskList.forEach(function (task) {
      if (task.interventionTaskIdentifier === taskId) {
        if (task.attempt || task.attempt === 0) {
          task.attempt += 1;
        } else {
          task.attempt = 1;
        }
      }
    });
  }

  // Adds unsaved intervention task data and marks tasks or assessments as completed
  addUnsavedInterventionTaskData(taskResponse: InterventionTaskResponse[], unfinished?: boolean): void {
    let unsavedInterventionTaskData: InterventionTaskResponse[] = [];

    let taskIds: string[] = [];
    // Save all wordlists
    // TODO: check if I need to check if length is defined like in old version
    for (let i = 0; i < taskResponse.length; i++) {
      if (taskIds.indexOf(taskResponse[i].interventionTaskIdentifier) === -1) {
        taskIds.push(taskResponse[i].interventionTaskIdentifier);
      }
      unsavedInterventionTaskData.push(taskResponse[i]);
    }
    // Save the unsaved intervention data
    let currentInterventionData = this.applicationStateService.getStudentInterventionData();
    if (currentInterventionData !== null) {
      currentInterventionData.unsavedInterventionTaskData = unsavedInterventionTaskData;

      // Update complete and attempt attributes on our request data object
      taskIds.forEach((taskId) => {
        if (currentInterventionData !== null) {
          this.updateAttemptCountForInterventionTask(taskId, currentInterventionData.interventionTaskList);
          if (!unfinished)
            this.setStudentInterventionTaskStatusCompleted(
              this.getCurrentDestination(), taskId, currentInterventionData.interventionTaskList);
        }
      });

      // Save the updated intervention data to storage
      this.applicationStateService.setStudentInterventionData(currentInterventionData);
    }
  }

  // Adds unsaved diagnostic task data and marks tasks or assessments as completed
  addUnsavedDiagnosticTaskData(taskResponse: DiagnosticTaskResponse, unfinished?: boolean): void {
    // Store the last diagnostic task in the student's assessment session storage
    // NOTE: The backend is expecting unsavedData to be an array so keeping that for flexibility if we need to save multiple tasks in the future
    let unsavedAssessmentData: DiagnosticTaskResponse[] = []
    unsavedAssessmentData.push(taskResponse);

    // Get the current student assessment data
    let currentStudentAssessment = this.applicationStateService.getStudentAssessment();
    if (currentStudentAssessment == null) {
      console.error(`Current student assessment is null. Cannot add unsaved diagnostic task data.`);
      return;
    }

    currentStudentAssessment.unsavedData = unsavedAssessmentData;
    if (!unfinished)
      this.setStudentDiagnosticTaskStatusCompleted(this.getCurrentDestination(), taskResponse.taskIdentifier, currentStudentAssessment.taskList);

    if (this.areAllTasksComplete()) {
      currentStudentAssessment.updateTime = (new Date()).toISOString().substring(0, 19).replace('T', ' ');
      currentStudentAssessment.isComplete = true;
    }

    // Save the student assessment to storage
    this.applicationStateService.setStudentAssessment(currentStudentAssessment);
  }

  // Check to see if we should add beta tasks to unsaved intervention data. We would need to add all beta tasks if was are in an
  // intervention and the destination has beta tasks and the student does NOT have beta tasks enabled (would never see them to complete them)
  addBetaTasksIfNecessary(): InterventionTaskResponse[] {
    // Check all the tasks for this destination and determine if all non-beta tasks are complete
    const taskList = this.getTaskStatusList() as InterventionTaskStatus[];
    const destinationNumber = this.getCurrentDestination() ;
    const interventionData = this.getStudentInterventionData();
    let allAvailableTasksComplete = true;
    let betaTaskStatuses: InterventionTaskStatus[] = [] ;
    let betaTaskResponses: InterventionTaskResponse[] = [] ;

    // If this is not an intervention OR this student has beta tasks enabled, we do not need to add them
    if (!this.isIntervention() || (this.getStudentData().betaTasksEnabled && this.getStudentData().betaSpeechTasksEnabled) || !interventionData) return betaTaskResponses;

    for (let tIdx = 0 ; tIdx < taskList.length ; tIdx++)
    {
      if ((taskList[tIdx].destination === destinationNumber) && !taskList[tIdx].complete)
      {
        // If this is a beta task, we do not care about the status of it
        if ((this.taskService.isBetaTask(taskList[tIdx].interventionTaskIdentifier) && !this.getStudentData().betaTasksEnabled) ||
            (this.taskService.isBetaSpeechTask(taskList[tIdx].interventionTaskIdentifier) && !this.getStudentData().betaSpeechTasksEnabled))
        {
          betaTaskStatuses.push(taskList[tIdx]) ;
          continue ;
        }

        allAvailableTasksComplete = false ;
        break ;
      }
    }

    // All our non-beta tasks for this destination are complete, so add our beta tasks that are hidden from this student
    if (allAvailableTasksComplete)
    {
      betaTaskStatuses.forEach((betaTaskStatus) => {
        let taskResponseObject: InterventionTaskResponse = {
          wordListType: -1,
          interventionTaskIdentifier: betaTaskStatus.interventionTaskIdentifier,
          points: 0,
          durationMillis: 0,
          objectiveNumber: interventionData.objectiveNumber,
          type: interventionData.type,
          unitNumber: interventionData.unitNumber ?? 0,
        }
        betaTaskResponses.push(taskResponseObject) ;
      }) ;
    }

    return betaTaskResponses ;
  }

  getCurrentStudentAvatar(): AvatarItem {
    let currentAvatar = this.applicationStateService.getCurrentAvatar();
    if (currentAvatar !== null) {
      return currentAvatar;
    } else {
      var avatarList = this.applicationStateService.getAvatarList();
      var studentAvatarData = this.applicationStateService.getStudentAvatarData();

      if (studentAvatarData !== null && avatarList.length > 0) {
        // Set the student's avatar based on database settings
        for (let avatarItem of avatarList) {
          if ((avatarItem.category == studentAvatarData.currentAvatarGroup) &&
            (avatarItem.index == studentAvatarData.currentAvatarIndex)) {
            currentAvatar = avatarItem;
            break;
          }
        }
      }

      if (currentAvatar === null) {
        // Perhaps the avatar is no longer available.  Pick the "coolest" avatar
        // in the list, which is the first one.
        currentAvatar = avatarList[0];
      }

      this.setCurrentStudentAvatar(currentAvatar);
      return currentAvatar;
    }
  }

  setCurrentStudentAvatar(avatarItem: AvatarItem): void {
    this.applicationStateService.setCurrentAvatar(avatarItem);
    let studentAvatarData = this.applicationStateService.getStudentAvatarData();
    if (studentAvatarData !== null && typeof studentAvatarData !== 'undefined') {
      // This updates the data that goes back to the server to persist
      // the current avatar choice, but this only happens when the
      // next task is completed.  Good enough?  If not, we may need
      // to add a new endpoint to just save current avatar info.
      studentAvatarData.currentAvatarGroup = avatarItem.category;
      studentAvatarData.currentAvatarIndex = avatarItem.index;
      this.applicationStateService.setStudentAvatarData(studentAvatarData);
    }
  }

  isStudentSecondGradeOrLess(): boolean {
    let studentData = this.getStudentData();
    if (studentData !== null) {
      // Actual grade is one less then the enum to account for K
      var secondGradeOrLess = studentData.grade <= 3;
      return (secondGradeOrLess);
    }
    return false;
  }

  getStudentData(): StudentData {
    // TODO: It's technically possible that studentData is null in storage but this should never really happen
    // during regular app usage because the data is first loaded after logging in. I'm adding an error log here in
    // the case it is null but will use the non-null assertion operator from TS going forward. In the future, we should
    // add better handling for null studentData.
    const studentData = this.applicationStateService.getStudentData();
    if (studentData == null) {
      console.error('studentData is null');
    }

    return studentData!;
  }

  setStudentData(studentData: StudentData) {
    this.applicationStateService.setStudentData(studentData);
  }

  getStudentInterventionData(): InterventionData | null {
    if (this.isIntervention()) {
      let studentData = this.getStudentData();
      return studentData.interventionData || null;
    }
    return null;
  }

  getCurriculum(): Curriculum {
    const curriculum = this.applicationStateService.getCurriculum();
    if (curriculum == null) {
      console.error('Curriculum is null');
    }
    // NOTE: This method will fail if somehow the curriculum in the storage service is null,
    //     : however the first step is always to login which calls loadCurriculum
    // Possible TODO for better null handling
    return this.curriculumAdapter.adapt(curriculum);
  }

  setCurriculum(curriculum: Curriculum) {
    this.applicationStateService.setCurriculum(curriculum);
  }

  getInterventionTask(taskId: string, currentDestination: number) {
    if (this.isIntervention()) {
      let interventionTaskList = this.getStudentInterventionData()!.interventionTaskList;
      for (let i = 0; i < interventionTaskList.length; i++) {
        if (currentDestination === interventionTaskList[i].destination && taskId == interventionTaskList[i].interventionTaskIdentifier) {
          return interventionTaskList[i];
        }
      }
    }
    return null;
  }

  setStudentInterventionTaskList(taskList: InterventionTaskStatus[]) {
    this.applicationStateService.setStudentInterventionTaskList(taskList);
  }

  clearCurriculum() {
    this.applicationStateService.clearCurriculum();
  }

  getStudentCurriculumName(): string {
    let studentData = this.getStudentData();
    return studentData.studentAssessment.curriculum;
  }

  getStudentLeaderboard(): Leaderboard {
    let studentData = this.getStudentData();
    return studentData.leaderboard;
  }

  setThemeProperties(themeProperties: ThemeProperties) {
    this.applicationStateService.setThemeProperties(themeProperties);
  }

  getThemeProperties(): ThemeProperties {
    // Note: It's technically possible that theme properties are null in storage but this should never really happen
    // during regular app usage because the theme is first loaded after logging in. I'm adding an error log here in
    // the case it is null but will use the non-null assertion operator from TS going forward.
    // Possible TODO for better null handling
    const themeProperties = this.applicationStateService.getThemeProperties();
    if (themeProperties == null) {
      console.error(`Theme properties are null`);
    }

    return themeProperties!;
  }

  setIsLoggedIn(isLoggedIn: boolean) {
    this.applicationStateService.setIsLoggedIn(isLoggedIn);
  }

  isLoggedIn(): boolean {
    return this.applicationStateService.isLoggedIn();
  }

  isScreenerDiagnostic(): boolean {
    let studentData = this.getStudentData();
    return studentData.studentAssessment.assessmentType === 0;
  }

  getCurrentDestination(): number {
    let studentData = this.getStudentData();
    if (this.isIntervention()) {
      return studentData.interventionData!.currentDestination;
    }

    return studentData.studentAssessment.currentDestination;
  }

  setCurrentDestination(destination: number) {
    let studentData = this.getStudentData();
    if (this.isIntervention()) {
      studentData.interventionData!.currentDestination = destination;
    }
    else {
      studentData.studentAssessment.currentDestination = destination;
    }

    this.setStudentData(studentData);
  }

  isPreDiagnostic(): boolean {
    let studentData = this.getStudentData();
    return studentData.studentAssessment.assessmentType === 1;
  }

  isMidDiagnostic(): boolean {
    let studentData = this.getStudentData();
    return studentData.studentAssessment.assessmentType === 3;
  }

  isPostDiagnostic(): boolean {
    let studentData = this.getStudentData();
    return studentData.studentAssessment.assessmentType === 4;
  }

  isPreDiagnosticCompleted(): boolean {
    let studentData = this.getStudentData();
    return studentData.preDiagnosticCompleted;
  }

  arePreDiagnosticTasksComplete(): boolean {
    return (this.isPreDiagnostic() && this.areAllTasksComplete());
  }

  isMidDiagnosticCompleted(): boolean {
    let studentData = this.getStudentData();
    return studentData.midDiagnosticCompleted;
  }

  isPostDiagnosticCompleted(): boolean {
    let studentData = this.getStudentData();
    return studentData.postDiagnosticCompleted;
  }

  isDiagnosticProductSubscription(): boolean {
    let studentData = this.getStudentData();
    return studentData.subscriptionType === 1;
  }

  // Use this method instead of isDiagnosticProductSubscription if you want to keep track of
  // the demo user's original subscription upon login.  The other one changes
  // based on whatever the current assessment is.  Needed for the demo select screen.
  isDemoUserDiagnosticProductSubscription(): boolean {
    return this.applicationStateService.getDemoUserSubscription() === 1;
  }

  isDemoUserSystemProductSubscription(): boolean {
    return this.applicationStateService.getDemoUserSubscription() === 2;
  }

  setDemoUserSubscription(demoUserSubscription: number) {
    this.applicationStateService.setDemoUserSubscription(demoUserSubscription);
  }

  isInterventionPreTest(): boolean {
    let interventionData = this.applicationStateService.getStudentInterventionData();
    return (interventionData !== null) ? interventionData.type === 0 : false;
  }

  isInterventionUnit(): boolean {
    let interventionData = this.applicationStateService.getStudentInterventionData();
    return (interventionData !== null) ? interventionData.type === 1 : false;
  }

  isInterventionPostTest(): boolean {
    var interventionData = this.getStudentInterventionData();
    return (interventionData !== null) ? interventionData.type === 2 : false;
  }

  getLastCompletedUnit() {
    let studentData = this.getStudentData();
    return studentData.currentUnit;
  }

  isFullProductSubscription(): boolean {
    let studentData = this.getStudentData();
    return studentData.subscriptionType === 2;
  }

  isIntervention(): boolean {
    let studentData = this.getStudentData();
    return studentData?.interventionData != null;
  }

  isDemoUser(): boolean {
    let studentData = this.getStudentData();
    return studentData?.userRole === 'ROLE_TEACHER' || studentData?.userRole === 'ROLE_TEACHER_CURRICULUM' || studentData?.userRole === 'ROLE_SCHOOL_REPORTS' || studentData?.userRole === 'ROLE_DISTRICT_REPORTS' || studentData?.userRole === 'ROLE_TEACHER_REVIEW';
  }

  isUserSuperUser(): boolean {
    let username = this.applicationStateService.getUsername();
    // Will fail if user is not logged in
    if (username) {
      // "Super" user if the last part of username is "DEMO" or this is a teacher demo user
      let userNameLast4 = username.slice(-4);
      return (userNameLast4 === 'DEMO') || this.isDemoUser();
    }
    else {
      return false;
    }
  }

  getDemoUserSubscription(): number {
    let studentData = this.getStudentData();
    return this.isDemoUser() ? studentData.subscriptionType : 0;
  }

  setLastTeamStanding(teamStanding: number) {
    let studentData = this.getStudentData();
    studentData.teamStanding = teamStanding;
    this.setStudentData(studentData);
  }

  getLastTeamStanding() {
    let studentData = this.getStudentData();
    return studentData.teamStanding;
  }

  getStudentTeam(): Team {
    let studentData = this.getStudentData();
    return studentData.team;
  }

  getStudentAvatar(): AvatarData {
    let studentData = this.getStudentData();
    return studentData.avatarData;
  }

  getStudentThemeNumber(): number {
    // Secondary grades (internal grade 6 and up) get a different theme than elementary
    let studentData = this.getStudentData();
    return (studentData.grade <= 5) ? THEMES.elementary : THEMES.secondary;
  }

  // TODO: Create a Destination Dictionary type
  computeCompletedDestinations(): { [key: number]: boolean } {
    // TODO: Remove the any[] type when the getTaskList type gets fixed to TaskStatus
    let destinationData = <any[]>this.getTaskStatusList();
    let destinationDictionary: any = {}

    for (let itemIdx in destinationData) {
      let value = destinationDictionary[destinationData[itemIdx].destination];
      if (value == null) {
        destinationDictionary[destinationData[itemIdx].destination] = destinationData[itemIdx].complete;
      }
      else {
        if (value && !destinationData[itemIdx].complete) {
          destinationDictionary[destinationData[itemIdx].destination] = false;
        }
      }
    }

    return destinationDictionary;
  }

  getNumLevelsInUnit(): number {
    let interventionData = this.getStudentInterventionData();
    let numberOfLevels = 0;
    if (interventionData) {
      let objectiveNumber = interventionData.objectiveNumber;
      let curriculum = this.getCurriculum();
      numberOfLevels = curriculum.levels[objectiveNumber - 1].Objective.Unit[0];
    }

    return numberOfLevels;
  }

  // Get the number of levels complete prior to the start of the
  // current objective.  Does not count pre/post objective "levels"
  // but only those that are numbered in intervention.
  getNumLevelsPriorToCurrentObjective(): number {
    let studentInterventionData = this.getStudentInterventionData();
    let levels = 0;

    if (!studentInterventionData) return levels;

    let objectiveNumber = studentInterventionData.objectiveNumber;
    for (var i = 1; i < objectiveNumber; ++i) {
      let curriculum = this.getCurriculum();
      let curriculumLevels = curriculum.levels[i - 1];
      for (let unitIdx in curriculumLevels.Objective.Unit) {
        levels += curriculumLevels.Objective.Unit[unitIdx]
      }
    }
    return levels;
  }

  getNumLevelsInPrePost(): number {
    let curriculum = this.getCurriculum();
    let studentInterventionData = this.getStudentInterventionData();
    let numLevels = 0;
    if (studentInterventionData) {
      let objectiveNumber = studentInterventionData.objectiveNumber;
      numLevels = curriculum.levels[objectiveNumber - 1].Objective.PreTest;
    }

    return numLevels;
  }

  getNumberOfLevelSelectButtons(): number {
    let curriculum = this.getCurriculum();
    if (this.isIntervention()) {
      // Since it is an intervention we are guaranteed that the intervention data will be there.
      let curriculumLevels = curriculum.levels[this.getStudentInterventionData()!.objectiveNumber - 1];
      let levels = 0;
      levels += curriculumLevels.Objective.PreTest;
      levels += curriculumLevels.Objective.PostTest;
      for (let unitIdx in curriculumLevels.Objective.Unit) {
        levels += curriculumLevels.Objective.Unit[unitIdx];
      }
      return levels;
    }
    else if (this.isScreenerDiagnostic()) {
      return curriculum.levels[0].Screener;
    }

    return curriculum.levels[0].Diagnostic;
  }

  getCurrentLevel() {
    var currentPosition = this.getCurrentPosition();
    var currentLevel = 0;

    if (this.isIntervention()) {
      var lastCompletedObjectiveNum = this.getStudentInterventionData()!.objectiveNumber;

      // If we aren't in the post test, don't count the current objective as
      // having all levels complete
      if (!this.isInterventionPostTest()) {
        --lastCompletedObjectiveNum;
      }

      let curriculum = this.getCurriculum();
      for (var i = 0; i < lastCompletedObjectiveNum; ++i) {
        for (let unitNumber in curriculum.levels[i].Objective.Unit) {
          currentLevel += curriculum.levels[i].Objective.Unit[unitNumber];
        }
      }

      if (this.isInterventionUnit()) {
        currentLevel += currentPosition - this.getNumLevelsInPrePost() + 1;
      }
    }
    else {
      currentLevel = currentPosition + 1;
    }

    return currentLevel;
  }

  getWeeklySessionTime() {
    let studentData = this.getStudentData();

    // Check if the student data has a leaderboard, if so use that for a more accurate student weekly session time
    if (this.isDemoUser() || !studentData.leaderboard) {
      return studentData.weeklySessionTime + (new Date().getTime() - studentData.sessionStartTime);
    }

    return studentData.leaderboard.studentWeeklyUsage;
  }

  getWeeklyLevelsCompleted() {
    let studentData = this.getStudentData();
    return studentData.leaderboard.weeklyLevelsCompleted;
  }

  calculateTaskPercentage(numberOfCorrectTrials: number, numberOfTrials: number): number {
    return Math.round((numberOfCorrectTrials / numberOfTrials) * 100);
  }

  // Check to see if student has completed at least one task.
  hasCompletedAtLeastOneTask() {
    // TODO: Remove this any[] type case when the getTaskList type has been fixed.
    var taskList = <any[]>this.getTaskStatusList();
    return taskList.some(taskStatus => taskStatus.complete);
  }

  // Check to see if student has completed at least one task
  // of a particular type and masking
  hasCompletedAtLeastOneTaskLikeThis(taskID: string): boolean {
    let completedTasks: string[] = this.applicationStateService.getStudentData()?.completedTasks ?? [];
    for (let task in completedTasks) {
      if (this.doTaskIDsMatchTypeAndMask(completedTasks[task], taskID)) {
        return true;
      }
    }

    var taskList = this.getTaskStatusList();
    for (let i = 0; i < taskList.length; i++) {
      let task = taskList[i];
      if (this.isInterventionTaskStatus(task)) {
        if (this.doTaskIDsMatchTypeAndMask(task.interventionTaskIdentifier, taskID) && task.complete) {
          return true;
        }
      } else {
        if (this.doTaskIDsMatchTypeAndMask(task.taskIdentifier, taskID) && task.complete) {
          return true;
        }
      }
    }
    return false;
  }

  setHasBeenNotifiedOfWinningTeam(hasBeenNotifiedOfWinningTeam: boolean) {
    let studentData = this.getStudentData();
    studentData.avatarData.hasBeenNotifiedOfWinningTeam = hasBeenNotifiedOfWinningTeam;
    this.setStudentData(studentData);
  }

  setHasBeenNotifiedOfWeeklyGoal(hasBeenNotifiedOfWeeklyGoal: boolean) {
    let studentData = this.getStudentData();
    studentData.avatarData.hasBeenNotifiedOfWeeklyGoal = hasBeenNotifiedOfWeeklyGoal;
    this.setStudentData(studentData);
  }

  getRecentlyAwardedAvatar(): AvatarItem | null {
    return this.applicationStateService.getRecentlyAwardedAvatar();
  }

  setRecentlyAwardedAvatar(avatar: AvatarItem | null) {
    this.applicationStateService.setRecentlyAwardedAvatar(avatar);
  }

  resetRecentlyAwardedAvatar() {
    this.setRecentlyAwardedAvatar(null);
  }

  getSubTaskScore(parentTaskStatus: InterventionTaskStatus, taskId: string, interventionTaskStatusList: InterventionTaskStatus[]): number {
    let taskIdArr = taskId.split('-');
    let subTaskSuffix = '-' + taskIdArr[1] + '-' + taskIdArr[2];
    let picSubTaskId = 'PAP' + subTaskSuffix;
    let questionSubTaskId = 'PAQ' + subTaskSuffix;
    let totalNumberOfTrials = 0;
    let totalNumberOfCorrectTrials = 0;
    let hasSubTasks = false;

    for (let idx = 0; idx < interventionTaskStatusList.length; idx++) {
      let taskStatus = interventionTaskStatusList[idx];
      if (taskStatus.interventionTaskIdentifier === picSubTaskId || taskStatus.interventionTaskIdentifier === questionSubTaskId) {
        totalNumberOfTrials += taskStatus.numberOfTrials;
        totalNumberOfCorrectTrials += taskStatus.numberOfCorrectTrials;
        hasSubTasks = true;
      }
    }

    if (totalNumberOfTrials > 0) {
      return this.calculateTaskPercentage(totalNumberOfCorrectTrials, totalNumberOfTrials);
    }
    else if (parentTaskStatus.complete && !hasSubTasks) {
      // If this task is marked as complete and doesn't have sub tasks, mark the score as 100%
      return 100;
    }

    return 0;
  }

  getTaskStatusList(enabledTasks: boolean = true): StudentAssessmentTaskStatus[] | InterventionTaskStatus[] {
    let studentData = this.getStudentData();
    let taskList = [];
    if (this.isIntervention()) {
      taskList = studentData.interventionData!.interventionTaskList;
      if (enabledTasks) {
        taskList = taskList.filter((task: InterventionTaskStatus) => task.enabled);
      }
    }
    else {
      taskList = studentData.studentAssessment.taskList;
    }

    return taskList;
  }

  // Gets the percentage of correct trials associated with an intervention task
  getTaskPercentage(taskId: string, subtask: boolean = false): number {
    let taskStatusList = this.getTaskStatusList() ;
    for (let statusIdx = 0 ; statusIdx < taskStatusList.length ; statusIdx++)
    {
      let taskStatus = taskStatusList[statusIdx] ;
      let currentTaskId: string = '' ;
      if (this.isInterventionTaskStatus(taskStatus))
      {
        currentTaskId = taskStatus.interventionTaskIdentifier;
      }
      else {
        currentTaskId = taskStatus.taskIdentifier;
      }

      if (currentTaskId === taskId) {
        if (taskStatus.numberOfTrials > 0) {
          // Calculate task score normally...
          return this.calculateTaskPercentage(taskStatus.numberOfCorrectTrials, taskStatus.numberOfTrials);
        }
        else if (taskStatus.numberOfTrials === 0 && !taskStatus.complete) {
          return -1;
        }
        else if (taskId.startsWith('PASS') && !subtask)
        {
          // Passages tasks are only intervention tasks
          // OPTIMIZE: This seems very fragile to assume that Passages tasks are only ever going to be interventions, but
          //         : it happens all over the codebase. We should think of a better design to indicate diagnostic or intervention
          return this.getSubTaskScore((taskStatus as InterventionTaskStatus), taskId, (taskStatusList as InterventionTaskStatus[]));
        }
        else if (taskStatus.numberOfTrials === 0 && taskStatus.complete) {
          // Mark score as 100% if task is complete with no trials
          return 100;
        }
      }
    }

    return -1;
  }

  getTaskPreviousAttemptPercentage(taskId: string): number {
    if (!this.isMidDiagnostic() && !this.isPostDiagnostic() && !this.isInterventionPostTest()) return -1;

    let taskStatusList = this.getTaskStatusList();
    for (let taskIdx = 0; taskIdx < taskStatusList.length; taskIdx++) {
      let taskStatus = taskStatusList[taskIdx];

      if (this.isInterventionTaskStatus(taskStatus) && taskStatus.interventionTaskIdentifier === taskId && taskStatus.pretestScore !== null) {
        return taskStatus.pretestScore;
      }
      else if (!this.isInterventionTaskStatus(taskStatus) && taskStatus.taskIdentifier === taskId && taskStatus.previousScore !== null && taskStatus.previousScore > 0) {
        return taskStatus.previousScore;
      }
    }

    return -1;
  }

  // Compare two task IDs to see if they are of the same type and masking
  doTaskIDsMatchTypeAndMask(taskID1: string, taskID2: string): boolean {
    let foundMatch = false;
    if (this.getMaskingFromTaskID(taskID1) === this.getMaskingFromTaskID(taskID2)) {
      foundMatch = (this.getTaskTypeFromTaskID(taskID1) === this.getTaskTypeFromTaskID(taskID2));
    }

    return foundMatch;
  }

  // Determine if the task is masked by looking at the task ID
  // Returns boolean true or false
  getMaskingFromTaskID(taskID: string): boolean {
    // If the second to last character is "M", it is masked
    // Remember that charAt uses 0 based indexing
    return (taskID.charAt(taskID.length - 2) === "M");
  }

  // Get the type of task from the taskID
  // Returns a string
  getTaskTypeFromTaskID(taskID: string) {
    if (taskID == null) {
      return "ERROR";
    } else if ((taskID.indexOf("FB") == 0) || (taskID.indexOf("MFB") == 0)) {
      return "FILL IN BLANK";
    } else if (taskID.indexOf("CHW") == 0) {
      return "CHANGE THE WORD";
    } else if ((taskID.indexOf("PIC") == 0) || (taskID.indexOf("MPIC") == 0) || (taskID.indexOf("PAP") == 0)) {
      return "FIND THE PICTURE";
    } else if (taskID.indexOf("RHY") == 0) {
      return "FIND THE RHYME";
    } else if (taskID.indexOf("FSB") == 0) {
      return "FIND THE SYLLABLE BREAKS";
    } else if (taskID.indexOf("FTW") == 0) {
      return "FIND THE WORD";
    } else if (taskID.indexOf("FWF") == 0) {
      return "FIND THE WORD FAMILY";
    } else if ((taskID.indexOf("VER") == 0) || (taskID.indexOf("MVER") == 0)) {
      return "VERIFY THE WORD";
    } else if (taskID.indexOf("STW") == 0) {
      return "SPELL THE WORD";
    } else if (taskID.indexOf("SPELL") == 0) {
      return "SPELLING";
    } else if (taskID.indexOf("MTW") == 0) {
      return "MAKE THE WORD";
    } else if (taskID.indexOf("MTP") == 0) {
      return "MAKE THE PHRASE";
    } else if ((taskID.indexOf("FSU") == 0) || (taskID.indexOf('FSM') == 0) || (taskID.indexOf('FSA') == 0)) {
      return "FIND THE NUMBER OF SYLLABLES";
    } else if (taskID.indexOf("PASS") == 0) {
      return "READ AND LISTEN";
    } else if (taskID.indexOf("PAQ") == 0) {
      return "ANSWER THE QUESTION";
    } else {
      return "UNKNOWN TASK";
    }
  }

  getWorldViewPreDiagnosticOverlay(): string {
    let themeProperties: ThemeProperties = this.getThemeProperties();
    return themeProperties.worldView.preDiagnosticOverlay;
  }

  getWorldViewMidDiagnosticOverlay(): string {
    let themeProperties: ThemeProperties = this.getThemeProperties();
    return themeProperties.worldView.midDiagnosticOverlay;
  }

  getWorldViewPostDiagnosticOverlay(): string {
    let themeProperties: ThemeProperties = this.getThemeProperties();
    return themeProperties.worldView.postDiagnosticOverlay;
  }

  getWorldViewBackground() {
    let themeProperties = this.getThemeProperties();
    let studentData = this.getStudentData();
    let highestObjectiveCompleted = studentData.highestObjectiveCompleted;
    return themeProperties.worldView.interventionBackgrounds[highestObjectiveCompleted];
  }

  getWorldViewActiveLocationBackground() {
    let interventionData = this.getStudentInterventionData();
    let themeProperties = this.getThemeProperties();
    if (interventionData != null) {
      return themeProperties.worldView.interventionBackgrounds[interventionData.objectiveNumber];
    }
    else {
      if (this.isPreDiagnostic()) {
        return themeProperties.worldView.preDiagnosticOverlay;
      }
      else if (this.isMidDiagnostic()) {
        return themeProperties.worldView.midDiagnosticOverlay;;
      }
      else {
        return themeProperties.worldView.postDiagnosticOverlay;;
      }
    }
  }

  getSideMenuTheme() {
    // TODO Test this side menu display: Changed logic heavily
    let sideMenu = this.getThemeProperties().sideMenu;
    return sideMenu;
  }

  getSideMenuOpen(): boolean {
    return this.applicationStateService.getSideMenuOpen();
  }

  setSideMenuOpen(isSideMenuOpen: boolean) {
    this.applicationStateService.setSideMenuOpen(isSideMenuOpen);
  }

  getStudentTaskStatusForDestination(destination: number): (StudentAssessmentTaskStatus | InterventionTaskStatus)[] {
    let taskStatusList: (StudentAssessmentTaskStatus | InterventionTaskStatus)[] = this.getTaskStatusList();
    let studentTaskStatusList: (StudentAssessmentTaskStatus | InterventionTaskStatus)[] = [];

    taskStatusList.forEach((taskStatus) => {
      if (taskStatus.destination === destination) studentTaskStatusList.push(taskStatus);
    });

    return studentTaskStatusList;
  }

  getTimerEnabled(): boolean {
    let interventionData = this.applicationStateService.getStudentInterventionData();
    return (interventionData !== null) ? interventionData.timerEnabled : false;
  }

  getWordListType(): string {
    let interventionData = this.applicationStateService.getStudentInterventionData();
    if (interventionData !== null) {
      let wordListType = interventionData.wordListType;

      if (wordListType === 0) return 'easy';
      else if (wordListType === 1) return 'medium';
      else if (wordListType === 2) return 'challenge';
    }

    return 'none';
  }

  getWordListTypeAsInt(wordListTypeAsString: string): number {
    if (wordListTypeAsString === 'easy') return 0;
    else if (wordListTypeAsString === 'medium') return 1;
    else if (wordListTypeAsString === 'challenge') return 2;
    else return -1;
  }

  getWelcomeVideoToPlay() {
    let videoToLoad = null;
    if (!this.isDemoUser() && !this.hasCompletedAtLeastOneTask()) {
      if (this.isScreenerDiagnostic()) {
        // Screener
        videoToLoad = 'video_welcome-screener';
      }
      else if (this.isDiagnosticProductSubscription()) {
        // iASK diagnostic
        videoToLoad = 'video_welcome-diagnostic';
      }
      else if (this.isPreDiagnostic()) {
        // "pre" diagnostic for Access Code
        videoToLoad = 'video_welcome-prediagnostic';
      }
      else if (this.isIntervention()) {
        // Access Code - pre, unit, or post test
        let studentInterventionData = this.getStudentInterventionData();
        let objectiveNumber = studentInterventionData!.objectiveNumber;
        let type = studentInterventionData!.type;

        if ((objectiveNumber == 1) && (type == 0)) {
          // Objective 1 Pretest
          videoToLoad = 'video_welcome-intervention';
        }
      }
    }

    return (videoToLoad) ? `theme${this.getStudentThemeNumber()}/${videoToLoad}` : null;
  }

  getVideoToPlayOnTransition() {
    var videoToLoad = null;

    if (this.isDemoUser()) {
      return null;
    }
    if (!this.hasCompletedAtLeastOneTask()) {
      if (this.isIntervention()) {
        // Access Code - pre, unit, or post test
        var studentInterventionData = this.getStudentInterventionData();
        var objectiveNumber = studentInterventionData!.objectiveNumber;
        var type = studentInterventionData!.type;
        var unitNumber = studentInterventionData!.unitNumber;

        if ((objectiveNumber == 1) && (type == 1) && (unitNumber == 1)) {
          // Unit 1 instructional
          videoToLoad = 'video_welcome-instructional-objective1';
        }
        else if (type == 2) {
          // Just about to start post test
          videoToLoad = 'video_end-of-unit-before-obj-posttest';
        }
        else if (type == 1) {
          // Instructional units
          if ((unitNumber == 5) || (unitNumber == 9) || (unitNumber == 13) ||
            (unitNumber == 17) || (unitNumber == 21)) {
            // Objective 2-6 pretests just completed, and starting on instructional
            videoToLoad = 'video_end-of-obj-pretest';
          }
        }
      }
      else if (this.isMidDiagnostic() || this.isPostDiagnostic()) {
        // Starting Mid or Post Diagnostic
        videoToLoad = 'video_end-of-obj-posttest-before-mid';
      }
    }

    return (videoToLoad) ? `theme${this.getStudentThemeNumber()}/${videoToLoad}` : null;
  }

  setSessionExpirationTime(expirationTime: number | null) {
    this.applicationStateService.setSessionExpirationTime(expirationTime);
  }

  getSessionExpirationTime(): number | null {
    return this.applicationStateService.getSessionExpirationTime();
  }
}
