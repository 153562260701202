import { Injectable } from '@angular/core' ;
import { Adapter } from '../adapter' ;
import { DiagnosticTaskResponse, InterventionTaskResponse, InterventionTrialResponse } from './student-data.model' ;

export interface Task {
  block: number;
  destination: number;
  focuserTime?: number;
  id: string;
  masked: boolean,
  maskerTime?: number,
  name: string;
  randomTrials: boolean;
  taskIcon: string;
  taskName: string;
  taskSubtext: string;
  taskType: string;
  taskaudio: string;
  taskaudiomasked?: string;
  trialaudiochange?: string ;
  trialaudiotomake?: string ;
  video: string;
  taskStartTime?: Date;
  trial: DiagnosticTrial[] | InterventionTrial[] ;
  generateAutocompleteResponse(additionalData?: any): DiagnosticTaskResponse | InterventionTaskResponse ;
}

// OPTIMIZE: Leaving this as an interface, it should probably be a class
// TODO: this should also be refactored so it isn't confused with an http response type
export interface Response {
  '#text': string;
  '@type': string;
  '@audio'?: string;
  '@image'?: string;
} 

// OPTIMIZE: Leaving this as an interface, it should probably be a class
export interface Tile {
  '@audio': string;
  '@type': string;
  '#text': string;
}

/**
 * Diagnostic Task classes
 */
// TODO: I put a lot of these as optional for now to make creating fake tasks easier,
//        Need to go through and make more properties required based on what's needed for trials
export class DiagnosticTask implements Task {
  public id: string ;
  public name: string ;
  public block: number ;
  public destination: number ;
  public masked: boolean ;
  public taskIcon: string ;
  public taskName: string ;
  public taskSubtext: string ;
  public taskType: string ;
  public taskaudio: string ;
  public video: string ;
  public randomTrials: boolean ;
  public trial: DiagnosticTrial[] ;
  public taskaudiomasked?: string ;
  public trialaudiochange?: string ;
  public trialaudiotomake?: string ;
  public trialaudioto?: string ;
  public focuserTime?: number = 750 ;
  public maskerTime?: number = 0 ;
  public taskStartTime?: Date ;

  constructor(item: any) {
    this.id = item.id ;
    this.name = item.name ;
    this.block = item.block ;
    this.destination = item.destination ;
    this.masked = item.masked ;
    this.taskIcon = item.taskIcon ;
    this.taskName = item.taskName ;
    this.taskSubtext = item.taskSubtext ;
    this.taskType = item.taskType ;
    this.taskaudio = item.taskaudio ;
    this.video = item.video ;
    this.randomTrials = item.randomTrials ;
    this.trial = item.trial ;

    if (item.taskaudiomasked) this.taskaudiomasked = item.taskaudiomasked ;
    if (item.trialaudiochange) this.trialaudiochange = item.trialaudiochange ;
    if (item.trialaudiotomake) this.trialaudiotomake = item.trialaudiotomake ;
    if (item.trialaudioto) this.trialaudioto = item.trialaudioto ;
    if (item.focuserTime) this.focuserTime = item.focuserTime ;
    if (item.maskerTime) this.maskerTime = item.maskerTime ;
  }

  generateAutocompleteResponse(additionalData?: any): DiagnosticTaskResponse {
    let trialResponses: DiagnosticTrialResponse[] = [] ;

    this.trial.forEach((trial: DiagnosticTrial, index: number) => {
      trialResponses.push({
        trialNumber: index + 1,
        trialLevel: trial.level || 0,
        target: trial.word['#text'] || "",
        targetGPCType: trial.gpcTarg || "",
        targetWordType: trial.itemType || "",
        targetAnswer: "",
        foil: null,
        response: "",
        responseType: "",
        numLawfulAlts: null,
        responsePosition: 1,
        responseTime: 1000,
        correct: true,
        maskTime: 0,
        gpcV: trial.gpcV || "",
        gpcC1: trial.gpcC1 || "",
        gpcC2: trial.gpcC2 || "",
        itemID: trial.itemID || 0,
        masked: trial.masked || "",
        targetPosition: trial.targPos || "",
        testSyl: trial.testSyl || "",
        numberOfSyls: trial.nsyls || 0,
        consonantType: trial.consType || "",
        vowelType: trial.vowelType || "",
        matchTrial: trial.matchTrial || "",
        homophonicRhyme: trial.hphRhyme || "",
      }) ;
    }) ;

    return {
      taskIdentifier: this.id,
      trials: trialResponses,
      durationMillis: 500 * trialResponses.length,
      numberOfTrials: trialResponses.length,
      numberOfCorrectTrials: trialResponses.length,
      points: 500 * trialResponses.length
    } ;
  }
}

// Adapters to translate JSON payloads from the REST API to our types
@Injectable({
  providedIn: 'root'
})
export class DiagnosticTaskAdapter implements Adapter<DiagnosticTask> {
  adapt(item: any, fromRemote?: boolean): DiagnosticTask {
    if (fromRemote)
    {
      let translatedItem: any = {}
      translatedItem.id = item['@id'] ;
      translatedItem.name = item['@name'] ;
      translatedItem.block = parseInt(item['@block']) ;
      translatedItem.destination = parseInt(item['@destination']) ;
      translatedItem.masked = (typeof(item['@masked']) !== 'undefined' && item['@masked'].toLowerCase() === 'true') ;
      translatedItem.taskIcon = item['@taskIcon'] ;
      translatedItem.taskName = item['@taskName'] ;
      translatedItem.taskSubtext = item['@taskSubtext'] ;
      translatedItem.taskType = item['@taskType'] ;
      translatedItem.taskaudio = item['@taskaudio'] ;
      translatedItem.video = item['@video'] ;
      translatedItem.randomTrials = (typeof(item['@randomTrials']) !== 'undefined' && item['@randomTrials'].toLowerCase() === 'true') ;
      translatedItem.trial = item['trial'] ;

      if (item['@taskaudiomasked']) translatedItem.taskaudiomasked = item['@taskaudiomasked'] ;
      if (item['@trialaudiochange']) translatedItem.trialaudiochange = item['@trialaudiochange'] ;
      if (item['@trialaudiotomake']) translatedItem.trialaudiotomake = item['@trialaudiotomake'] ;
      if (item['@trialaudioto']) translatedItem.trialaudioto = item['@trialaudioto'] ;
      if (item['@focuserTime']) translatedItem.focuserTime = parseInt(item['@focuserTime']) ;
      if (item['@maskerTime']) translatedItem.maskerTime = parseInt(item['@maskerTime']) ;

      return new DiagnosticTask(translatedItem) ;
    }
    else
    {
      return new DiagnosticTask(item) ;
    }
  }
}

// TODO: Go through these and figure out if just one trial interface with optional variables?
//       Or separate trial types per task?
// OPTIMIZE: Leaving this as an interface, it should probably be a class
export interface DiagnosticTrial {
  blankTilePos?: string;
  consType?: string;
  correct: {resp: string};
  display: {
    blankTile: number;
    tile: string[];
  }
  gpcC1?: string;
  gpcC2?: string;
  gpcTarg?: string;
  gpcV?: string;
  hphRhyme?: string;
  'initial-audio'?: string;
  itemID?: number;
  itemType?: string;
  level?: number;
  masked: string;
  maskerTime: string;
  matchTrial?: string;
  nsyls?: number;
  numlawfulalts?: number;
  'resp-list': {
    '@position'?: string;
    '@randomResponses': string;
    resp: Response[];
  }
  phrase?: {
    '#text': string;
    '@audio': string;
  }
  targPos?: string;
  testSyl?: string;
  vowelType?: string;
  question?: {
    '#text': string;
    '@audio': string;
  }
  word: {
    '#text': string;
    '@audio': string;
  };
}

// OPTIMIZE: Leaving this as an interface, it should probably be a class
export interface DiagnosticTrialResponse {
  trialNumber: number;
  trialLevel: number;
  target: string;
  targetGPCType: string;
  targetWordType: string;
  targetAnswer: string;
  foil: string | null;
  response: string;
  responseType: string;
  numLawfulAlts: number | null;
  responsePosition: number;
  responseTime: number;
  correct: boolean | null;
  maskTime: number;
  gpcV: string;
  gpcC1: string;
  gpcC2: string;
  itemID: number;
  masked: string;
  targetPosition: string;
  testSyl: string;
  numberOfSyls: number;
  consonantType: string;
  vowelType: string;
  matchTrial: string;
  homophonicRhyme: string;
}

/**
 * Intervention Task classes
 */
// TODO: I put a lot of these as optional for now to make creating fake tasks easier,
//        Need to go through and make more properties required based on what's needed for trials
export class InterventionTask implements Task {
  public id: string ;
  public name: string ;
  public block: number ;
  public destination: number ;
  public masked: boolean ;
  public taskIcon: string ;
  public taskName: string ;
  public taskSubtext: string ;
  public taskType: string ;
  public taskaudio: string ;
  public timerBar: boolean = false;
  public video: string ;
  public randomTrials: boolean ;
  public wordlistType: string ;
  public trial: InterventionTrial[] ;
  public taskaudiomasked?: string ;
  public trialaudiochange?: string ;
  public trialaudiotomake?: string ;
  public trialaudioto?: string ;
  public focuserTime?: number = 750 ;
  public maskerTime?: number = 0 ;
  public passageType?: string ;
  public passage?: {
    '@audio': string;
    title?: {
      '@audio': string;
      '#text': string;
    }
    phrase?: [{
      '@audio': string;
      '#text': string;
    }]
  } ;
  public taskClass?: string ;
  public timerBarDelay?: number = 100 ;
  public timerBarSpeed?: number = 2000 ;
  public trialinstructionalaudio?: string ;
  public taskStartTime?: Date ;

  constructor(item: any) {
    this.id = item.id ;
    this.name = item.name ;
    this.block = item.block ;
    this.destination = item.destination ;
    this.masked = item.masked ;
    this.taskIcon = item.taskIcon ;
    this.taskName = item.taskName ;
    this.taskSubtext = item.taskSubtext ;
    this.taskType = item.taskType ;
    this.taskaudio = item.taskaudio ;
    this.timerBar = item.timerBar ;
    this.video = item.video ;
    this.randomTrials = item.randomTrials ;
    this.wordlistType = item.wordlistType ;
    this.trial = item.trial ;

    if (item.taskaudiomasked) this.taskaudiomasked = item.taskaudiomasked ;
    if (item.trialaudiochange) this.trialaudiochange = item.trialaudiochange ;
    if (item.trialaudiotomake) this.trialaudiotomake = item.trialaudiotomake ;
    if (item.trialaudioto) this.trialaudioto = item.trialaudioto ;
    if (item.focuserTime) this.focuserTime = item.focuserTime ;
    if (item.maskerTime) this.maskerTime = item.maskerTime ;
    if (item.timerBarDelay) this.timerBarDelay = item.timerBarDelay ;
    if (item.timerBarSpeed) this.timerBarSpeed = item.timerBarSpeed ;
    if (item.taskClass) this.taskClass = item.taskClass ;
    if (item.passage) this.passageType = item.passage ;
    if (item.trialinstructionalaudio) this.trialinstructionalaudio = item.trialinstructionalaudio ;
    if (item.passage) this.passage = item.passage ;
  }

  generateAutocompleteResponse(additionalData?: any): InterventionTaskResponse {
    let trialResponses: InterventionTrialResponse[] = [] ;

    if (this.id.startsWith('PASS'))
    {
      // First, autocomplete our Read and Listen task
      return {
        wordListType: additionalData.wordListType,
        interventionTaskIdentifier: this.id,
        points: 500,
        durationMillis: 0,
        objectiveNumber: additionalData.objectiveNumber,
        type: additionalData.type,
        unitNumber: additionalData.unitNumber
      } ;
    }
    else
    {
      // I'm not sure how this can happen, but in the legacy version (intervention_task_factory#autoCompletePassagesTask)
      // they indicate that it could somehow be possible, but I can't see it. So lets guarantee we have at least one trial
      // For every trial in this Task, create a perfect trial response and add it to our running list
      // OPTIMIZE: Currently, actual intervention trials have their response object created in a method on the
      //         : intervention task service, however, this could be a method here instead
      let trialCount = (this.trial.length === 0) ? 1 : this.trial.length ;
      for (let tIdx = 0 ; tIdx < trialCount ; tIdx++)
      {
        let numberOfSyls: number ;
        let respFoil: string | null ;

        // Determine the number of syllables based on the trial we are looking at
        if (this.trial[tIdx].sylls)
        {
          numberOfSyls = parseInt(this.trial[tIdx].sylls || "0") ;
        }
        else if (this.trial[tIdx]['syllable-list'] && this.trial[tIdx]['syllable-list']?.syllable)
        {
          numberOfSyls = this.trial[tIdx]['syllable-list']?.syllable.length || 1 ;
        }
        else
        {
          numberOfSyls = 0 ;
        }

        if (this.id.startsWith('CHW'))
        {
          let foil = [] ;
          let addEToEnd = false ;
          let tile = <Tile[]>this.trial[tIdx].display.tile ;

          // If a tile in the target word contains the silent e syntax, remove the +e and append that to the end,
          // but keep the letter(s) before the +e.  Then set a flag to tell us to append an e to the end of the word
          tile.forEach((character) => {
            let tileText = character['#text'] ;
            let plusIdx = tileText.indexOf('+') ;
            if (plusIdx !== -1)
            {
              addEToEnd = true ;
              foil.push(tileText.substring(0, plusIdx)) ;
            }
            else
            {
              foil.push(tileText) ;
            }
          }) ;
          if (addEToEnd) foil.push('e') ;

          respFoil = foil.toString().replace(/\,/g, '') || '' ;
        }
        else if (this.id.startsWith('VER') || this.id.startsWith('MVER'))
        {
          respFoil = <string>this.trial[tIdx].display.tile || '' ;
        }
        else
        {
          respFoil = null ;
        }

        trialResponses.push({
          responseTimeOne: 1000,
          responseTimeTwo: 0,
          isCorrect: true,
          trialNumber: tIdx + 1 || 0, // trialNumber is zero-based, so add 1,
          points: 250,
          masked: this.masked ? 'M' : 'U',
          maskTime: this.maskerTime!,
          targetWordType: this.trial[tIdx].wordType || '',
          targetAnswer: '',
          response: '',
          response2: '',
          requestSupport: 0,
          redisplayMaskedWord: 0,
          numberOfSyls: numberOfSyls,
          foil: respFoil,
        }) ;
      }

      // All our trial responses are created, now make our task response array
      return {
        wordListType: additionalData.wordListType,
        interventionTaskIdentifier: this.id,
        interventionTrials: trialResponses,
        points: trialResponses.length * 250,
        durationMillis: additionalData.durationMillis,
        objectiveNumber: additionalData.objectiveNumber,
        type: additionalData.type,
        unitNumber: additionalData.unitNumber
      } ;
    }
  }
}

@Injectable({
  providedIn: 'root'
})
export class InterventionTaskAdapter implements Adapter<InterventionTask> {
  adapt(item: any, fromRemote?: boolean): InterventionTask {
    if (fromRemote) {
      let translatedItem: any = {};
      translatedItem.id = item['@id'] ;
      translatedItem.name = item['@name'] ;
      translatedItem.block = parseInt(item['@block']) ;
      translatedItem.destination = parseInt(item['@destination']) ;
      translatedItem.masked = (typeof(item['@masked']) !== 'undefined' && item['@masked'].toLowerCase() === 'true') ;
      translatedItem.taskIcon = item['@taskIcon'] ;
      translatedItem.taskName = item['@taskName'] ;
      translatedItem.taskSubtext = item['@taskSubtext'] ;
      translatedItem.taskType = item['@taskType'] ;
      translatedItem.taskaudio = item['@taskaudio'] ;
      translatedItem.timerBar = (typeof(item['@timerBar']) !== 'undefined' && item['@timerBar'].toLowerCase() === 'true') ;
      translatedItem.video = item['@video'] ;
      translatedItem.randomTrials = (typeof(item['@randomTrials']) !== 'undefined' && item['@randomTrials'].toLowerCase() === 'true') ;
      translatedItem.wordlistType = item['@wordlistType'] ;
      translatedItem.trial = item['trial'] ;

      if (item['@taskaudiomasked']) translatedItem.taskaudiomasked = item['@taskaudiomasked'] ;
      if (item['@trialaudiochange']) translatedItem.trialaudiochange = item['@trialaudiochange'] ;
      if (item['@trialaudiotomake']) translatedItem.trialaudiotomake = item['@trialaudiotomake'] ;
      if (item['@trialaudioto']) translatedItem.trialaudioto = item['@trialaudioto'] ;
      if (item['@focuserTime']) translatedItem.focuserTime = parseInt(item['@focuserTime']) ;
      if (item['@maskerTime']) translatedItem.maskerTime = parseInt(item['@maskerTime']) ;
      if (item['@timerBarDelay']) translatedItem.timerBarDelay = parseInt(item['@timerBarDelay']) ;
      if (item['@timerBarSpeed']) translatedItem.timerBarSpeed = parseInt(item['@timerBarSpeed']) ;
      if (item['@taskClass']) translatedItem.taskClass = item['@taskClass'] ;
      if (item['@passage']) translatedItem.passageType = item['@passage'] ;
      if (item['@trialinstructionalaudio']) translatedItem.trialinstructionalaudio = item['@trialinstructionalaudio'] ;
      if (item['passage']) translatedItem.passage = item['passage'] ;

      return new InterventionTask(translatedItem);
    } else {
      return new InterventionTask(item) ;
    }
  }
}

export interface RespList {
  '@position'?: string;
  '@randomResponses': string;
  resp: Response[];
}

// TODO: Go through these and figure out if just one trial interface with optional variables?
//       And confirm that all the possible properties are there
// OPTIMIZE: Leaving this as an interface, it should probably be a class
export interface InterventionTrial {
  blankTilePos?: string;
  correct?: {resp: (string | { '@audio': string ; '#text': string ; }[])};
  display: {
    blankTile?: {'@audio': string};
    tile: Tile[] | string;
  }
  'initial-audio'?: string;
  phrase?: {
    '#text': string;
    '@audio': string;
  }
  question?: {
    '#text': string;
    '@audio': string;
  }
  'resp-list'?: RespList;
  sentence: {
    '#text': string;
    '@audio': string;
  }
  'syllable-list'?: {
    syllable: [{
      '#text': string;
      '@cvcText': string;
      '@audio': string;
    }]
  }
  sylls?: string;
  word: {
    '#text': string;
    '@audio': string;
  }
  wordType: string;
  sentenceType?: string;
}
