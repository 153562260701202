export const environment = {
  production: true,
  SENTRY_DSN: 'https://0abc4f47dcba887d7e188e15435af429@o4506633830989824.ingest.us.sentry.io/4507103771820032',
  EC2URL: 'https://wfservices.wordflight.com/',
  AssetServerURL: 'https://student.wordflight.com',
  VideoAssetServerURL: 'https://student.wordflight.com',
  CLEVER_REDIRECT_URI: 'https://wfservices.wordflight.com/clever/login',
  CLEVER_CLIENT_ID: '6c6d4dfbf1c99e1e747e',
  CLASSLINK_REDIRECT_URI: 'https://wfservices.wordflight.com/classlink/login',              
  CLASSLINK_CLIENT_ID: 'c163216955075175c1d49930a17ebc6f8702f1f5f7a2',
  versionNumber: '5.001',
  'appStoreLink': 'https://apps.apple.com/us/app/wordflight-student/id1584915043',
  // Specifies how many seconds a student should finish a trial within.
  // If a task takes  more than this time on aggregate, student will
  // be notified and poor usage might be reported.
  trialSecondsLimit: 60,
};
 