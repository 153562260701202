import {AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit, NgZone} from '@angular/core';
import { Router } from '@angular/router';
import { of } from 'rxjs';
import { catchError, concatMap, first, map, mergeMap, tap } from 'rxjs/operators';
import { InterventionTrial, Response } from 'src/app/core/models/task.model';
import { AudioPlayerService } from 'src/app/core/services/audio-player.service';
import { InterventionTaskService } from 'src/app/core/services/intervention-task.service';
import { ShuffleService } from 'src/app/core/services/shuffle.service';
import { StudentDataService } from 'src/app/core/services/student-data.service';
import { TimerService } from 'src/app/core/services/timer.service';
import { InterventionTaskComponent } from '../intervention-task.component';
import { ResponseTile } from './find-the-rhyme-intervention.model';
import { TaskService } from '../../core/services/task.service';

@Component({
  selector: 'find-the-rhyme-intervention',
  templateUrl: './find-the-rhyme-intervention.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FindTheRhymeInterventionComponent extends InterventionTaskComponent implements OnInit, AfterViewInit {

  constructor(
    public studentDataService: StudentDataService,
    public interventionTaskService: InterventionTaskService,
    public shuffleService: ShuffleService,
    public timerService: TimerService,
    public audioPlayerService: AudioPlayerService,
    public taskService: TaskService,
    public router: Router,
    public changeDetector: ChangeDetectorRef,
    private zone: NgZone,
  ) {
    super(studentDataService, interventionTaskService, timerService, audioPlayerService, router);
  }
  ngOnInit(): void {
    // Check to shuffle trials
    if (this.task.randomTrials) {
      this.trials = this.shuffleService.shuffleArray(this.trials);
    }
    this.unmaskedTask = !this.task.masked;
    this.hideTimer = true;
    // Get starting points for the total points cloud
    this.taskTotalPoints = this.interventionTaskService.getStartingPoints(this.task.id, this.currentDestination, this.wordListAttempt);
    // Initialize values for variables to do the toggling of the next button highlighting
    this.toggleNextButton = false;
    this.toggleAgainButton = false;
    this.disableNextButton = true;
    this.disableAgainButton = true;
    this.disableResponseButtons = true;
    this.readyToHighlight = false;
    this.hideResponses = true;
    this.hideNextButton = false;
    this.hideAgainButton = true;
  }

  ngAfterViewInit() {
    // After view is initialized wait for task animation to complete and then initialize everything else
    this.taskBar.taskAnimationComplete.pipe(first())
    .subscribe(() => {
      // set this to tell the trial-counter that animation is complete
      this.animationComplete = true;
      this.interventionTaskService.initTaskContainerElements(this.task, this.alreadyCompleted, this.wordListAttempt, this.attempt)
        .pipe(first(),
          map(() => {
            let timerBarSettings = this.interventionTaskService.getTimerBarTaskSettings();
            // TODO: OPTIMIZE could probably find a better way to set this.hideTimer and handle timerBarSettings
            timerBarSettings.timerBarEnabled ? this.trialTimerBar.showTimerBar() : this.trialTimerBar.hideTimerBar();
            this.hideTimer = !timerBarSettings.timerBarEnabled
          }),
          concatMap(() => {
            if (!this.studentDataService.hasCompletedAtLeastOneTaskLikeThis(this.task.id) && this.interventionTaskService.getPlayVideoFlag()) {
              this.playInstructionalAudio = false;
              return this.instructions.playInstructionalVideo();
            }
            else {
              return of({});
            }
          }),
          concatMap(() => {
            if (this.playInstructionalAudio) {
              return this.audioPlayerService.play(this.interventionTaskService.getInstructionalAudioFile());
            } else {
              return of({});
            }
          }),
        )
        .subscribe({
          complete: () => this.instructionalAudioCompleteFunction(),
        });
    });

    // Display the focus dialog if needs focus is set (from intevention task)
    if(this.needsFocus){
      this.focusDialog.showDialog();
    }
  }

  trials: InterventionTrial[] = this.task.trial;
  numberOfCorrectTrials: number = 0;
  trialResponseList: Response[] = [];
  incorrectFirstResponse: boolean = false;
  maskerTime: number = this.task.maskerTime ?? 0;
  unmaskedTask: boolean = false;
  responseOptions: ResponseTile[] = [];
  responseOptions1: ResponseTile[] = [];
  responseOptions2: ResponseTile[] = [];
  targetWord: string = '';
  oneIncorrectAnswer: boolean = false;
  firstResponseTime: number = 0;
  secondResponseTime: number = 0;

  addHoverClass(element: HTMLElement) {
    element.classList.add('hover');
  }

  removeHoverClass(element: HTMLElement) {
    element.classList.remove('hover');
  }

  enableResponses() {
    this.reusableTimer = window.setTimeout(() => {
      this.disableResponseButtons = false;
      this.disableAVButtons = false;
      this.changeDetector.markForCheck() ;
    }, 0);
  }

  /**
    * Plays the target word audio (uses optional arguments)
    */
  playWordAudio() {
    this.audioPlayerService.play(this.trials[this.trialIndex].word['@audio']).subscribe({
      complete: () => this.enableResponses(),
      error: () => this.enableResponses()
    });
  }

  playCorrectWordAudio() {
    for(let i = 0; i < this.responseOptions.length; i++){
      if (this.responseOptions[i].isCorrect){
        this.audioPlayerService.play(this.responseOptions[i].audio).subscribe();
        break;
      }
    }
  }

  playResponseAudio(index: number) {
    this.dataTracker.requestSupport++;
    this.audioPlayerService.play(this.trialResponseList[index]['@audio']!).subscribe({
      complete: () => this.enableResponses,
      error: () => this.enableResponses()
    });
  }

  instructionalAudioCompleteFunction() {
    // Explicitly running this timer inside of the angular zone so that iOS can respond to changes more quickly
    this.zone.run(() => {
      this.reusableTimer = window.setTimeout(() => {
        this.disableNextButton = false;
        this.disableAgainButton = true;
        this.hideAgainButton = true;
        this.readyToHighlight = true;
        this.hideResponses = false;
        this.changeDetector.markForCheck();
        if (this.unmaskedTask){
          this.displayTrial();
        } else {
          this.createInterval();
        }
      }, 0);
    });
  }

  createResponseList(trial: InterventionTrial) {
    this.dataTracker = this.interventionTaskService.createTrialDataTrackerObject();
    this.responseOptions = [];

    // Create a shallow copy of the response list
    let responseList = trial['resp-list']!.resp.slice();

    // Reduce the number of responses, if needed
    responseList = this.interventionTaskService.reduceResponsesIfNecessary(responseList);

    // Shuffle responses
    responseList = this.interventionTaskService.shuffleResponses(responseList, true);

    // Set the global trial response list to the now fully formed trimmed/shuffled list.
    this.trialResponseList = responseList;

    let notHighlighted = false;

    // Build the response lists
    for (var i = 0; i < responseList.length; i++) {
      let responseText = responseList[i]['#text'];
      let responseAudio = responseList[i]['@audio'] ?? "";
      let isCorrectResponse = (responseList[i]['@type'] === 'Correct');

      this.responseOptions.push(new ResponseTile(responseText, responseAudio, notHighlighted, isCorrectResponse));
      if (isCorrectResponse) {
        this.dataTracker.targetAnswer = responseText;
      }
  }

    this.responseOptions1 = this.responseOptions.slice(0,3);
    this.responseOptions2 = this.responseOptions.slice(3,6);
    this.showResponseAudioButtons = this.interventionTaskService.hasInitialAudioSupport();
  }

  runFirstIncorrectSequence() {
    // Get angular to update
    this.reusableTimer = window.setTimeout(() => {
      this.targetWord = this.trials[this.trialIndex].word['#text'];
      this.removeResponseHighlighting();
      this.oneIncorrectAnswer = true;
      this.showResponseAudioButtons = true;
      this.disableAgainButton = true;
      this.hideAgainButton = true;
      this.playWordAudio();
      this.changeDetector.markForCheck() ;
      this.startTime = this.timerService.startTimer();
    }, 0);
  }

  secondResponseIncorrectSequence() {
    this.reusableTimer = window.setTimeout(() => {
      // Play correct word audio
      this.highlightCorrectResponse();
      this.playCorrectWordAudio();
    }, 0);
  }

  displayTrial() {
    this.createResponseList(this.trials[this.trialIndex]);
    this.stopInterval();
    this.readyToHighlight = false;
    this.incorrectFirstResponse = false;
    this.maskerTime = this.task.maskerTime ?? 0;

    // If we have extra support and have gotten two in a row incorrect, our maskertime is extended for the whole task
    if (this.maskerTime > 0 && this.interventionTaskService.hasTwoIncorrectInSequence && this.studentDataService.getStudentData().extraSupportNeeded)
    {
      this.maskerTime += this.interventionTaskService.maskerSupportExtend ;
    }

    // Remove any left over highlighting from a missed response in a previous trial
    this.removeResponseHighlighting();

    this.disableNextButton = true;
    this.disableAgainButton = false;
    this.hideResponses = false;
    this.hideNextButton = true;
    this.hideAgainButton = true;
    this.oneIncorrectAnswer = false;
    this.targetWord = "______";
    this.changeDetector.markForCheck() ;

    this.reusableTimer = window.setTimeout(() => {
      this.targetWord = this.trials[this.trialIndex].word['#text'];
      if (this.studentDataService.isInterventionPreTest() || (this.studentDataService.isInterventionPostTest())){
        this.hideAgainButton = true;
        this.disableAgainButton = true ;
      }
      else {
        this.hideAgainButton = false;
        this.disableAgainButton = false ;
      }
      this.changeDetector.markForCheck() ;
      this.displayMasker();
    }, this.task.focuserTime || 750);
  }

  displayTrialAgain() {
    // Remove any left over highlighting from the previous trial
    this.removeResponseHighlighting();
    this.dataTracker.redisplayMaskedWord++;
    this.maskerTime = this.maskerTime + 60;
    this.hideAgainButton = true;
    this.disableAgainButton = true;
    this.targetWord = "______";
    this.changeDetector.markForCheck() ;

    this.reusableTimer = window.setTimeout(() => {
      this.targetWord = this.trials[this.trialIndex].word['#text'];
      this.hideAgainButton = true;
      this.disableAgainButton = true;
      this.changeDetector.markForCheck() ;
      this.displayMasker();
    }, this.task.focuserTime || 750);
  }

  displayMasker() {
    if (this.task.masked) {
      this.reusableTimer = window.setTimeout(() => {
        this.targetWord = "########";
        this.disableResponseButtons = false;
        this.disableAVButtons = false;
        this.changeDetector.markForCheck() ;
        this.startTime = this.timerService.startTimer();
      }, this.maskerTime);
    }
    else {
      this.disableResponseButtons = false;
      this.disableAVButtons = false;
      this.changeDetector.markForCheck() ;
      this.startTime = this.timerService.startTimer();
    }
  }

  updateTotalPoints(points: number) {
    this.taskTotalPoints += points;
  }

  removeResponseHighlighting() {
    for (let response in this.responseOptions) {
      this.responseOptions[response].highlight = false;
    }
  }

  submitResponse(selectedResponse: number) {
    this.disableResponseButtons = true;
    this.disableAVButtons = true;
    this.disableAgainButton = true;

    // Stop timer after the student selects a response
    this.endTime = this.timerService.stopTimer();
    if (this.oneIncorrectAnswer ){
      this.secondResponseTime = this.timerService.computeTime(this.startTime, this.endTime) || 0;
    }
    else{
      this.firstResponseTime = this.timerService.computeTime(this.startTime, this.endTime) || 0;
      this.secondResponseTime = 0;
    }

    let responseTile = this.responseOptions[selectedResponse];
    responseTile.highlight = true;

    let isCorrect = responseTile.isCorrect;
    let runningPointsAnimation = this.trialTimerBar.sendResponseToTimerBar(isCorrect);
    let trialPoints = this.trialTimerBar.getPoints();
    this.interventionTaskService.playSoundEffect(isCorrect);
    this.interventionTaskService.recordResponseInTrialDataTrackerObject(this.dataTracker, responseTile.text);

    if (isCorrect || !this.isUnit){
      let isTrialCorrect = isCorrect && !this.incorrectFirstResponse;
      let responseObject = this.interventionTaskService.createTrialResponseObject(isTrialCorrect, this.trialIndex,
          this.firstResponseTime, this.secondResponseTime, trialPoints, this.dataTracker, selectedResponse);
      if (isTrialCorrect) this.numberOfCorrectTrials++;
      this.interventionTaskService.trackResponseTrends(isTrialCorrect);

      this.trialList.push(responseObject);

      // Perform expected animations and move on to the next trial
      this.taskService.answerTrial(isTrialCorrect) ;
      this.interventionTaskService.moveToNextTrial(responseObject, runningPointsAnimation).subscribe({
        complete: () => {
          this.updateTotalPoints(responseObject.points);
          this.afterUpdate();
        }
      });
    }
    else{
      // 2 incorrect responses
      if(this.oneIncorrectAnswer){
        this.interventionTaskService.trackResponseTrends(isCorrect);
        let responseObject = this.interventionTaskService.createTrialResponseObject(isCorrect, this.trialIndex,
            this.firstResponseTime, this.secondResponseTime, trialPoints, this.dataTracker, selectedResponse);
        this.trialList.push(responseObject);
        this.taskService.answerTrial(isCorrect) ;
        // Play "The correct answer is..." followed by resetting the target word, playing word audio, highlighting, etc
        this.reusableTimer = window.setTimeout(() =>{
          this.audioPlayerService.play('Audio/Help/help_correctansweris.mp3').subscribe({
            complete: () => this.secondResponseIncorrectSequence(),
            error: () => this.secondResponseIncorrectSequence()
          });
        }, this.interventionTaskService.secondIncorrectDelay);

        // Perform expected animations and move on to the next trial
        this.reusableTimer = window.setTimeout(() => {
          this.interventionTaskService.moveToNextTrial(responseObject, runningPointsAnimation).subscribe({
            complete: () => {
              this.updateTotalPoints(responseObject.points);
              this.afterUpdate();
            }
          });
        }, 1000);
      }
      else{
        // 1 incorrect response
        this.incorrectFirstResponse = true;

        this.reusableTimer = window.setTimeout(() => {
          this.audioPlayerService.play('Audio/Help/help_tryagain.mp3').subscribe({
            complete: () => this.runFirstIncorrectSequence(),
            error: () => this.runFirstIncorrectSequence()
          });
        }, this.interventionTaskService.firstIncorrectDelay);
      }
    }
  }

  highlightCorrectResponse() {
    for (let i = 0; i < this.responseOptions.length; i++) {
      this.responseOptions[i].highlight = this.responseOptions[i].isCorrect;
    }
    this.changeDetector.markForCheck() ;
  }

  // Show the user the response they selected briefly before going to the next trial
  // Run through the task with the next trial in the curriculum
  afterUpdate() {
    this.reusableTimer = window.setTimeout(() => {
      this.trialTimerBar.resetTrialTimer();

      // Make sure we didn't just complete the last task
      if (this.trialIndex + 1 < this.trials.length) {
        this.trialIndex += 1;
        this.disableResponseButtons = true;
        this.disableAVButtons = true;
        // Explicitly running this timer inside of the angular zone so that iOS can respond to changes more quickly
        this.zone.run(() => {
          this.reusableTimer = window.setTimeout(() => {
            this.disableNextButton = false;
            this.hideNextButton = false;
            this.disableAgainButton = true;
            this.hideAgainButton = true;
            this.hideResponses = true;
            this.targetWord = '';
            this.readyToHighlight = true;
            this.removeResponseHighlighting();
            this.changeDetector.markForCheck();
            if (this.unmaskedTask){
              this.displayTrial();
            } else {
              this.createInterval();
            }
          }, 250);
        });
      }
      else {
        this.reusableTimer = window.setTimeout(() => {
          this.hideNextButton = true;
          this.hideAgainButton = true;
          this.saveTaskData();
        }, 250); // Just needs a little time to finish trial counter animation
      }
    }, this.interventionTaskService.getDelayAfterSingleResponse(this.trialList, [250, 500]));
  }

  saveTaskData() {
    this.interventionTaskService.handleEndOfTaskProcess(this.trialList, this.taskTotalPoints,
        this.numberOfTrials, this.numberOfCorrectTrials, this.attempt)
    .pipe(
      mergeMap(() => {
        let params = this.interventionTaskService.getTaskDataParams();
        if (params.taskData.length) {
          return this.studentDataService.saveTrialData(params.taskData, !params.taskFinished)
        } else {
          return of({});
        }
      })
    ).subscribe({
      next: () => {
        this.saveDataDialog.hideSaveDataDialog();
        this.completeTask(this.attempt);
      },
      error:() => this.saveDataDialog.showSaveDataDialog()
    });
  }


  disableVideo() {
    return (this.disableNextButton && this.disableResponseButtons);
  }

  get runChangeDetection() {
    // console.log('running change detection in find-the-rhyme-intervention') ;
    return true ;
  }
}
