<div class="viewport-1" [ngStyle]="{'background-image':'url(' + interventionTaskService.taskBackgroundImage() + ')'}">
    <student-header></student-header>
    <task-bar #taskBar [totalPoints]="taskTotalPoints"></task-bar>

    <div class="task-screen-element change-the-blank-container"
        [ngStyle]="{'background-image': ' ' + interventionTaskService.taskContainerColor() + ' '}"
        [ngClass]="(animationComplete) ? ['taskShow'] : ['taskHide']">
        <div class="timer-bar-container">
            <trial-timer-bar></trial-timer-bar>
            <button [ngClass]="(hideTimer) ? ['replayVideo-no-timerbar'] : ['replayVideo']" (click)="replayVideo()"
                [disabled]="disableAVButtons"></button>
        </div>
        <div class="read-the-sentence-target-container">
            <!-- tiles that spell the incomplete word will go in here
                Probably use ng-repeat to loop over letters in the target word-->
            <button class="speaker speaker-rtw-adjust" (click)="playTargetAudioViaSpeakerClick()" [disabled]="!isAudioSupportEnabled"></button>
            <div class="sentence-type-row">
                <div [ngClass]="sentenceType"></div>
            </div>
            <div *ngIf="!submitting; else loading" class="target-sentence-row">
                <div *ngFor="let word of currentTargetSentence; let i = index" class="target-sentence-rts-text">
                    <div [ngClass]="{'rts-word-correct': transcriptionCorrectness[i] === 'correct',
                            'rts-word-incorrect': transcriptionCorrectness[i] === 'incorrect',
                            'rts-word-caution': transcriptionCorrectness[i] === 'caution'}">{{ word.word }}</div>
                    <div *ngIf="word.punct">{{ word.punct }}</div>
                </div>
            </div>
            <div class="recording-button-row-rts">
                <button [disabled]="isRecordingDisabled"
                    [ngClass]="(isRecording) ? ['record-button-stop'] : ['record-button']"
                    (click)="startRecording()"></button>
            </div>
            <button class="submit-rtw" [disabled]="isSubmitDisabled" (click)="uploadAudioRecording()"></button>
        </div>
        <div class="recording-playback-container">
            <audio id="player" #player [src]="audioRecordingURL">
                Your browser does not support the audio element.
            </audio>
            <div class="progress-bar-container">
                <div id="progress-bar" class="progress-bar-rtw">
                    <div id="progress" #progress class="playback-progress"></div>
                </div>
                <div class="playback-timestamps">
                    <span id="current-time" class="time-display">{{ currentTime }}</span>
                    <span id="total-time" #totalTimeDisplay class="time-display">{{ durationDisplay }}</span>
                </div>
            </div>
            <div>
                <button [disabled]="isPlayDisabled"
                    [ngClass]="(isPlayingRecording) ? ['recording-playback-button-pause'] : ['recording-playback-button']"
                    (click)="togglePlay()"></button>
            </div>
        </div>
        <div *ngIf="showDebug">
            <ng-container *ngTemplateOutlet="debugging"></ng-container>
        </div>
    </div>

    <trial-counter [showCorrectIncorrect]="true" [taskAnimationComplete]="animationComplete"></trial-counter>
    <instructions [videoSource]="instructionalVideoFile"></instructions>
    <save-data-dialog (onSaveButtonClick)="saveTaskData()"></save-data-dialog>
    <try-again-dialog></try-again-dialog>
    <focus-dialog></focus-dialog>
</div>

<ng-template #loading>
    <div class="bouncing-dots-row-rts">
        <div class="bouncing-dots">
            <div class="dot"></div>
            <div class="dot"></div>
            <div class="dot"></div>
        </div>
    </div>
</ng-template>

<ng-template #debugging>
    <div>
        <select [(ngModel)]="aiVendor">
            <option value="deepgram">Deepgram AI</option>
            <option value="gcp" disabled>Google Speech-to-Text</option>
        </select>
    </div>
    <div
        style="border: 1px solid #333 ; background-color: #FFF ; border-radius: 5px ; padding: 5px ; margin-top: 20px ;">
        <p style="font-weight: bold ;">
            <em *ngIf="submitting">Loading...</em>
        </p>
        <h4>Returned Transcript (AI confidence value)</h4>
        <p *ngIf="!submitting">{{ transcription }} - ({{ confidence }}) : <span
                [style.color]="(status === 'Correct') ? 'green' : 'red'">{{ status }}</span></p>
        <h4>Individual Words Together (AI confidence value of each word)</h4>
        <div *ngIf="!submitting">
            <span *ngFor="let word of words">{{ word.word }} ({{ word.confidence }}) </span>
        </div>
    </div>
</ng-template>