import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { of } from 'rxjs';
import { concatMap, first, map, mergeMap } from 'rxjs/operators';
import { InterventionTrial } from 'src/app/core/models/task.model';
import { AudioPlayerService } from 'src/app/core/services/audio-player.service';
import { InterventionTaskService } from 'src/app/core/services/intervention-task.service';
import { ShuffleService } from 'src/app/core/services/shuffle.service';
import { StudentDataService } from 'src/app/core/services/student-data.service';
import { TimerService } from 'src/app/core/services/timer.service';
import { InterventionTaskComponent } from '../intervention-task.component';
import { KeyboardLetters, KeyboardSpecial, TargetLetters } from './spelling-intervention.model'
import { TaskService } from '../../core/services/task.service';

const ENTER_KEY: number = 69;
const DELETE_KEY: number = 68;
const BACKSPACE_KEY: number = 66;
const SHIFT_KEY: number = 83;
const BLACK_CIRCLE: string = '\u25cf';

@Component({
  selector: 'spelling-intervention',
  templateUrl: './spelling-intervention.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SpellingInterventionComponent extends InterventionTaskComponent implements OnInit, AfterViewInit {

  constructor(
    public studentDataService: StudentDataService,
    public interventionTaskService: InterventionTaskService,
    public timerService: TimerService,
    public audioPlayerService: AudioPlayerService,
    public router: Router,
    public shuffleService: ShuffleService,
    public taskService: TaskService,
    public changeDetector: ChangeDetectorRef,
  ) {
    super(studentDataService, interventionTaskService, timerService, audioPlayerService, router);
  }

  delete: KeyboardSpecial = new KeyboardSpecial('delete', '68', false, false);
  enter: KeyboardSpecial = new KeyboardSpecial('enter', '69', false, false);
  shift: KeyboardSpecial = new KeyboardSpecial('shift', '83', false, false);
  keyboard: KeyboardLetters[] = [];
  topKeyRow: KeyboardLetters[] = [];
  middleKeyRow: KeyboardLetters[] = [];
  bottomKeyRow: KeyboardLetters[] = [];

  trials: InterventionTrial[] = this.task.trial;
  numberOfCorrectTrials: number = 0;
  incorrectFirstResponse: boolean = false;
  oneIncorrectAnswer: boolean = false;
  startTask: boolean = false;
  showType: boolean = false;
  targetWord: string[] = [];
  targetAnswer: TargetLetters[] = [];
  syllables: string[] = [];

  ngOnInit(): void {
    // Check to shuffle trials
    if (this.task.randomTrials) {
      this.trials = this.shuffleService.shuffleArray(this.trials);
    }
    this.showType = (this.task.wordlistType === 'easy');
    // Get starting points for the total points cloud
    this.taskTotalPoints = this.interventionTaskService.getStartingPoints(this.task.id, this.currentDestination, this.wordListAttempt);
  }

  ngAfterViewInit(): void {
    // After view is initialized wait for task animation to complete and then initialize everything else
    this.taskBar.taskAnimationComplete.pipe(first())
    .subscribe(() => {
      // set this to tell the trial-counter that animation is complete
      this.animationComplete = true;
      this.interventionTaskService.initTaskContainerElements(this.task, this.alreadyCompleted, this.wordListAttempt, this.attempt)
        .pipe(first(),
          map(() => {
            this.createKeyboard();
            let timerBarSettings = this.interventionTaskService.getTimerBarTaskSettings();
            timerBarSettings.timerBarEnabled ? this.trialTimerBar.showTimerBar() : this.trialTimerBar.hideTimerBar();
          }),
          concatMap(() => {
            if (!this.studentDataService.hasCompletedAtLeastOneTaskLikeThis(this.task.id) && this.interventionTaskService.getPlayVideoFlag()) {
              this.playInstructionalAudio = false;
              return this.instructions.playInstructionalVideo();
            }
            else {
              return of({});
            }
          }),
          // NOTE: we always play the instructional audio on the first trial, so only check for video here
        )
        .subscribe({
          complete: () => this.defaultAudioCompleteFunc(),
        });
    });
      
    // Display the focus dialog if needs focus is set (from intevention task)
    if(this.needsFocus){
      this.focusDialog.showDialog();
    }
  }

  keyPress($event: KeyboardEvent) {
    let code = $event.key.charCodeAt(0);
    if(!this.disableResponseButtons){
      if (code === SHIFT_KEY){
        this.shiftLetters();
      }
      else if (code === ENTER_KEY && this.targetWord.length > 0){
        this.submitResponse();
        this.removeResponseHighlighting();
      }
      else if (code === DELETE_KEY || code === BACKSPACE_KEY){
        this.delete.highlight = true;
        this.removeLetter();
        this.removeResponseHighlighting();
      }
      else if (code <= 122 && code >= 97){
        for(let i = 0; i < this.keyboard.length; i++){
          if (parseInt(this.keyboard[i].keyCode) === code){
            this.keyboard[i].highlight = true;
            this.addLetter(this.keyboard[i].index);
            break;
          }
        }
        this.removeResponseHighlighting();
      }
    }
  }

  addHoverClass(key: KeyboardLetters | KeyboardSpecial) {
    key.highlight = true;
  }

  removeHoverClass(key: KeyboardLetters | KeyboardSpecial) {
    key.highlight = false;
  }

  // Handle touch events on normal keys
  handleTouchEndNormalKey($index: number, $event: TouchEvent) {
    this.removeResponseHighlighting();
  }

  // Handle touch events on special keys (shift, enter, delete)
  handleTouchEndSpecialKey(keyCode: string, $event: TouchEvent) {
    this.removeResponseHighlighting();
  }

  // enable keyboard and all AV buttons
  enableButtons() {
    this.reusableTimer = window.setTimeout(() => {
      this.disableResponseButtons = false;
      this.disableAVButtons =  false;
      this.startTask = true;
      this.changeDetector.markForCheck() ;
    }, 0);
  }

  // Set up audio for the speaker button
  playTargetAudioViaSpeakerClick() {
    this.dataTracker.requestSupport++;
    this.playWordAudio();
  }

  playWordAudio() {
    document.getElementById("keyPress")!.focus();
    this.audioPlayerService.play(this.trials[this.trialIndex].word['@audio']).subscribe({
      complete: () => this.enableButtons(),
      error: () => this.enableButtons()
    });
  }

  // Play the correct word and do not enable buttons
  playCorrectWordAudio() {
    this.audioPlayerService.play(this.trials[this.trialIndex].word['@audio']).subscribe();
  }

  // Set up audio for syllable audio support buttons
  playSyllableAudio(index: number) {
    document.getElementById("keyPress")!.focus();
    this.dataTracker.requestSupport++;
    this.audioPlayerService.play(this.syllables[index]).subscribe();
  }

  // display the letter above selected CVC button
  seeLetter(index: number) {
    document.getElementById("keyPress")!.focus();
    this.targetAnswer[index].blank = this.targetAnswer[index].text;
  }

  focusThenReplayVideo() {
    document.getElementById("keyPress")!.focus();
    this.replayVideo();
  }

  defaultAudioCompleteFunc() {
    this.reusableTimer = window.setTimeout(() => {
      this.displayTask(this.trialIndex);
    }, 0);
  }

  runFirstIncorrectSequence() {
    this.showType = true;
    this.oneIncorrectAnswer = true;
    this.changeDetector.markForCheck() ;

    this.playWordAudio();
    this.startTime = this.timerService.startTimer();
  }

  runSecondIncorrectSequence() {
    // Setting timeout function around displayCorrectTargetWord because otherwise the DOM is
    // not updating at the correct time, there is a slight pause before updating.
    window.setTimeout(() => { this.displayCorrectTargetWord(this.trials[this.trialIndex]); }, 0);
    this.playCorrectWordAudio();
    // Perform expected animations and move on to the next trial
    this.reusableTimer = window.setTimeout(() => {
      this.disableAVButtons =  true;
      let responseObject = this.trialList[this.trialList.length - 1];
      this.updateTotalPoints(responseObject.points);
      this.afterUpdate();
    }, this.interventionTaskService.moveToNextTrialDelay);
  }

  // Displays the correct answer in the target area
  displayCorrectTargetWord(trial: InterventionTrial) {
    let correctResponse = this.trials[this.trialIndex].word['#text'];

    for(let character = 0; character < correctResponse.length; character++){
      this.updateTargetTile(correctResponse[character]);
    }
    this.changeDetector.markForCheck() ;
  }

  // Loop through the trial list and build the target word and responses for each item, and go to the next one after a click even updates the counter
  displayTask(newIndex: number) {
    document.getElementById("keyPress")!.focus();

    this.showType = false;
    this.targetWord = [];
    this.trialIndex = newIndex;
    this.oneIncorrectAnswer = false;
    this.incorrectFirstResponse = false;

    // Plays the trial instructional audio followed by playWordAudio() if on the first trial, otherwise just calls playWordAudio()
    if (this.trialIndex === 0){
      this.audioPlayerService.play(this.interventionTaskService.getTrialInstructionalAudio()).subscribe({
        complete: () => this.playWordAudio(),
      });
    } else {
      this.playWordAudio();
    }

    this.targetAnswer = [];
    this.syllables = [];

    this.dataTracker = this.interventionTaskService.createTrialDataTrackerObject();

    // TODO: correctWord can be array or not, depending on the number of syllables,
    // so ideally we would have the types defined to be able to handle both
    let correctWord = this.trials[this.trialIndex]['syllable-list']!.syllable;
    if (correctWord.length > 1){
      for (let syllable in correctWord) {
        let syllableText = correctWord[syllable]['#text'];
        let syllableCVC = correctWord[syllable]['@cvcText'];
        this.syllables.push(correctWord[syllable]['@audio']);
        for (let character = 0; character < syllableCVC.length; character++) {
          this.targetAnswer.push(new TargetLetters('_', syllableText.charAt(character), syllableCVC.charAt(character)));
        }
        //add black circle between syllables
        this.targetAnswer.push(new TargetLetters(BLACK_CIRCLE, BLACK_CIRCLE, BLACK_CIRCLE));
      }

      // remove additional black circle
      this.targetAnswer.pop();
    }
    else{
      // NOTE index of 0 below because only one syllable for this word
      let syllable: any = [this.trials[this.trialIndex]['syllable-list']!.syllable];
      let correctWordString = syllable[0]['#text'];
      let correctCVC = syllable[0]['@cvcText'];
      this.syllables.push(syllable[0]['@audio']);
      for (let character = 0; character < correctWordString.length; character++) {
        this.targetAnswer.push(new TargetLetters('_', correctWordString.charAt(character), correctCVC.charAt(character)));
      }
    }

    // TODO: need to check where/if orginalStartTime is used in other places in legacy code
    this.changeDetector.markForCheck() ;
    let originalStartTime = this.timerService.startTimer();
    this.startTime = originalStartTime;
  }

  // Add letter to the displayed word
  updateTargetTile(text: string) {
    this.targetWord.push(text);
  }

  // Remove Highlighting from all keys
  removeResponseHighlighting() {
    // allow for the key to be highlighted briefly (100ms) as the user types on the keyboard
    window.setTimeout(() => {
      for (let key of this.keyboard) {
        key.highlight = false;
      }

      this.shift.highlight = false;
      this.enter.highlight = false;
      this.delete.highlight = false;
      this.changeDetector.markForCheck() ;
    }, 100);
  }

  // Function for what happens when user presses the shift key
  shiftLetters() {
    document.getElementById("keyPress")!.focus();
    this.shift.highlight = true;

    for(var i = 0; i < this.keyboard.length; i++){
      if (this.keyboard[i].text == this.keyboard[i].text.toUpperCase()){
        this.keyboard[i].text = this.keyboard[i].text.toLowerCase();
      }else{
        this.keyboard[i].text = this.keyboard[i].text.toUpperCase();
      }
    }

    this.removeResponseHighlighting();
  }

  // Function for what happens when user hits backspace or delete
  removeLetter() {
    document.getElementById("keyPress")!.focus();
    this.targetWord.pop();
    this.changeDetector.markForCheck() ;
  }

  // Function for what happens when user clicks on key
  addLetter(selectedResponse: number) {
    document.getElementById("keyPress")!.focus();
    if (this.targetWord.length < 15){
      this.updateTargetTile(this.keyboard[selectedResponse].text);
    }
    this.changeDetector.markForCheck() ;
  }

  submitResponse() {
    document.getElementById("keyPress")!.focus();

    // Save the student's response
    this.disableResponseButtons = true;
    this.disableAVButtons = true;
    this.changeDetector.markForCheck() ;

    let answer = this.trials[this.trialIndex].word['#text'];
    this.dataTracker.targetAnswer = answer;
    let response = "";
    // To get integer representation of the student's answer
    let responseBinary = "";
    this.targetWord.forEach((element) => {
      response += element;
      responseBinary += element.charCodeAt(0);
    });

    let isCorrect = (answer === response);
    let runningPointsAnimation = this.trialTimerBar.sendResponseToTimerBar(isCorrect);
    let trialPoints = this.trialTimerBar.getPoints();
    this.interventionTaskService.playSoundEffect(isCorrect);
    this.interventionTaskService.recordResponseInTrialDataTrackerObject(this.dataTracker, response);

    let firstResponseTime = 0;
    let secondResponseTime = 0;
    if (isCorrect || !this.isUnit){
      // If the student missed their first try -- count the trial as incorrect
      let isTrialCorrect = isCorrect && !this.incorrectFirstResponse;
      this.interventionTaskService.trackResponseTrends(isTrialCorrect);
      let responseObject = this.interventionTaskService.createTrialResponseObject(isTrialCorrect, this.trialIndex,
          firstResponseTime, secondResponseTime, trialPoints, this.dataTracker, Number(responseBinary));
      if (isTrialCorrect) this.numberOfCorrectTrials++;

      this.trialList.push(responseObject);

      // Perform expected animations and move on to the next trial
      this.taskService.answerTrial(!this.incorrectFirstResponse)
      this.showType = false;
      this.oneIncorrectAnswer = false;
      this.interventionTaskService.moveToNextTrial(responseObject, runningPointsAnimation).subscribe({
        complete: () => {
          this.updateTotalPoints(responseObject.points);
          this.afterUpdate();
        }
      });
    }
    else {
      this.targetWord = [];

      // 2 incorrect responses
      if(this.oneIncorrectAnswer){
        this.interventionTaskService.trackResponseTrends(isCorrect);
        let responseObject = this.interventionTaskService.createTrialResponseObject(isCorrect, this.trialIndex, firstResponseTime, secondResponseTime, trialPoints, this.dataTracker, Number(responseBinary));
        this.trialList.push(responseObject);

        this.taskService.answerTrial(isCorrect) ;
        // Play "The correct answer is..." followed by resetting the target word, playing word audio, highlighting, etc
        this.reusableTimer = window.setTimeout(() => {
          this.audioPlayerService.play('Audio/Help/help_correctansweris.mp3').subscribe({
            complete: () => this.runSecondIncorrectSequence(),
            error: () => this.runSecondIncorrectSequence()
          });
        }, this.interventionTaskService.secondIncorrectDelay);
      } else {
        // 1 incorrect response
        this.incorrectFirstResponse = true;
        this.reusableTimer = window.setTimeout(() => {
          this.audioPlayerService.play('Audio/Help/help_tryagain.mp3').subscribe({
            complete: () => this.runFirstIncorrectSequence(),
            error: () => this.runFirstIncorrectSequence()
          });
        }, this.interventionTaskService.firstIncorrectDelay);
      }
    }
  }

  // Set up keyboard keys with KeyCode, highlight, disable, and index
  createKeyboard() {
    let letters = ['q','w','e','r','t','y','u','i','o','p', 'a','s','d','f','g','h','j','k','l', 'z','x','c','v','b','n','m'] ;
    let keyCodes = ['113', '119', '101', '114', '116', '121', '117', '105', '111', '112', '97', '115', '100', '102', '103', '104', '106', '107', '108', '122', '120', '99', '118', '98', '110', '109'] ;
    let index = 0;

    for(let i = 0; i < letters.length; i++){
      this.keyboard.push(new KeyboardLetters(letters[i], keyCodes[i], false, false, index));
      ++index;
    }

    this.enter = new KeyboardSpecial('enter', '69', false, false);
    this.shift = new KeyboardSpecial('shift', '83', false, false);
    this.delete = new KeyboardSpecial('delete', '68', false, false);

    this.topKeyRow =  this.keyboard.slice(0,10);
    this.middleKeyRow =  this.keyboard.slice(10,19);
    this.bottomKeyRow =  this.keyboard.slice(19,26);
  }

  // Show the user the response they selected for one second before going to the next trial
  // Run through the task with the next trial in the curriculum
  afterUpdate() {
    this.reusableTimer = window.setTimeout(() => {
    this.trialTimerBar.resetTrialTimer();

    let newIndex = this.trialIndex + 1;
    if (newIndex < this.trials.length) {
      this.displayTask(newIndex);
    } else {
      this.saveTaskData();
    }
    }, this.interventionTaskService.getDelayAfterSingleResponse(this.trialList));
  }

  saveTaskData() {
    this.interventionTaskService.handleEndOfTaskProcess(this.trialList, this.taskTotalPoints,
        this.numberOfTrials, this.numberOfCorrectTrials, this.attempt)
    .pipe(
      mergeMap(() => {
        let params = this.interventionTaskService.getTaskDataParams();
        if (params.taskData.length) {
          return this.studentDataService.saveTrialData(params.taskData, !params.taskFinished)
        } else {
          return of({});
        }
      })
    ).subscribe({
      next: () => {
        this.saveDataDialog.hideSaveDataDialog();
        this.completeTask(this.attempt);
      },
      error:() => this.saveDataDialog.showSaveDataDialog()
    });
  }
}
